import { useMutation, useQuery } from '@tanstack/react-query';
import useOrganization from 'hooks/useOrganization';
import { ServiceFactory } from 'services/FactoryService';
import { BucketExpressionModel, ExpressionBucket } from 'types/expression.types';
import { Planning } from './usePlannings';

export type Blueprint = {
  segmentId: number;
  name: string;
  folder?: string;
  desc?: string;
  created?: Date;
  edited?: {
    by: {
      userId: number;
      name: string;
      username: string;
      email: string;
    };
    date: Date;
  };
  country?: string;
  type?: string;
  hasChildren?: boolean;
  isShared?: boolean;
  expression: BucketExpressionModel | ExpressionBucket;
};

export type Folder = {
  name: string;
  blueprints: Blueprint[];
};

export const GET_BLUEPRINTS = 'GET_BLUEPRINTS';
export const GET_BLUEPRINT = 'GET_BLUEPRINT';

const useBlueprints = () => {
  const service = ServiceFactory.get('plannings');
  const { currentOrganization } = useOrganization();

  const { data, isLoading, error } = useQuery<Blueprint[]>([GET_BLUEPRINTS, currentOrganization], () =>
    service.getBlueprints(currentOrganization.clientId)
  );

  const folders: Folder[] = [];
  let blueprints: Blueprint[] = [];

  if (data) {
    blueprints = data.map(blueprint => {
      const blueprintName = blueprint.name.split('|');

      const folder = blueprintName.shift() || '';
      const name = blueprintName.join('|');

      const newBlueprint = { ...blueprint, folder: folder?.trim(), name: name.trim() };
      if (!folders.find(f => f.name === folder?.trim())) {
        folders.push({ name: folder?.trim(), blueprints: [newBlueprint] });
      } else {
        const folderFind = folders.find(f => f.name === folder?.trim());
        if (folderFind) folderFind.blueprints.push(newBlueprint);
      }
      return newBlueprint;
    });
  }

  const onUpdateBlueprint = async (props: { payload: unknown; segmentId: number; clientId: number }) => {
    const { payload, segmentId, clientId } = props;
    return service.updateBlueprint(payload, segmentId, clientId);
  };

  const getBlueprint = async (payload: Planning) => {
    const { segmentId, clientId } = payload;
    return service.getBlueprint(segmentId, clientId);
  };

  const getBlueprinMutation = useMutation({
    mutationFn: getBlueprint,
    mutationKey: [GET_BLUEPRINT]
  });

  return {
    blueprints,
    isLoading,
    error,
    folders,
    saveBlueprint: service.saveBlueprint,
    deleteBlueprint: service.deleteBlueprint,
    updateBlueprint: useMutation(onUpdateBlueprint),
    getBlueprinMutation,
    shareBlueprint: service.shareBlueprint
  };
};

export default useBlueprints;
