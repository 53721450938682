import { SAVE } from 'constants/flows';

export const initialPlanification = (t: any) => ({
  loading: false,
  name: '',
  folder: t({ id: 'MODAL.SHARE.FOLDER.DEFAULT' }),
  step: SAVE,
  newFolder: false,
  planification: {
    clientId: 0,
    platforms: [
      {
        platformsData: {},
        seatIds: [],
        platform: null,
        seats: [],
        platformName: ''
      }
    ]
  }
});
