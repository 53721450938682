import repository from '../repository';

const getOptions = () => {
  const token = localStorage.getItem('token');
  const rt = localStorage.getItem('rtToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt
    }
  };
};

export default {
  async getBlueprints(clientId) {
    const blueprintsResponse = await repository.get(`segments/v3/list`, {
      ...getOptions(),
      params: {
        type: 'blueprint',
        view_as: clientId,
        size: -1,
        fields: 'name,segmentId,created,edited,type,country,usage,isShared'
      }
    });
    return blueprintsResponse.data.response.response;
  },
  async getBlueprint(blueprintId, clientId) {
    const blueprintsResponse = await repository.get(`segments/v3/get/${blueprintId}?view_as=${clientId}`, getOptions());
    return blueprintsResponse.data.response;
  },
  async saveBlueprint(payload, clientId) {
    if (!clientId) {
      throw new Error('clientId is required');
    }
    const blueprintsResponse = await repository.post(
      `segments/v3/create?type=blueprint&view_as=${clientId}`,
      payload,
      getOptions()
    );
    return blueprintsResponse.data.response;
  },
  async updateBlueprint(payload, segmentId, clientId) {
    const blueprintsResponse = await repository.put(
      `segments/v3/edit/${segmentId}?view_as=${clientId}`,
      payload,
      getOptions()
    );
    return blueprintsResponse.data.response;
  },
  async shareBlueprint(payload, segmentId, clientId) {
    const blueprintsResponse = await repository.post(
      `/segments/v3/share/${segmentId}?view_as=${clientId}`,
      payload,
      getOptions()
    );
    return blueprintsResponse.data.response;
  },
  async deleteBlueprint(blueprintId) {
    const blueprintsResponse = await repository.delete(`segments/v3/delete/${blueprintId}`, getOptions());
    return blueprintsResponse.data.response;
  },
  async saveCustomPlanning(customPlanning, clientID) {
    const customPlanification = await repository.post(
      `sondeo/planification/create?view_as=${clientID}`,
      customPlanning,
      getOptions()
    );
    return customPlanification.data.planificationId;
  },
  async removeCustomPlanning({ blueprint, clientId }) {
    const customPlanning = await repository.delete(`segments/v3/delete/?view_as=${clientId}`, {
      data: { ids: [blueprint.segmentId] },
      headers: getOptions().headers
    });
    return customPlanning?.data?.response;
  },

  async getPlatformsAndSeats(clientId) {
    const platsAndSeats = await repository.get(`/platforms/get?getSeats=true&view_as=${clientId}`, getOptions());
    return platsAndSeats.data.response;
  },
  async downloadPlanification(payload) {
    const fileToDownload = await repository.post(`sondeo/v2/planification/download`, payload, getOptions());
    return fileToDownload.data.response;
  },
  async getPlatfformPrice(expression, platformId, clientId) {
    const price = await repository.post('segments/price', { expression, platformId, clientId }, getOptions());
    return price.data.response[0].price;
  },

  async createGeoAudience(name, description, locations, clientId, country, days) {
    return repository.post(
      `/segments/v3/create/?type=geo&view_as=${clientId}`,
      { name, description, locations, country, days },
      getOptions()
    );
  },
  createKeywordsAudience(payload) {
    return repository.post(`/segments/v3/create/?type=keyword&view_as=${payload.clientId}`, payload, getOptions());
  }
};
