import * as commonSelectors from './common';
import * as organizationSelectors from './organization';
import * as planificationSelectors from './planification';

export const {
  getDemographicOverlap,
  getGlobalErrors,
  getGuidedModal,
  getIdsSelectedSegments,
  getLogOutScreen,
  getModal,
  getAndroidDevices,
  getDevicesByCountryAndPlatform,
  getDevicesByPlatform,
  getIosDevices,
  getPasswordModal,
  getPeople,
  getPlanifModal,
  getPlanningModalObject,
  getPlatforms,
  getRightPanel,
  getSearchQuery,
  getSearchResults,
  getSelectedIdSegments,
  getSelectedPlanif,
  getShareModal,
  getShowTutorial,
  getTopClusters,
  getTopCountries,
  getTotalDevices,
  getTotalRow,
  getUser,
  getUserId,
  getUserOrganization,
  getUserPlanData,
  getWebMobileDevices,
  getWebNoMobileDevices,
  isAuthorized,
  refreshInsightData,
  getModalsUI
} = commonSelectors;

export const { getExpressionBucket, getSelectedBucket, getCountrySelected } = planificationSelectors;

export const { getOrganizations, getLoadingOrganizations, getCurrentOrganization } = organizationSelectors;
