import React from 'react';
import { Field, FieldHookConfig, useField, ErrorMessage } from 'formik';
import { TextInput, Text, Box } from '@retargetly/ui-components';

interface Props {
  name: string;
  max?: number;
  min?: number;
  type?: string;
  placeholder?: string;
  dataCy?: string;
  onPaste?: (event: React.ClipboardEvent<HTMLInputElement>) => void;
}

const CustomInputComponent = (props: any) => {
  return <TextInput type="text" {...props} invalid={props.invalid} />;
};

const FormikInput: React.FC<Props & FieldHookConfig<any>> = ({
  name,
  max,
  min,
  type = 'text',
  placeholder,
  dataCy,
  onPaste
}) => {
  const [field, meta] = useField({ name });
  return (
    <div>
      <Field
        type={type}
        {...field}
        as={CustomInputComponent}
        data-cy={dataCy}
        placeholder={placeholder ?? ''}
        name={name}
        min={min}
        max={max}
        invalid={meta?.touched === true && meta?.error !== undefined}
        onPaste={onPaste}
        autoComplete="off"
      />
      <Box mt={2}>
        <Text size="bodySmall" align="left" color="red">
          <ErrorMessage name={name} />
        </Text>
      </Box>
    </div>
  );
};

export default FormikInput;
