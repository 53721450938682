import * as segmentsActions from './segments';
import * as uiActions from './ui';
import * as insightsActions from './insights';
import * as planningsActions from './plannings';
import * as authActions from './auth';
import * as organizationActions from './organizations';

export const {
  loadSegments,
  fetchSegments,
  addSelectedSegmentsIds,
  removeIdFromSelectedSegments,
  setSelectedSegments,
  setSelectedExpressionGroup,
  addAvailableExpressionGroup,
  addSelectedSegmentsToGroup,
  removeSelectedSegmentsFromGroup,
  setExpressionGroup,
  resetExpressionCondition,
  resetSelectedSegments,
  fetchGuidedPlanifications,
  fetchSearchSegments,
  getCountries,
  addCountriesSelected,
  setSegmentRecency,
  setRecencyFilter,
  removeCondition,
  setCountrySelected,
  resetExpressionBucket,
  removeSegmentsFromBucket,
  addSelectedSegmentsToBucket,
  setSelectedExpressionBucket,
  addBucketToExpressionBucket,
  removeBucketToExpressionBucket,
  setInternalOperatorToBucket,
  setExternalOperatorToBucket
} = segmentsActions;

export const { loadCustomPlannings, removeCustomPlannings, getPlatformsAndSeats, addPlanningObject } = planningsActions;

export const { logout, setAuthRedirectPath, fetchLogout, loginFromToken, setToken } = authActions;

export const { getEstimations, getTableEstimations, getBotData, setAvatarProps } = insightsActions;

export const {
  togglePanelRight,
  toggleModal,
  toggleGuidedModal,
  toggleTour,
  toggleLogOut,
  setGlobalError,
  toggleShareModal,
  togglePlanifModal,
  togglePasswordModal,
  toggleQuestionModal,
  toggleWelcomeModal,
  refreshInsightData,
  setTypeFlow
} = uiActions;

export const { getOrganizations, setCurrentOrganization } = organizationActions;
