import useOrganization from 'hooks/useOrganization';
import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useSelector } from 'react-redux';
import { getCurrentPlanification } from 'selectors/planification';
import { getShareModal } from 'selectors';
import { SAVE, SHARE } from 'constants/flows';
import { initialPlanification } from './share';

export const useSetupPlanification = () => {
  const { currentOrganization } = useOrganization();
  const t = useFormatMessage();
  const [formState, setFormState] = useState(initialPlanification(t));
  const currentPlanification = useSelector(getCurrentPlanification);
  const shareModal = useSelector(getShareModal);
  const isNewPlanification = useMemo(
    () =>
      (Object.keys(currentPlanification).length === 0 || currentPlanification?.isNew) &&
      currentPlanification?.segmentId === undefined,
    [currentPlanification]
  );
  const planificationHasBeenSaved = useMemo(() => !!currentPlanification?.segmentId, [currentPlanification]);
  const planificationHasBeenShared = useMemo(() => currentPlanification?.isShared, [currentPlanification]);

  useEffect(() => {
    if (currentOrganization !== undefined) {
      const formStateCloned = cloneDeep(formState);
      formStateCloned.planification = {
        ...formStateCloned.planification,
        clientId: currentOrganization?.clientId
      };
      setFormState(formStateCloned);
    }
  }, [currentOrganization]);

  useEffect(() => {
    if (currentPlanification?.segmentId) {
      const [folder, ...spreadName] = currentPlanification?.name?.split('|');
      const name = spreadName.join('|');
      const formStateCloned = cloneDeep(formState);
      formStateCloned.name = name;
      formStateCloned.folder = folder;
      formStateCloned.step = planificationHasBeenShared ? SHARE : SAVE;
      setFormState(formStateCloned);
    }
  }, [currentPlanification, shareModal]);

  const handlePlatformChange = (
    data: {
      name: 'platform' | 'platformName';
      value: number;
      platformName: string;
    },
    idxPlatform: number
  ) => {
    const newState = cloneDeep(formState);
    const { name, value, platformName } = data;
    newState.planification.platforms[idxPlatform] = {
      ...newState.planification.platforms[idxPlatform],
      [name]: value,
      platformName,
      platformsData: {
        [value]: {
          platformId: value,
          audienceType: value === 25 ? ['mobile'] : undefined
        }
      },
      seatIds: []
    };
    setFormState(newState);
    return newState;
  };

  const handleSeatChange = (
    data: { value: number; seatsArray: Array<{ children: string; value: string }> },
    idxPlatform: number
  ) => {
    const newState = cloneDeep(formState);
    const { value, seatsArray } = data;
    if (typeof value === 'number') {
      newState.planification.platforms[idxPlatform].seatIds = [value as never];
    } else {
      newState.planification.platforms[idxPlatform].seatIds = value;
    }
    // @ts-ignore
    newState.planification.platforms[idxPlatform].seatsArray = seatsArray.map(
      seat => `${seat.children}(${seat.value})`
    );
    setFormState(newState);
    return newState;
  };

  const addPlatformAndSeat = () => {
    const newState = cloneDeep(formState);
    newState.planification.platforms.push({
      platformsData: {},
      seatIds: [],
      platform: null,
      seats: [],
      platformName: ''
    });
    setFormState(newState);
    return newState;
  };

  const setData = (data: { name: 'name' | 'folder'; value: string }) => {
    const { name, value } = data;
    const newData = { ...formState };
    newData[name] = value;
    setFormState(newData);
    return newData;
  };

  const removePlatform = (platformIndex: number) => {
    const newState = cloneDeep(formState);
    newState.planification.platforms = newState.planification.platforms.filter(
      (platform, platIndex) => platIndex !== platformIndex
    );
    setFormState(newState);
    return newState;
  };

  const resetPlanification = () => {
    setFormState(initialPlanification(t));
  };

  const changeStep = (value: string) => {
    const newState = cloneDeep(formState);
    newState.step = value;
    setFormState(newState);
    return newState;
  };

  return {
    formState,
    handlePlatformChange,
    handleSeatChange,
    addPlatformAndSeat,
    setData,
    removePlatform,
    resetPlanification,
    isNewPlanification,
    planificationHasBeenSaved,
    planificationHasBeenShared,
    currentPlanification,
    currentOrganization,
    changeStep
  };
};
