import produce from 'immer';

import {
  LOAD_CUSTOM_PLANNINGS,
  EDIT_CUSTOM_PLANNING,
  GET_PLATFORMS_AND_SEATS,
  ADD_PLANNING_MODAL,
  SET_CURRENT_PLANIFICATION,
  REST_CURRENT_PLANIFICATION
} from '../constants/actionTypes';

const initialState = {
  customPlannings: [],
  platformsPlanification: [],
  selectedPlanning: undefined,
  modalPlanning: null,
  currentPlanification: {}
};

export default (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case LOAD_CUSTOM_PLANNINGS:
      newState.customPlannings = [...action.payload];
      return newState;
    case EDIT_CUSTOM_PLANNING:
      newState.customPlannings = newState.customPlannings.map(item => {
        if (item.id !== action.payload.id) {
          return item;
        }
        return { ...action.payload };
      });
      return newState;

    case GET_PLATFORMS_AND_SEATS:
      newState.platformsPlanification = [...action.payload];
      return newState;

    case ADD_PLANNING_MODAL:
      return produce(state, draftState => {
        draftState.modalPlanning = action.payload;
      });
    case SET_CURRENT_PLANIFICATION:
      return produce(state, draftState => {
        draftState.currentPlanification = action.payload;
      });
    case REST_CURRENT_PLANIFICATION:
      return produce(state, draftState => {
        draftState.currentPlanification = {};
      });
    default:
      return state;
  }
};
