import produce from 'immer';
/**
 * Segments Reducer Module;
 * Pure functions that will fire actions
 * to change state
 */

const modelState = {
  selectedIdSegments: [],
  selectedCountry: '',
  selectedBucket: 0,
  expressionBucket: [{ segments: [], internalOperator: 'OR', operator: null }]
};

export default (state = modelState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case 'SET_COUNTRY_SELECTED': {
      return produce(state, draftState => {
        draftState.selectedCountry = action.payload;
      });
    }
    case 'SET_SELECTED_EXPRESSION_BUCKET':
      return produce(state, draftState => {
        draftState.selectedBucket = action.payload;
      });
    case 'ADD_SEGMENTS_IDS_TO_EXPRESSION_BUCKET':
      return produce(state, draftState => {
        const previous = draftState.expressionBucket[draftState.selectedBucket].segments;
        draftState.expressionBucket[draftState.selectedBucket].segments = [...new Set(previous.concat(action.payload))];
        // ADD SEGMENT ID SELECTED
        action.payload.forEach(segment => {
          if (!draftState.selectedIdSegments.includes(segment.id)) {
            draftState.selectedIdSegments.push(segment.id);
          }
        });
      });
    case 'ADD_BUCKET_TO_EXPRESSION_BUCKET':
      return produce(state, draftState => {
        const newBucket = { segments: [], internalOperator: 'OR', operator: null };
        draftState.expressionBucket.push(newBucket);
        // set the selected bucket to the new one
        draftState.selectedBucket = draftState.expressionBucket.length - 1;
        // set the operator to the previous bucket
        draftState.expressionBucket[draftState.selectedBucket - 1].operator = action.payload;
      });
    case 'SET_INTERNAL_OPERATOR_TO_EXPRESSION_BUCKET':
      return produce(state, draftState => {
        draftState.expressionBucket[action.payload.bucketKey].internalOperator = action.payload.operator;
      });
    case 'SET_EXTERNAL_OPERATOR_TO_EXPRESSION_BUCKET':
      return produce(state, draftState => {
        draftState.expressionBucket[action.payload.bucketKey].operator = action.payload.operator;
      });

    case 'REMOVE_SEGMENTS_FROM_EXPRESSION_BUCKET':
      return produce(state, draftState => {
        if (action.payload.bucketKey) {
          const { segments } = draftState.expressionBucket[action.payload.bucketKey];
          action.payload.segments.forEach(segment => {
            const index = segments.findIndex(s => s.id === segment.id);
            if (index !== -1) {
              segments.splice(index, 1);
            }
          });
          draftState.expressionBucket[action.payload.bucketKey].segments = [...new Set(segments)];
        } else {
          draftState.expressionBucket = draftState.expressionBucket.map(bucket => {
            const { segments } = bucket;
            action.payload.segments.forEach(segment => {
              const index = segments.findIndex(s => s.id === segment.id);
              if (index !== -1) {
                segments.splice(index, 1);
              }
            });
            return bucket;
          });
        }
        // REMOVE FROM SELECTED ID SEGMENTS
        action.payload.segments.forEach(segment => {
          if (draftState.selectedIdSegments.includes(segment.id)) {
            draftState.selectedIdSegments = draftState.selectedIdSegments.filter(id => id !== segment.id);
          }
        });
      });
    case 'REMOVE_BUCKET_TO_EXPRESSION_BUCKET':
      return produce(state, draftState => {
        const bucketKey = action.payload;
        const { segments } = draftState.expressionBucket[bucketKey];
        // REMOVE FROM SELECTED ID SEGMENTS
        segments.forEach(segment => {
          const { rootParentId } = segment;
          if (rootParentId) {
            if (draftState.selectedIdSegments.includes(segment.id)) {
              draftState.selectedIdSegments = draftState.selectedIdSegments.filter(id => id !== segment.id);
            }
          }
        });
        // DELETE BUCKET FROM EXPRESSION BUCKET
        draftState.expressionBucket.splice(bucketKey, 1);
        // SELECT THE PREVIOUS BUCKET O THE FIRST ONE
        if (bucketKey === draftState.selectedBucket) {
          draftState.selectedBucket = bucketKey - 1 || 0;
        } else if (draftState.selectedBucket > draftState.expressionBucket.length - 1) {
          draftState.selectedBucket = draftState.expressionBucket.length - 1;
        }
      });

    case 'RESET_EXPRESSION_BUCKET':
      newState.selectedBucket = 0;
      newState.expressionBucket = [{ segments: [], operator: null, internalOperator: 'OR' }];
      newState.selectedIdSegments = [];

      return newState;

    default:
      return state;
  }
};
