import {
  ExpressionBucket,
  BucketExpressionModel,
  ExpressionPlain,
  LogicalOperator,
  Segment
} from 'types/expression.types';

export const expressionBucketToExpressionPlain = (expressionBucket: ExpressionBucket): ExpressionPlain =>
  expressionBucket
    .filter(b => b.segments.length > 0)
    .map((bucket, index, arr) => {
      const segments = bucket.segments.map(segment => segment?.segmentId || segment).filter(Number);
      return ` (${segments.join(` ${bucket.internalOperator} `)}) ${
        index < arr.length && arr.length > 1 ? bucket.operator || '' : ''
      }`;
    })
    .join('')
    .trim();

export const expressionBucketToBucketExpressionModel = (expressionBucket: ExpressionBucket): BucketExpressionModel =>
  expressionBucket
    .filter(b => b.segments.length > 0)
    .map((bucket, index, expression) => {
      const segments = bucket.segments.map(segment => segment.segmentId).filter(Number);
      return {
        id: index,
        rawExpression: [
          {
            group: [...new Set(segments)],
            internalOperator: bucket.internalOperator,
            externalOperator: null
          }
        ],
        type: 'third-party',
        bucketOperator: index === expression.length - 1 ? null : bucket.operator
      };
    });

export const expressionBucketExpressionModelToExpressionBucket = (
  bucketExpressionModel: BucketExpressionModel
): ExpressionBucket =>
  bucketExpressionModel.map(bucket => {
    const segments: Segment[] = [];
    bucket.rawExpression[0].group.forEach(segment => {
      if (typeof segment === 'number') segments.push({ id: `S${segment}`, name: '', segmentId: segment });
      else segments.push({ ...segment, id: `S${segment.segmentId}`, rootParentName: segment?.rootParentName });
    });
    return {
      id: bucket.id.toString(),
      segments,
      internalOperator: (bucket.rawExpression[0].internalOperator || 'OR').toUpperCase() as LogicalOperator,
      operator: bucket.bucketOperator?.toUpperCase() as LogicalOperator
    };
  });
