import { createContext } from 'react';
import { Map } from 'mapbox-gl';
import { bulkPlaces } from './types';

export interface MapContextProps {
  isMapReady: boolean;
  map?: Map;
  setMap: (map: Map) => void;
  goToPoint: (lng: number, lat: number) => void;
  setTool: (tool: string | undefined) => void;
  drawInstance: MapboxDraw | undefined;
  setDrawInstance: (draw: MapboxDraw) => void;
  addByLatAndLong: (lat: number, long: number) => void;
  addByBulk: (data: bulkPlaces[]) => void;
  bulkFileError: boolean;
  setBulkError: (error: boolean) => void;
}

export const MapContext = createContext({} as MapContextProps);
