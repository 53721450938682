import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import useEventTracker from 'hooks/useEventTracker';
import { useTaxonomyContext } from 'components/Planner/context/hooks';
import { SEARCH } from 'constants/routes';
import SearchInput from '../SearchInput/SearchInput';
import useGetRegularSearch from '../hooks/useGetRegularSearch';

type Props = {
  country: string;
};

const RegularSearch: React.FC<Props> = ({ country }) => {
  const { trackEvent } = useEventTracker();
  const history = useHistory();
  const { countrySelected, queryToShow, setQueryToShow, reset } = useTaxonomyContext();
  const { isFetching } = useGetRegularSearch(queryToShow, countrySelected);

  const searchSegments = useCallback(
    (queryValue: string | null) => {
      reset();
      history.push(SEARCH);
      history.replace({ search: `?q=${queryValue}` });
      setQueryToShow(queryValue);
    },
    [history]
  );

  useEffect(() => {
    if (queryToShow !== '') {
      searchSegments(queryToShow);
    }
  }, [queryToShow, searchSegments]);

  const resetListResults = () => {
    setQueryToShow('');
    history.push('?q=');
  };

  const searchHandle = (queryValue: string) => {
    searchSegments(queryValue);
    if (queryValue !== '' && countrySelected) {
      trackEvent('KEYWORDS_SEARCH_BUTTON', {});
    }
  };

  return (
    <SearchInput
      query={queryToShow}
      searchSegments={searchHandle}
      resetListResults={resetListResults}
      loading={isFetching}
    />
  );
};

export default RegularSearch;
