import React from 'react';
import { Breadcrumb } from '@retargetly/ui-components';
import useBreadcrumbs from './hooks/useBreadcrumbs';

const Breadcrumbs = () => {
  const currentBreadcrumb = useBreadcrumbs();

  return (
    <div>
      {currentBreadcrumb && (
        <Breadcrumb items={[{ name: currentBreadcrumb.name, url: currentBreadcrumb.url }]} dataTestid="breadcrumb" />
      )}
    </div>
  );
};

export default Breadcrumbs;
