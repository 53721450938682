import React from 'react';
import { Layout } from 'antd';
import { MapView } from './Map';

const { Content } = Layout;
const contentStyle: React.CSSProperties = {
  textAlign: 'center',
  height: '100vh',
  width: 'auto'
};

export const MapContainer = () => {
  return (
    <Content style={contentStyle}>
      <MapView />
    </Content>
  );
};
