import { useMutation } from '@tanstack/react-query';
import { ServiceFactory } from 'services/FactoryService';

const authFactory = ServiceFactory.get('auth');

export const logoutService = () => {
  return authFactory.logoutService();
};

export const useLogout = (onSuccessLogout: (redirect: string) => void, onErrorLogout: () => void) => {
  return useMutation(logoutService, {
    onSuccess: (res: any) => {
      onSuccessLogout(res.data.redirect_to);
    },
    onError: () => {
      onErrorLogout();
    }
  });
};
