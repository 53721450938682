import repository from '../repository';

const getOptions = () => {
  const token = localStorage.getItem('token');
  const rt = localStorage.getItem('rtToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt
    }
  };
};

export default {
  async getProductsVersions() {
    const response = await repository.get('/products/versions', getOptions());
    return response.data;
  }
};
