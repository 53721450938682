import React, { FC } from 'react';
import { Box, Button } from '@retargetly/ui-components';
import { Loading, Error, Empty } from 'components/StateComponents';
import { useFormatMessage } from 'react-intl-hooks';
import Comparison from 'components/Insights/Comparison/Comparison';

type Props = {
  data: any;
  isLoading: boolean;
  isError: boolean;
  handleOpenChildAudience: (id: number, name: string) => void;
  handleBack: () => void;
  clustersSelected: { name: string; id: number }[];
};

const TabSelector: FC<Props> = ({
  data,
  isLoading,
  isError,
  handleOpenChildAudience,
  handleBack,
  clustersSelected
}) => {
  const t = useFormatMessage();

  return (
    <>
      {isLoading && (
        <Box w="100%">
          <Loading />
        </Box>
      )}

      {isError && <Error />}

      {data && !isLoading && !isError && data.length === 0 && (
        <Box w="100%">
          {clustersSelected.length > 1 && (
            <Button variant="ghost" label={`${t({ id: 'GENERIC.LABEL.BACK' })}`} onClick={() => handleBack()} />
          )}
          <Empty />
        </Box>
      )}

      {data && !isLoading && !isError && data.length > 0 && (
        <Box w="100%">
          <Comparison
            data={data}
            handleOpenChildAudience={handleOpenChildAudience}
            handleBack={handleBack}
            clustersSelected={clustersSelected}
          />
        </Box>
      )}
    </>
  );
};

export default TabSelector;
