/* eslint-disable camelcase */
import React, { useEffect, useCallback } from 'react';
import { Stack } from '@retargetly/ui-components';
import { CircularProgress } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useCookies } from 'react-cookie';
import { loginFromToken } from 'actions';
import { useQueryParams } from 'hooks/useQueryParams';
import * as routes from '../../constants/routes';
import useAuth from '../../hooks/useAuth';

const Auth = () => {
  const query = useQueryParams();
  const view_as = query.get('view_as');
  const path = query.get('path');
  const history = useHistory();
  const [cookies, , removeCookie] = useCookies(['jwtToken', 'rtToken']);
  const dispatch = useDispatch();
  const { isLoggedIn } = useAuth();

  const onError = () => {
    removeCookie('jwtToken', {
      path: '/',
      domain: process.env.REACT_APP_API_DOMAIN,
      sameSite: false
    });
    removeCookie('rtToken', {
      path: '/',
      domain: process.env.REACT_APP_API_DOMAIN,
      sameSite: false
    });
    history.push(routes.SINGLE_SIGN_ON_AUTH);
  };

  const onLoginFromToken = useCallback(
    (token, rtToken, viewas) => {
      dispatch(loginFromToken(token, rtToken, viewas, onError));
    },
    [dispatch]
  );

  /**
   * @description This useEffect is used to check if the user is authenticated and has a token in the cookies, if both conditions aren't met, the user is redirected to sso provider.
   */
  useEffect(() => {
    if (!isLoggedIn && (cookies?.jwtToken === undefined || cookies?.rtToken === undefined)) {
      history.push(routes.SINGLE_SIGN_ON_AUTH);
    }
  }, []);

  /**
   * @description This useEffect is used to check if the user isn't authenticated and has a token in the cookies, if both conditions are met, the cookie will be used for login.
   */
  useEffect(() => {
    if (!isLoggedIn && cookies?.jwtToken !== undefined && cookies?.rtToken !== undefined) {
      onLoginFromToken(cookies?.jwtToken, cookies?.rtToken, view_as);
    }
  }, []);

  /*
   * after the user is authenticated, the user is redirected to the path that was passed as a query parameter.
   */
  useEffect(() => {
    if (isLoggedIn) {
      if (path && view_as) {
        history.push(`${path}?view_as=${view_as}`);
      } else {
        history.push('/');
      }
    }
  }, [isLoggedIn]);

  return (
    <Stack h="100vh" alignItems="center" justifyContent="center">
      <CircularProgress color="primary" value={0} size="70px" />
    </Stack>
  );
};

export default Auth;
