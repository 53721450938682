import { expressionBucketToExpressionPlain } from 'utils/expressionTransformTo';
import { useQuery } from '@tanstack/react-query';
import useOrganization from './useOrganization';
import { ServiceFactory } from '../services/FactoryService';
import useExpressionBucket from './planning/useExpressionBucket';

const planning = ServiceFactory.get('plannings');
const FACEBOOK_PLATFORM = 25;

const usePrice = (platformId?: number) => {
  const { expressionBucket, hasValidExpression } = useExpressionBucket();
  const { currentOrganization } = useOrganization();

  const { isLoading, data, error } = useQuery(
    ['GET_PRICE', expressionBucket, platformId, currentOrganization.clientId],
    () => {
      return planning.getPlatfformPrice(
        expressionBucketToExpressionPlain(expressionBucket),
        platformId,
        currentOrganization.clientId
      );
    },
    {
      enabled: hasValidExpression && platformId !== FACEBOOK_PLATFORM
    }
  );

  return {
    isLoading,
    price: data ?? 0,
    error
  };
};

export default usePrice;
