import React, { useEffect, useState } from 'react';
import useEventTracker from 'hooks/useEventTracker';
import { Input, Select, Row, Switch, Typography, Button, Tooltip, message } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import { Box, colors } from '@retargetly/ui-components';
import usePlanning from 'hooks/planning/usePlannings';
import useBlueprints, { GET_BLUEPRINTS } from 'hooks/planning/useBlueprints';
import { toggleShareModal } from 'actions';
import { SHARE } from 'constants/flows';
import { useQueryClient } from '@tanstack/react-query';
import { setCurrentPlanification } from 'actions/plannings';
import { rowDistance, textDistance } from './formStyle';
import { usePlanificationContext } from '../context';

const { Text } = Typography;
const { Option } = Select;

const First = () => {
  const {
    setData,
    formState,
    isNewPlanification,
    currentPlanification,
    currentOrganization,
    changeStep
  } = usePlanificationContext();
  const { trackEvent } = useEventTracker();
  const { name, folder } = formState;
  const { planification, setPlanification, savePlanning } = usePlanning();
  const { folders, blueprints, updateBlueprint } = useBlueprints();
  const [planificationName, setPlanificationName] = useState('');
  const [nameError, setNameError] = useState(false);
  const [folderError, setFolderError] = useState(false);
  const [selectedFolderName, setSelectedFolderName] = useState('');
  const [newFolderName, setNewFolderName] = useState('');
  const [newFolder, setNewFolder] = useState(false);
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  useEffect(() => {
    setPlanificationName(name);
    setSelectedFolderName(folder);
  }, [name, folder]);

  const verifyNonexistent = (type, value) => {
    switch (type) {
      case 'NAME':
        if (blueprints.filter(item => item.name.toLowerCase() === value.toLowerCase()).length === 0) {
          setData({ name: 'name', value });
          setPlanification({ ...planification, name: value });
          setNameError(false);
        } else {
          setNameError(true);
        }
        break;
      case 'FOLDER':
        if (folders.filter(item => item.name.toLowerCase() === value.toLowerCase()).length === 0) {
          setFolderError(false);
          setPlanification({ ...planification, folder: value });
          setData({ name: 'folder', value });
        } else {
          setFolderError(true);
        }
        break;
      default:
        return false;
    }
    return true;
  };

  // If new Folder sets to true
  const checkNewFolder = checked => {
    setNewFolder(!newFolder);
    if (!checked) {
      // after disable new folder switch, set the correct folder name
      setData({ name: 'folder', value: selectedFolderName });
    }
  };

  // Folder Select Handler
  const handleSwitchFolder = e => {
    setSelectedFolderName(e);
    setData({ name: 'folder', value: e });
  };

  const handleNameChange = e => {
    setPlanificationName(e.target.value);
    verifyNonexistent('NAME', e.target.value);
  };

  // New Folder Name Handler
  const handleNewFolderChange = e => {
    setNewFolderName(e.target.value);
    verifyNonexistent('FOLDER', e.target.value);
  };

  const onUpdateBluePrint = async () => {
    const props = {
      payload: { name: `${newFolderName || selectedFolderName} | ${planificationName}` },
      segmentId: currentPlanification?.segmentId,
      clientId: currentOrganization?.clientId
    };
    return updateBlueprint.mutateAsync(props, {
      onSuccess: result => {
        message.success(result?.message);
        dispatch(
          setCurrentPlanification({
            ...currentPlanification,
            name: `${newFolderName || selectedFolderName} |${planificationName}`
          })
        );
        queryClient.invalidateQueries([GET_BLUEPRINTS]);
        trackEvent('UPDATE_PLAN', {
          planName: result?.name,
          folderName: result?.folder
        });
      },
      onError: error => {
        message.error(error.error);
      }
    });
  };

  const saveBluePrint = async () => {
    if (isNewPlanification) {
      await savePlanning.mutate(
        {
          name: planificationName,
          folder: newFolderName || selectedFolderName
        },
        {
          onSuccess: result => {
            message.success(result?.message);
            trackEvent('SAVED_PLAN', {
              planName: result?.name,
              folderType: result?.newFolder ? 'New' : 'Existing',
              folderName: result?.folder
            });
            dispatch(toggleShareModal(false));
          },
          onError: error => {
            message.error(error.error);
          }
        }
      );
    }
    if (!isNewPlanification) {
      try {
        await onUpdateBluePrint();
        dispatch(toggleShareModal(false));
      } catch (error) {
        console.error(error);
      }
    }
  };

  const saveAndShareBluePrint = async () => {
    if (isNewPlanification) {
      await savePlanning.mutateAsync(
        {
          name: planificationName,
          folder: newFolderName || selectedFolderName
        },
        {
          onSuccess: result => {
            message.success(result?.message);
            trackEvent('SHARED_PLAN', {
              planName: result?.name,
              folderType: result?.newFolder ? 'New' : 'Existing',
              folderName: result?.folder
            });
          },
          onError: error => {
            message.error(error.error);
          }
        }
      );
    }
    if (!isNewPlanification) {
      try {
        await onUpdateBluePrint();
      } catch (error) {
        console.error(error);
      }
    }
    changeStep(SHARE);
  };

  return (
    <Box>
      <Row style={rowDistance}>
        <Text strong style={textDistance}>
          {t({
            id: `GENERIC.LABEL.NAME`
          })}
        </Text>
        <Tooltip
          title={t({
            id: `MODAL.SHARE.NAME.ERROR.IN_USE`
          })}
          defaultVisible={nameError}
          visible={nameError}
          placement="right"
          zIndex={999999}
          color={colors.error[500]}
        >
          <Input
            className="rounded-input"
            value={planificationName}
            onChange={handleNameChange}
            placeholder={t({
              id: `GENERIC.LABEL.NAME`
            })}
          />
        </Tooltip>
      </Row>
      <Row style={rowDistance}>
        <Text strong style={textDistance}>
          {t({
            id: `GENERIC.LABEL.FOLDER`
          })}
        </Text>
        <Select
          showArrow
          showSearch
          className="custom-select"
          value={newFolder ? '' : selectedFolderName}
          disabled={newFolder}
          style={{ width: '100%' }}
          placeholder={t({
            id: `MODAL.SHARE.FOLDER.PLACEHOLDER`
          })}
          onChange={e => handleSwitchFolder(e)}
        >
          {folders?.map(foldr => (
            <Option key={foldr.name} value={foldr.name}>
              {foldr.name}
            </Option>
          ))}
        </Select>
      </Row>
      <Row style={rowDistance}>
        <Text style={{ marginRight: 10 }}>
          {t({
            id: `MODAL.SHARE.FOLDER.CREATE`
          })}
        </Text>
        <Switch
          checked={newFolder}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={e => checkNewFolder(e)}
        />
      </Row>
      {newFolder && (
        <Row style={{ marginBottom: 15 }}>
          <Tooltip
            title={t({
              id: `MODAL.SHARE.FOLDER.ERROR.IN_USE`
            })}
            defaultVisible={folderError}
            visible={folderError}
            placement="right"
            zIndex={999999}
            color={colors.error[500]}
          >
            <Input
              value={newFolderName}
              onChange={handleNewFolderChange}
              placeholder={t({
                id: `MODAL.SHARE.FOLDER.NAME_PLACEHOLDER`
              })}
            />
          </Tooltip>
        </Row>
      )}
      <Row justify="end">
        <Button
          type="primary"
          key="next"
          name="next"
          loading={updateBlueprint.isLoading || savePlanning.isLoading}
          disabled={!planificationName || nameError || (newFolder && newFolderName === '') || folderError}
          onClick={saveBluePrint}
        >
          {isNewPlanification
            ? t({
                id: `GENERIC.LABEL.SAVE`
              })
            : t({
                id: `GENERIC.LABEL.UPDATE`
              })}
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          type="primary"
          key="next"
          name="next"
          loading={updateBlueprint.isLoading || savePlanning.isLoading}
          disabled={!planificationName || nameError || (newFolder && newFolderName === '') || folderError}
          onClick={saveAndShareBluePrint}
        >
          {isNewPlanification
            ? t({
                id: `GENERIC.LABEL.SAVE_AND_SHARE`
              })
            : t({
                id: `GENERIC.LABEL.UPDATE_AND_SHARE`
              })}
        </Button>
      </Row>
    </Box>
  );
};

First.propTypes = {};
First.defaultProps = {};

export default First;
