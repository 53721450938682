import { useSelector, useDispatch } from 'react-redux';
import { Organization } from 'utils/interfaces';
import * as selectors from '../selectors/organization';
import * as actions from '../actions';

const useOrganization = () => {
  const { setCurrentOrganization } = actions;
  const organizations: Organization[] = useSelector(selectors.getOrganizations);
  const currentOrganization: Organization = useSelector(selectors.getCurrentOrganization);
  const dispatch = useDispatch();
  const onGetOrganizations = () => dispatch(actions.getOrganizations());
  const onSetCurrentOrganization = (organization: Organization) => dispatch(setCurrentOrganization(organization));
  return {
    currentOrganization,
    organizations,
    onGetOrganizations,
    onSetCurrentOrganization
  };
};

export default useOrganization;
