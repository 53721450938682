/* eslint-disable camelcase */

export type expression = {
  demographics: number[];
  expanded: number[];
  filtered: number[];
  excluded: number[];
};

export type RootState = {
  auth: any;
  segments: any;
  searchSegments: any;
  customPlanifications: any;
  insights: any;
  ui: any;
};

export type GetEstimationPayload = {
  metrics: string[];
  segments: expression;
  source: string[];
};

export interface charProps {
  hairStyle: string;
  hairColor: string[];
  facialHair: string;
  skinColor: string;
  tatoos: string;
  glass: string;
  clothes: string;
  mouth: string;
  eyes: string;
  eyebrows: string;
  accesories: string;
  glassOpacity: string;
  extra: string[];
  clothesColor: string;
}

export type Answer = {
  answer: string;
  isValid: boolean;
  explanation: string;
  value: number;
  votes: number;
  score: number;
};

export interface Question {
  id: number;
  question: string;
  segments: string;
  answered: boolean;
  answer_date: Date;
  answers: Answer[];
  votes: number;
  score: number;
  created: Date;
  userId: number;
  label: string;
}

export type Plans = 'starter' | 'growth';

// Interface for user Registation
export interface Iuser {
  name: string;
  email: string;
  password: string;
  country: string;
  agency: string;
  agencySize: string;
  plan: Plans;
  userInviteMails?: string[];
}

export type Tsegment = {
  id: string;
  segmentId: number;
  parentId: string;
  name: string;
  country: string[];
  demographic: number;
  price: number;
  groupId: string;
  rootParentId: string;
  type: null | string;
};

export type segmentList = {
  parent: string;
  id: string;
  name: string;
  price: number;
};

export type groupSegment = {
  parent: string;
  id: string;
  name: string;
  price: number;
  raw: { childrens: any[] };
  type: string | null;
  allIds: string[];
};

export interface ISegmentAffinity {
  id: number;
  name: string;
  hasChildrens: boolean;
  devices: number;
  outer_bar: number;
  inner_bar: number;
  affinity_percent: number;
}

export interface Organization {
  clientId: number;
  name: string;
  enabled: boolean;
  type: string;
  clusterId: number;
  clusterName: string;
  parent: number;
  image: string;
  id_theme: number;
  marketplace: number;
  id: number;
  theme: Theme;
  products: {
    dmp: boolean;
    loop: boolean;
  };
}

export interface ICountriesObject {
  code: string;
  country: string;
}

export interface Theme {
  id: number;
  domain: string;
  title: string;
  image_login: string;
  image_header: string;
  favicon: string;
  image_theme: string;
  primary_color: string;
  secondary_color: string;
  background_color: string;
}

export interface UserProfile {
  accessToken: {
    accessToken: string;
    accessTokenExpires: string;
  };
  access_token: string;
  allow_all_data_query: number;
  clusterName: string;
  demoAccount: number;
  email: string;
  expires_in: number;
  features: any[];
  id: number;
  id_client: number;
  id_cluster: number;
  id_parent_client: number;
  lang: string;
  marketplace: number;
  marketplaceAnonymous: number;
  name: string;
  onlyPlanner: number;
  organization: {
    id: number;
    name: string;
    products: {
      dmp: boolean;
      loop: boolean;
    };
  };
  refreshToken: {
    refreshToken: string;
    refreshTokenExpires: string;
  };
  token_type: string;
  type: string;
  user: {
    features: any[];
    id: number;
    username: string;
  };
  username: string;
  view_on: boolean;
  internal_user_id: number;
  main_org: number;
}

export interface BrandIcons {
  size?: 'sm' | 'md' | 'lg';
}
export const AFFINITY_RANGES = {
  low: { min: 82, max: 87 },
  medium: { min: 88, max: 93 },
  high: { min: 94, max: 99 }
};

export const AFFINITY_LABELS = {
  low: 'SEARCH.GPT.AFFINITY_LOW',
  medium: 'SEARCH.GPT.AFFINITY_MEDIUM',
  high: 'SEARCH.GPT.AFFINITY_HIGH'
};

export const SIMILITY_LABELS = {
  low: 'SEARCH.GPT.SIMILARITY_LOW',
  medium: 'SEARCH.GPT.SIMILARITY_MEDIUM',
  high: 'SEARCH.GPT.SIMILARITY_HIGH'
};
