import { createContext, useContext } from 'react';
import { useSetupPlanification } from './use-setup-planification';

type SetupContextProperties = ReturnType<typeof useSetupPlanification>;
interface PlanificacionContextProps extends SetupContextProperties {}

export const PlanificacionContext = createContext<PlanificacionContextProps>({} as PlanificacionContextProps);

export const usePlanificationContext = () => {
  const context = useContext(PlanificacionContext);
  if (!context) {
    throw new Error('usePlanificationContext must be used within a PlanificationProvider');
  }
  return context;
};
