import React from 'react';
import { Flex, Box, Heading, Text, FailSvg } from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';

export const EmptyPlanning = () => {
  const t = useFormatMessage();
  return (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center" my={4}>
      <Flex
        w="calc(100% - 490px)"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxW="300px"
        maxH="300px"
        minW="150px"
        minH="150px"
      >
        <FailSvg />
      </Flex>
      <Box my={2}>
        <Heading size="200" color="black">
          {t({
            id: `SEARCH.EMPTY.TITLE`
          })}
        </Heading>

        <Text size="bodyDefault" color="black">
          {t({
            id: `SEARCH.EMPTY.SUBTITLE`
          })}
        </Text>
      </Box>
    </Flex>
  );
};
