import { useState, useEffect } from 'react';
import { Crumb } from '../interfaces/breadcrumbs';

const useBreadcrumbs = (): Crumb | null => {
  const [currentBreadcrumb, setCurrentBreadcrumb] = useState<Crumb | null>(null);

  useEffect(() => {
    const arrayBreadcrumbs: Crumb[] = [
      { url: '/keywords', name: 'Keywords', isFolder: false },
      { url: '/planner/search', name: 'Search', isFolder: false }
    ];

    const currentPath = window.location.pathname;

    const matchedBreadcrumb = arrayBreadcrumbs.find(crumb => crumb.url === currentPath);

    setCurrentBreadcrumb(matchedBreadcrumb || null);
  }, []);

  return currentBreadcrumb;
};

export default useBreadcrumbs;
