import { Feature } from './types';
import { LocationState } from './LocationProvider';

type LocationAction =
  | {
      type: 'SET_USER_LOCATION';
      payload: [number, number];
    }
  | {
      type: 'SET_PLACES';
      payload: Feature[];
    }
  | {
      type: 'SET_LOADING_PLACES';
    }
  | {
      type: 'SET_RADIUS';
      payload: number;
    }
  | {
      type: 'ADD_LOCATION';
      payload: Feature;
    }
  | {
      type: 'DELETE_LOCATION';
      payload: string;
    }
  | {
      type: 'RESET_LOCATION';
    };

export const locationReducer = (state: LocationState, action: LocationAction): LocationState => {
  switch (action.type) {
    case 'SET_USER_LOCATION':
      return {
        ...state,
        isLoading: false,
        userLocation: action.payload
      };
    case 'SET_LOADING_PLACES':
      return {
        ...state,
        isLoadingPlaces: true,
        places: []
      };

    case 'SET_PLACES':
      return {
        ...state,
        isLoadingPlaces: false,
        places: action.payload
      };

    case 'SET_RADIUS':
      return {
        ...state,
        radius: action.payload
      };

    case 'ADD_LOCATION':
      return {
        ...state,
        locations: state.locations?.slice().concat(action.payload)
      };

    case 'DELETE_LOCATION':
      return {
        ...state,
        locations: state.locations?.filter(location => location.id !== action.payload)
      };

    case 'RESET_LOCATION':
      return {
        ...state,
        locations: []
      };

    default:
      return state;
  }
};
