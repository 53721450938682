import { Map, Marker } from 'mapbox-gl';
import { MapState } from './MapProvider';

type MapAction =
  | { type: 'SET_MAP'; payload: Map }
  | { type: 'SET_MARKER'; payload: Marker[] }
  | { type: 'SET_TOOL'; payload: string | undefined }
  | { type: 'SET_DRAW'; payload: MapboxDraw }
  | { type: 'SET_BULK_ERROR'; payload: boolean };

export const mapReducer = (state: MapState, action: MapAction) => {
  switch (action.type) {
    case 'SET_MAP':
      return {
        ...state,
        map: action.payload
      };

    case 'SET_MARKER':
      return {
        ...state,
        markers: action.payload
      };

    case 'SET_TOOL':
      return {
        ...state,
        toolSelected: action.payload
      };

    case 'SET_DRAW': {
      return {
        ...state,
        drawInstance: action.payload
      };
    }

    case 'SET_BULK_ERROR': {
      return {
        ...state,
        bulkFileError: action.payload
      };
    }

    default:
      return state;
  }
};
