const contentWrapper = {
  padding: '14px 0px',
  margin: '10px 0px'
};

const modalWrapper = {
  margin: 20,
  boxShadow: '0px 0px 4px 4px rgba(245,245,245, 0.5)',
  borderRadius: 8
};

const rowDistance = {
  padding: '8px 0px'
};
const textDistance = {
  marginBottom: '5px'
};

const stepsWrapper = {
  padding: 0
};

const clientButtonStyle = { padding: '15px 0px' };

export { modalWrapper, rowDistance, textDistance, contentWrapper, stepsWrapper, clientButtonStyle };
