import React from 'react';
import { Spin } from 'antd';

interface Props {}

const SplashSpinner: React.FC<Props> = () => {
  return (
    <div style={{ display: 'grid', placeItems: 'center', height: '100vh' }}>
      <Spin size="large">
        <div className="content" />
      </Spin>
    </div>
  );
};

export default SplashSpinner;
