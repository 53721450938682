import React from 'react';
import { IntlProvider } from 'react-intl-hooks';

import { getDefaultLang } from './langHelper';
import useAuth from '../hooks/useAuth';
import ptMessages from './pt.json';
import enMessages from './en.json';
import esMessages from './es.json';

const allMessages = {
  en: enMessages,
  es: esMessages,
  pt: ptMessages
};

export function I18nProvider({ children }: { children: React.ReactNode }) {
  const { userLang } = useAuth();
  const locale: keyof typeof allMessages = userLang || getDefaultLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages} defaultLocale="es">
      {children}
    </IntlProvider>
  );
}
