import { useState, useEffect } from 'react';
import { CDN_URL } from 'constants/config';
import { LOOP_FALLBACK_THEME } from 'constants/fallback-theme';
import { Theme } from 'utils/interfaces';
import getUrlDomain from 'utils/get-url-domain';
import useGetAllThemes from 'hooks/themes/useGetAllThemes';
import useOrganization from 'hooks/useOrganization';

const useTheme = () => {
  const [theme, setTheme] = useState(LOOP_FALLBACK_THEME);
  const { currentOrganization } = useOrganization();
  const domain = getUrlDomain(window.location.href);
  const { data: allThemes } = useGetAllThemes();

  /* 
    Set theme domain-base
  */
  useEffect(() => {
    if (allThemes !== undefined && currentOrganization === undefined) {
      const themeSelected = allThemes.filter((clientTheme: Theme) => {
        const themeCollection = clientTheme?.domain?.split(',');
        return themeCollection === undefined ? false : themeCollection.includes(domain);
      });

      if (themeSelected[0]) {
        setTheme(themeSelected[0]);
      }
    }
  }, [allThemes, currentOrganization, domain]);

  /* 
    Set theme client-base
  */
  useEffect(() => {
    if (
      currentOrganization !== undefined &&
      currentOrganization.theme !== undefined &&
      allThemes !== undefined &&
      allThemes.length !== 0
    ) {
      const themeSelected = allThemes.filter((clientTheme: Theme) => {
        return clientTheme.id === currentOrganization.theme.id;
      });
      if (themeSelected[0]) {
        setTheme(themeSelected[0]);
      }
    }
  }, [currentOrganization, allThemes]);

  const getThemeProp = (propName: keyof typeof LOOP_FALLBACK_THEME): string => {
    // @ts-ignore
    return theme[`${propName}`];
  };

  const getThemePropUrl = (propName: keyof typeof LOOP_FALLBACK_THEME) => {
    return `${CDN_URL}/images/${getThemeProp(propName)}`;
  };

  return {
    getThemePropUrl,
    getThemeProp
  };
};

export default useTheme;
