import React from 'react';
import { Button, Icon, Text, Heading, Flex, Box, IconChip } from '@retargetly/ui-components';
import { Marker } from 'mapbox-gl';
import { useMapContext } from 'components/GeoTool/context/Map/MapProvider';
import { useFormatMessage } from 'react-intl-hooks';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';
import { faMapPin, faCrosshairs, faFile } from '@fortawesome/pro-solid-svg-icons';
import logo from 'images/searchGeo.svg';
import { useLocationContext } from '../../context/Locations/LocationProvider';
import CreationForm from '../CreatonForm/CreationForm';

const locationContainer: React.CSSProperties = {
  textAlign: 'center',
  width: '340px',
  backgroundColor: 'white',
  overflowY: 'auto'
};

const CreationPanel = () => {
  const { locations, deletePlaces } = useLocationContext();
  const { drawInstance, goToPoint, map } = useMapContext();
  const t = useFormatMessage();

  const haveLocationsCreated = locations.length > 0;
  const locationsCreatedWithPinTool = locations.filter(
    (location: any) => location?.properties?.latLongTool === undefined && location?.properties?.bulkTool === undefined
  );
  const haveLocationsCreatedWithPinTool = locationsCreatedWithPinTool.length > 0;

  const locationsCreatedWithLatLongTool = locations.filter(
    (location: any) => location?.properties?.latLongTool === true
  );
  const haveLocationsCreatedWithLatLongTool = locationsCreatedWithLatLongTool.length > 0;

  const locationsCreatedWithBulkTool = locations.filter((location: any) => location?.properties?.bulkTool === true);

  const haveLocationsCreatedWithBulkTool = locationsCreatedWithBulkTool.length > 0;

  const deleteLocation = (id: string) => {
    // eslint-disable-next-line no-unused-expressions
    map?._markers?.forEach((marker: Marker) => marker.remove());
    drawInstance.delete(id);
    deletePlaces(id);
  };

  const flyToLocation = (lat: number, long: number) => {
    goToPoint(long, lat);
    // eslint-disable-next-line no-unused-expressions
    map?._markers?.forEach((marker: Marker) => marker.remove());
    new Marker().setLngLat([long, lat]).addTo(map);
  };

  return (
    <div style={locationContainer}>
      <Flex
        alignItems="flex-start"
        flexDirection="column"
        py="1rem"
        pl="1rem"
        borderBottom="1px solid #E3E6EE"
        borderTop="1px solid #E3E6EE"
        width="100%"
      >
        <Heading size="100">
          {t({
            id: `GEOTOOL.CREATIONPANEL.PLANNING`
          })}
        </Heading>
      </Flex>
      <Flex
        alignItems="flex-start"
        flexDirection="column"
        py="1rem"
        pl="1rem"
        borderBottom="1px solid #E3E6EE"
        borderTop="1px solid #E3E6EE"
        width="100%"
      >
        <Text size="bodyBold">
          {t({
            id: `GEOTOOL.CREATIONPANEL.LOCATION`
          })}
        </Text>
      </Flex>
      <Flex
        py="1rem"
        pl="1rem"
        borderBottom="1px solid #E3E6EE"
        borderTop="1px solid #E3E6EE"
        width="100%"
        height="250px"
        overflowY="auto"
        flexDirection="column"
      >
        {!haveLocationsCreated && (
          <Flex flexDirection="column" alignItems="center">
            <Flex h="150px" w="150px" alignItems="center" justifyContent="center">
              <img src={logo} alt="search logo" />
            </Flex>
            <Flex flexDirection="column">
              <Text size="bodyMedium" align="center">
                {t({
                  id: `GEOTOOL.CREATIONPANEL.PIN`
                })}
              </Text>
              <Text size="bodySmall" align="center">
                {t({
                  id: `GEOTOOL.CREATIONPANEL.TOOL`
                })}
              </Text>
            </Flex>
          </Flex>
        )}
        {haveLocationsCreatedWithPinTool && (
          <>
            <Flex alignItems="center" my={2}>
              <IconChip
                ico={<Icon icon={faMapPin} name="map-pin" size="sm" color="primary.500" />}
                ariaLabel="icon-button"
              />
              <Box ml={2}>
                <Text size="bodyBold">
                  {t({
                    id: `GEOTOOL.CREATIONPANEL.PIN.LOCATION`
                  })}
                </Text>
              </Box>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              {locationsCreatedWithPinTool.map((location: any) => {
                return (
                  <Flex
                    flexDirection="row"
                    width="100%"
                    key={location.id}
                    onClick={() => flyToLocation(location?.properties?.center[1], location?.properties?.center[0])}
                    cursor="pointer"
                  >
                    <Box flexGrow={1}>
                      {`Lat: ${location?.properties?.center[1]
                        .toString()
                        .slice(0, 8)}, Long: ${location?.properties?.center[0].toString().slice(0, 8)}`}
                    </Box>
                    <Box mr="20px">
                      <Button
                        variant="ghost"
                        onClick={e => {
                          e.stopPropagation();
                          deleteLocation(location.id);
                        }}
                        icon={<Icon icon={faXmark} name="check" size="sm" />}
                        size="xs"
                      />
                    </Box>
                  </Flex>
                );
              })}
            </Flex>
          </>
        )}
        {haveLocationsCreatedWithLatLongTool && (
          <>
            <Flex alignItems="center" my={2}>
              <IconChip
                ico={<Icon icon={faCrosshairs} name="cross" size="sm" color="primary.500" />}
                ariaLabel="icon-button"
              />
              <Box ml={2}>
                <Text size="bodyBold">
                  {t({
                    id: `GEOTOOL.CREATIONPANEL.LATLOING.LOCATION`
                  })}
                </Text>
              </Box>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              {locationsCreatedWithLatLongTool.map((location: any) => {
                return (
                  <Flex
                    flexDirection="row"
                    width="100%"
                    key={location.id}
                    onClick={() => flyToLocation(location?.properties?.center[1], location?.properties?.center[0])}
                    cursor="pointer"
                  >
                    <Box flexGrow={1}>
                      {`Lat: ${location?.properties?.center[1]
                        .toString()
                        .slice(0, 8)}, Long: ${location?.properties?.center[0].toString().slice(0, 8)}`}
                    </Box>
                    <Box mr="20px">
                      <Button
                        variant="ghost"
                        onClick={e => {
                          e.stopPropagation();
                          deleteLocation(location.id);
                        }}
                        icon={<Icon icon={faXmark} name="check" size="sm" />}
                        size="xs"
                      />
                    </Box>
                  </Flex>
                );
              })}
            </Flex>
          </>
        )}
        {haveLocationsCreatedWithBulkTool && (
          <>
            <Flex alignItems="center" my={2}>
              <IconChip
                ico={<Icon icon={faFile} name="file" size="sm" color="primary.500" />}
                ariaLabel="icon-button"
              />
              <Box ml={2}>
                <Text size="bodyBold">
                  {t({
                    id: `GEOTOOL.CREATIONPANEL.BULK.LOCATION`
                  })}
                </Text>
              </Box>
            </Flex>
            <Flex flexDirection="column" alignItems="center">
              {locationsCreatedWithBulkTool.map((location: any) => {
                return (
                  <Flex
                    flexDirection="row"
                    width="100%"
                    key={location.id}
                    onClick={() => flyToLocation(location?.properties?.center[1], location?.properties?.center[0])}
                    cursor="pointer"
                  >
                    <Box flexGrow={1}>
                      {`Lat: ${location?.properties?.center[1]
                        .toString()
                        .slice(0, 8)}, Long: ${location?.properties?.center[0].toString().slice(0, 8)}`}
                    </Box>
                    <Box mr="20px">
                      <Button
                        variant="ghost"
                        onClick={e => {
                          e.stopPropagation();
                          deleteLocation(location.id);
                        }}
                        icon={<Icon icon={faXmark} name="check" size="sm" />}
                        size="xs"
                      />
                    </Box>
                  </Flex>
                );
              })}
            </Flex>
          </>
        )}
      </Flex>

      <Flex
        alignItems="center"
        flexDirection="column"
        py="1rem"
        px="1rem"
        borderBottom="1px solid #E3E6EE"
        borderTop="1px solid #E3E6EE"
        width="100%"
        minHeight="450px"
      >
        <Flex alignItems="flex-start" width="100%">
          <Text size="bodyBold">
            {t({
              id: `GEOTOOL.CREATIONPANEL.GENERAL.INFO`
            })}
          </Text>
        </Flex>
        <CreationForm />
      </Flex>
    </div>
  );
};

export default CreationPanel;
