import { useMutation } from '@tanstack/react-query';
import { ServiceFactory } from '../../../services/FactoryService';

const services = ServiceFactory.get('segments');

export const IA_KEY = 'SEARCH_IA';

const getIaSegments = ({ query, country }: any): Promise<any> => {
  return services.getSegmentsByIa(query, country).then((res: any) => {
    return res?.data;
  });
};

export const useGetIaSearch = () => {
  return useMutation({ mutationKey: [IA_KEY], mutationFn: getIaSegments });
};
