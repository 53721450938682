/* eslint-disable camelcase */
import React, { FC } from 'react';
import { Flex, Box, Span, colors, Alert } from '@retargetly/ui-components';
import { Divider, Skeleton } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { numberParser } from '../utils/numberParser';
import { calculatePercentage } from '../utils/calculatePercentage';
import '../styles/components/estimation.scss';

type Metrics = {
  total_devices: number;
  android_devices: number;
  ios_devices: number;
  web_no_mobile_devices: number;
  web_mobile_devices: number;
  top_clusters: {
    segments: {
      percent: number;
    }[];
  }[];
};

type Props = {
  data: Metrics;
  isLoadingMetrics: boolean;
  isError: boolean;
};

const stylesCategories = {
  fontSize: '0.75rem',
  fontWeight: 400,
  color: 'neutrals.400',
  letterSpacing: '1.08px',
  lineHeight: '14px'
};

const stylesLabels = { margin: '0px 5px 0px 0px', fontSize: '14px', color: '#757580', lineHeight: '16px' };

const stylesPercentage = {
  margin: '0px 5px 0px 0px',
  fontSize: '14px',
  fontWeight: 500,
  color: '#424250',
  lineHeight: '16px'
};

const EstimationComponent: FC<Props> = ({ data, isError, isLoadingMetrics }) => {
  const t = useFormatMessage();

  const formatedTotalDevices = numberParser(data?.total_devices || 0);

  const {
    total_devices: totalDevices = 0,
    android_devices: androidDevices = 0,
    ios_devices: iosDevices = 0,
    web_no_mobile_devices: webNoMobileDevices = 0,
    web_mobile_devices: webMobileDevices = 0
  } = data || {};

  const { percent: malePercentage = 0 } = data?.top_clusters[0]?.segments[0] || {};
  const { percent: femalePercentage = 0 } = data?.top_clusters[0]?.segments[1] || {};

  const totalDevicesSum = iosDevices + androidDevices + webNoMobileDevices + webMobileDevices;

  return (
    <Flex alignItems="center" justifyContent="center" my={2} py={2} px={4} minHeight="100px">
      {isError ? (
        <Box display="flex" alignItems="center" justifyContent="center" height="100%">
          <Alert
            title={`${t({
              id: `INSIGHTS.RIGHT.ERROR`
            })}`}
            status="error"
          />
        </Box>
      ) : (
        <>
          <Box
            flex="1 1 100%"
            maxWidth="200px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            height="100%"
            ml={2}
          >
            {isLoadingMetrics ? (
              <Flex alignItems="center" justifyContent="center" minHeight="100px" mb={2}>
                <Skeleton
                  active
                  title={false}
                  paragraph={{
                    rows: 2,
                    width: 100
                  }}
                />
              </Flex>
            ) : (
              <Flex flexDirection="column" style={{ textAlign: 'center' }}>
                <Span fontSize="32px" fontWeight={600} color={colors.blue[500]}>
                  {formatedTotalDevices}
                </Span>
                <Span fontSize="14px" color={colors.neutrals[400]}>
                  {t({
                    id: `GENERIC.LABEL.SCOPE_ESTIMATED`
                  })}
                </Span>
              </Flex>
            )}
          </Box>
          <Divider type="vertical" style={{ width: '1px', height: '99px' }} />
          <Flex flex="1 1 100%" maxWidth="200px" flexDirection="column" my={2}>
            <Box>
              <Span {...stylesCategories} textTransform="uppercase">
                {t({
                  id: `GENERIC.LABEL.GENDER`
                })}
                :
              </Span>
            </Box>
            {isLoadingMetrics ? (
              <Flex alignItems="center" justifyContent="center">
                <Skeleton
                  active
                  title={false}
                  paragraph={{
                    rows: 1,
                    width: 150
                  }}
                />
              </Flex>
            ) : (
              <Box display="flex" mb={4} height="100%" className="labels">
                <Flex flexDirection="row">
                  <Span {...stylesLabels}>
                    {t({
                      id: `GENERIC.LABEL.FEMALE`
                    })}
                  </Span>{' '}
                  <Span {...stylesPercentage}>
                    {femalePercentage === 0 || femalePercentage === null ? '0' : femalePercentage.toFixed(1)}%
                  </Span>
                </Flex>
                <Flex flexDirection="row">
                  <Span {...stylesLabels}>
                    {t({
                      id: `GENERIC.LABEL.MALE`
                    })}
                  </Span>{' '}
                  <Span {...stylesPercentage}>
                    {malePercentage === 0 || malePercentage === null ? '0' : malePercentage.toFixed(1)}%
                  </Span>
                </Flex>
              </Box>
            )}

            <Box>
              <Span {...stylesCategories} textTransform="uppercase">
                {t({
                  id: `GENERIC.LABEL.DEVICES`
                })}
                :
              </Span>
            </Box>
            {isLoadingMetrics ? (
              <Skeleton
                active
                title={false}
                paragraph={{
                  rows: 1,
                  width: 150
                }}
              />
            ) : (
              <Box display="flex" className="labels">
                <Flex flexDirection="row">
                  <Span {...stylesLabels}>Mobile </Span>

                  <Span {...stylesPercentage}>
                    {iosDevices + androidDevices > totalDevices
                      ? '100%'
                      : `${calculatePercentage(totalDevicesSum, iosDevices + androidDevices)}%`}
                  </Span>
                </Flex>

                <Flex flexDirection="row">
                  <Span {...stylesLabels}>Desktop </Span>

                  <Span {...stylesPercentage}>
                    {webNoMobileDevices + webMobileDevices > totalDevices
                      ? '100%'
                      : `${calculatePercentage(totalDevicesSum, webNoMobileDevices + webMobileDevices)}%`}
                  </Span>
                </Flex>
              </Box>
            )}
          </Flex>
        </>
      )}
    </Flex>
  );
};

export default EstimationComponent;
