import { AFFINITY_RANGES } from './interfaces';

export const getAffinityRange = (affinity: number) => {
  let label = '';

  type AffinityRange = keyof typeof AFFINITY_RANGES;
  // eslint-disable-next-line no-restricted-syntax
  for (const range in AFFINITY_RANGES) {
    if (
      affinity >= AFFINITY_RANGES[range as AffinityRange].min &&
      affinity <= AFFINITY_RANGES[range as AffinityRange].max
    ) {
      label = range;
      break;
    }
  }
  return label as AffinityRange;
};
