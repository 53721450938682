import React from 'react';
import { Flex, Box, Button, Heading, Text } from '@retargetly/ui-components';

type Props = {
  title: string;
  description: string;
  confirmationText?: string;
  onConfirmation?: () => void;
  cancelText?: string;
  onCancel?: () => void;
};

const Confirmation: React.FC<Props> = ({
  title,
  description,
  confirmationText,
  onConfirmation,
  cancelText,
  onCancel
}) => {
  return (
    <Flex flexDirection="column" alignItems="center" height="160px">
      <Box flexGrow={1}>
        <Box>
          <Heading size="200" color="neutrals.500">
            {title}
          </Heading>
        </Box>
        <Box mt={6}>
          <Text size="bodyDefault" color="neutrals.500">
            {description}
          </Text>
        </Box>
      </Box>

      <Flex justifyContent="right" alignItems="center" mt={4} w="100%">
        {onCancel && cancelText && (
          <Box mr={2}>
            <Button variant="ghost" onClick={onCancel} label={cancelText} />
          </Box>
        )}
        {onConfirmation && confirmationText && (
          <Box mr={2}>
            <Button variant="primary" onClick={onConfirmation} label={confirmationText} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default Confirmation;
