/* eslint-disable camelcase */
/* eslint-disable no-useless-catch */
import { useQuery } from '@tanstack/react-query';
import { ServiceFactory } from '../../services/FactoryService';

const keywordsServices = ServiceFactory.get('keywords');

const valueToFilter: Record<number, string> = {
  24: 'last1D',
  168: 'last7D',
  336: 'last14D',
  720: 'last30D'
};

const getFilterForValue = (value: number) => valueToFilter[value] || '';

interface Payload {
  clientId: number;
  keywords: string[];
  country: string;
  value: number;
}

const fetchKeywordEstimation = async (payload: Payload) => {
  const { clientId, keywords, country, value } = payload;

  if (!value) {
    return null;
  }

  try {
    return await keywordsServices.getKeywordVolumeEstimation(clientId, keywords, country, getFilterForValue(value));
  } catch (error) {
    throw error;
  }
};

const useKeywordEstimation = (payload: Payload) => {
  const { clientId, keywords, country, value } = payload;

  return useQuery(
    ['keywordEstimation', clientId, keywords, country, getFilterForValue(value)],
    () => fetchKeywordEstimation(payload),
    {
      enabled: keywords.length > 0 && country !== null && value !== null && country !== '',
      // select: data => {
      //   // This response is an array of one object with a property for each keyword but we only need the device_unique property
      //   return data?.response[0]?.device_unique || null;
      // }
      select: data => {
        if (!data || data.length === 0) {
          return null;
        }
        return data?.response?.total_volume || '0';
      }
    }
  );
};

export default useKeywordEstimation;
