import React, { useEffect, useState } from 'react';
import { Box, Flex, Icon, Text, Switch } from '@retargetly/ui-components';
import RegularSearch from 'components/Search/RegularSearch';
import useCountries from 'hooks/useCountriesFilter';
import IaSearch from 'components/Search/IaSearch';
import { useFormatMessage } from 'react-intl-hooks';
import { useTaxonomyContext } from 'components/Planner/context/hooks';
import { faSparkles } from '@fortawesome/pro-duotone-svg-icons';
import useEventTracker from 'hooks/useEventTracker';
import CountrySelector from './CountrySelector';

const CountryAndSearchContainer = () => {
  const t = useFormatMessage();
  const [showElements, setShowElements] = useState(false);
  const { trackEvent } = useEventTracker();

  const { useIaSearch, setUseIaSearch } = useTaxonomyContext();
  const { countrySelected } = useCountries();

  // When the country is selected from cache, set showElements to true for rendering the search components
  useEffect(() => {
    if (countrySelected) {
      setShowElements(true);
      localStorage.setItem('showElements', JSON.stringify(true));
    }
  }, []);

  useEffect(() => {
    const storedShowElements = localStorage.getItem('showElements');
    setShowElements(storedShowElements ? JSON.parse(storedShowElements) : false);
    /**
     * Track event when user has default IA search for feature usage experiments
     * TODO: remove this when the experiment is over
     */
    if (useIaSearch) {
      trackEvent('HAS_DEFAULT_IA_SEARCH');
    }
  }, []);

  const handleCountrySelect = () => {
    setShowElements(true);
    localStorage.setItem('showElements', JSON.stringify(true));
  };

  const handleSwitchSearch = () => {
    if (!useIaSearch) {
      setUseIaSearch(true);
    } else {
      setUseIaSearch(false);
    }
  };

  return (
    <Flex wrap="wrap" flex="1 auto" alignItems="end" gap={4}>
      <Box w="200px">
        <CountrySelector onCountrySelect={handleCountrySelect} />
      </Box>
      {showElements && countrySelected ? (
        <>
          <Box flexGrow="inherit">{useIaSearch ? <IaSearch /> : <RegularSearch country={countrySelected} />}</Box>

          <Box w="100%">
            <Flex flexDirection="row" alignItems="center" mb={6} gap={2}>
              <Switch size="sm" onChange={handleSwitchSearch} isChecked={useIaSearch} />
              <Text size="bodyMedium" color="neutrals.500">
                {t({
                  id: `SEARCH.GPT.TAB.IA.SEARCH`
                })}
              </Text>
              <Icon icon={faSparkles} name="magnifying-glass" size="sm" color="primary.500" />
            </Flex>
          </Box>
        </>
      ) : null}
    </Flex>
  );
};

export default CountryAndSearchContainer;
