import React from 'react';
import { Menu, Icon, MenuButton, MenuDivider, MenuGroup, MenuItem, MenuList } from '@retargetly/ui-components';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons';
import { useFormatMessage } from 'react-intl-hooks';
import PropTypes from 'prop-types';

const ExpressionBucketButton = ({ addBucket }) => {
  const t = useFormatMessage();
  return (
    <Menu>
      <MenuButton
        variant="ghost"
        backgroundColor="transparent"
        leftIcon={<Icon icon={faPlus} name="plus" className="svgPrimary" size="sm" />}
      >
        {t({
          id: `PLANIFICATION.INFO.NEWGROUP`
        })}
      </MenuButton>
      <MenuList>
        <MenuGroup
          title={t({
            id: `PLANIFICATION.INFO.NEWGROUP_COND`
          })}
        >
          <MenuItem onClick={() => addBucket('OR')}>
            {t({
              id: `GENERIC.LABEL.OR`
            })}
          </MenuItem>
          <MenuItem onClick={() => addBucket('AND')}>
            {t({
              id: `GENERIC.LABEL.AND`
            })}
          </MenuItem>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup
          title={t({
            id: `PLANIFICATION.INFO.NEWGROUP_EXC`
          })}
        >
          <MenuItem onClick={() => addBucket('NOT')}>
            {t({
              id: `GENERIC.LABEL.AND_NOT`
            })}
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
};

ExpressionBucketButton.propTypes = {
  addBucket: PropTypes.func.isRequired
};

export default ExpressionBucketButton;
