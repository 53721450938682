import { ISegmentAffinity } from 'utils/interfaces';
import { expressionBucketToBucketExpressionModel } from 'utils/expressionTransformTo';
import { useQuery } from '@tanstack/react-query';
import { ServiceFactory } from '../services/FactoryService';
import useCountries from './useCountriesFilter';
import useExpressionBucket from './planning/useExpressionBucket';

const insights = ServiceFactory.get('insights');

type AffinityParams = {
  clusterId?: number;
  segmentId?: number;
};

const useAffinity = ({ segmentId, clusterId }: AffinityParams) => {
  const { countrySelected } = useCountries();
  const { expressionBucket } = useExpressionBucket();

  const payload: any = {
    expression: expressionBucketToBucketExpressionModel(expressionBucket),
    metrics: 'count,affinity',
    country: countrySelected
  };
  if (clusterId) {
    payload.cluster_id = [clusterId];
  }
  if (segmentId) {
    payload.segment_id = [segmentId];
  }

  const { data, isLoading, error, isError } = useQuery<ISegmentAffinity[]>(
    ['affinity', payload],
    () => insights.getAffinityData(payload),
    {
      enabled: !!segmentId || !!clusterId
    }
  );

  return {
    data,
    isLoading,
    error,
    isError
  };
};

export default useAffinity;
