import React from 'react';
import { PlanificacionContext } from './planification-context';
import { useSetupPlanification } from './use-setup-planification';

export const PlanificationProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    formState,
    handlePlatformChange,
    handleSeatChange,
    addPlatformAndSeat,
    setData,
    removePlatform,
    resetPlanification,
    isNewPlanification,
    planificationHasBeenSaved,
    planificationHasBeenShared,
    currentPlanification,
    currentOrganization,
    changeStep
  } = useSetupPlanification();

  return (
    <PlanificacionContext.Provider
      value={{
        formState,
        handlePlatformChange,
        handleSeatChange,
        addPlatformAndSeat,
        setData,
        removePlatform,
        resetPlanification,
        isNewPlanification,
        planificationHasBeenSaved,
        planificationHasBeenShared,
        currentPlanification,
        currentOrganization,
        changeStep
      }}
    >
      {children}
    </PlanificacionContext.Provider>
  );
};
