import React from 'react';
import { useMapContext } from 'components/GeoTool/context/Map/MapProvider';
import { Box } from '@retargetly/ui-components';
import PinTool from './PinTool';
import BulkTool from './BulkTool';
import LatLogTool from './LatlogTool';

const ActionPanel = () => {
  const { toolSelected } = useMapContext();

  return (
    <Box
      hidden={toolSelected === undefined}
      position="absolute"
      top="60px"
      left="0px"
      width="290px"
      height="auto"
      backgroundColor="white"
      borderRadius="5px"
      boxShadow="0px 20px 25px -5px rgba(0, 0, 0, 0.1), 0px 10px 10px -5px rgba(0, 0, 0, 0.04)"
      zIndex="100"
      marginLeft="104px"
      padding="10px 15px 10px 15px"
    >
      {toolSelected === 'pin' && (
        <Box display="flex" position="relative" flexDirection="column" alignItems="center" height="100%">
          <PinTool />
        </Box>
      )}
      {toolSelected === 'latlong' && (
        <Box display="flex" position="relative" flexDirection="column" alignItems="center" height="100%">
          <LatLogTool />
        </Box>
      )}
      {toolSelected === 'bulk' && (
        <Box display="flex" position="relative" flexDirection="column" alignItems="center" height="100%">
          <BulkTool />
        </Box>
      )}
    </Box>
  );
};

export default ActionPanel;
