import repository from '../repository';

const getOptions = () => {
  const token = localStorage.getItem('token');
  const rt = localStorage.getItem('rtToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt
    }
  };
};

export default {
  async list() {
    const customPlanification = await repository.get('sondeo/survey/list', getOptions());
    return customPlanification.data.response;
  },
  async create(question) {
    try {
      const customPlanification = await repository.post('sondeo/survey/create', question, getOptions());
      return customPlanification.data.response;
    } catch (err) {
      return err;
    }
  },
  async checklimit() {
    try {
      const customPlanification = await repository.get('sondeo/survey/checklimit', getOptions());
      return customPlanification.data.response;
    } catch (err) {
      return undefined;
    }
  },
  async checkdevices(payload) {
    try {
      const customPlanification = await repository.post('sondeo/survey/checkdevices', payload, getOptions());
      return customPlanification.data.response;
    } catch (err) {
      return undefined;
    }
  }
};
