import mixpanel from 'mixpanel-browser';
import * as actions from '../../constants/actionTypes';
/**
 *  Segment Actions Module
 *  Different actions that execute the reducers
 */

export const setSelectedExpressionBucket = bucketKey => {
  return {
    type: actions.SET_SELECTED_EXPRESSION_BUCKET,
    payload: bucketKey
  };
};

export const addSelectedSegmentsToBucket = segmentsIds => {
  return {
    type: actions.ADD_SEGMENTS_IDS_TO_EXPRESSION_BUCKET,
    payload: segmentsIds
  };
};

export const addBucketToExpressionBucket = externalOperator => {
  return {
    type: actions.ADD_BUCKET_TO_EXPRESSION_BUCKET,
    payload: externalOperator
  };
};

export const removeSegmentsFromBucket = (segments, bucketKey) => {
  return {
    type: actions.REMOVE_SEGMENTS_FROM_EXPRESSION_BUCKET,
    payload: {
      segments,
      bucketKey
    }
  };
};

export const removeBucketToExpressionBucket = bucketKey => {
  return {
    type: actions.REMOVE_BUCKET_TO_EXPRESSION_BUCKET,
    payload: bucketKey
  };
};

export const setInternalOperatorToBucket = (bucketKey, operator) => {
  return {
    type: actions.SET_INTERNAL_OPERATOR_TO_EXPRESSION_BUCKET,
    payload: {
      bucketKey,
      operator
    }
  };
};

export const setExternalOperatorToBucket = (bucketKey, operator) => {
  return {
    type: actions.SET_EXTERNAL_OPERATOR_TO_EXPRESSION_BUCKET,
    payload: {
      bucketKey,
      operator
    }
  };
};

export const resetExpressionBucket = () => {
  return {
    type: actions.RESET_EXPRESSION_BUCKET
  };
};

export const searchSegments = (segmentsResultsData, query) => {
  // TODO: Remove mix panel track from action
  mixpanel.track('MADE_SEARCH', {
    searchTerm: query,
    keywords: query.split(' '),
    resultCount: segmentsResultsData.length,
    result1: segmentsResultsData[0]?.fullpath,
    result2: segmentsResultsData[1]?.fullpath,
    result3: segmentsResultsData[2]?.fullpath,
    result4: segmentsResultsData[3]?.fullpath,
    result5: segmentsResultsData[4]?.fullpath
  });
  return {
    type: actions.SEARCH_SEGMENTS,
    payload: { segmentsResultsData, query }
  };
};

export const setCountrySelected = country => {
  return {
    type: actions.SET_COUNTRY_SELECTED,
    payload: country
  };
};
