/* eslint-disable camelcase */
import { useQuery } from '@tanstack/react-query';
import { ServiceFactory } from 'services/FactoryService';
import { useState } from 'react';

export const GET_PRODUCT_VERSION = 'product_version';
const productService = ServiceFactory.get('products');

interface Response {
  response: Array<{
    product: string;
    version_number: string;
    updated: string;
  }>;
}

const useGetVersion = () => {
  const [isHidden, setIsHidden] = useState(true);
  const envVersion = process.env.REACT_APP_APPLICATION_VERSION || '';

  const handleClose = () => {
    setIsHidden(true);
    setTimeout(() => {
      setIsHidden(false);
    }, 600000);
  };

  const { data } = useQuery<Response>([GET_PRODUCT_VERSION], {
    queryFn: productService.getProductsVersions,
    refetchInterval: 30000,
    onSuccess: result => {
      const loop = result.response.find(product => product.product === 'LOOP');
      const isSameVersion = (loop?.version_number || '') <= envVersion;
      setIsHidden(isSameVersion);
      return loop?.version_number;
    }
  });

  return {
    isHidden,
    handleClose,
    setIsHidden,
    envVersion,
    serverVersion: data?.response.find(product => product.product === 'LOOP')?.version_number
  };
};

export default useGetVersion;
