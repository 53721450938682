/* eslint-disable no-nested-ternary */
/* eslint-disable camelcase */
import React, { FC } from 'react';
import { Text, Flex, Alert } from '@retargetly/ui-components';
import useKeywordEstimation from 'hooks/keywords/useKeywordsEstimation';
import useOrganization from 'hooks/useOrganization';
import { useFormikContext } from 'formik';
import { useFormatMessage } from 'react-intl-hooks';
import { Skeleton } from 'antd';
import { numberParser } from 'utils/numberParser';

type Props = {
  tags: string[];
  accentAlert: boolean;
};

const KeywordsEstimation: FC<Props> = ({ tags, accentAlert }) => {
  const t = useFormatMessage();
  const { currentOrganization } = useOrganization();
  const { values } = useFormikContext<any>();
  const { country, days } = values;
  const keywordEstimationPayload = {
    clientId: currentOrganization?.clientId,
    keywords: tags.map(tag => tag.toLowerCase()),
    country,
    value: days
  };

  const { data: volumeEstimation, isLoading, isError } = useKeywordEstimation(keywordEstimationPayload);

  const formatedTotalDevices = numberParser(volumeEstimation || 0);

  return (
    <Flex flexDirection="column" gap={4}>
      {accentAlert ? (
        <Alert
          title={`${t({
            id: `KEYWORDS.ALERT.ACCENT`
          })}`}
          status="warning"
        />
      ) : null}
      {isLoading && tags.length > 0 && country !== null && country !== '' && days !== null ? (
        <Flex alignItems="center" justifyContent="center" minHeight="30px" mb={2}>
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 1,
              width: 100
            }}
          />
        </Flex>
      ) : isError ? (
        <Alert
          title={`${t({
            id: `INSIGHTS.RIGHT.ERROR`
          })}`}
          status="error"
        />
      ) : (
        <Flex gap="2" mt={2} height="30px">
          <Text size="bodyBold">
            {t({
              id: `GENERIC.LABEL.SCOPE_ESTIMATED`
            })}
            :
          </Text>
          <Text size="bodyMedium">{formatedTotalDevices}</Text>
        </Flex>
      )}
    </Flex>
  );
};

export default KeywordsEstimation;
