import React, { useState } from 'react';
/* eslint-disable no-extra-boolean-cast */
import { faCopy, faEye, faFolder, faPencil, faShareNodes, faTrash } from '@fortawesome/pro-duotone-svg-icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Badge,
  Button,
  Flex,
  Icon,
  Span,
  Text,
  Tooltip
} from '@retargetly/ui-components';
import { togglePanelRight, toggleShareModal } from 'actions';
import { setCurrentPlanification } from 'actions/plannings';
import { usePlanificationContext } from 'components/SharedPlanification/context';
import { SHARE } from 'constants/flows';
import useBlueprints, { Blueprint } from 'hooks/planning/useBlueprints';
import useExpressionBucket from 'hooks/planning/useExpressionBucket';
import usePlanning, { Planning } from 'hooks/planning/usePlannings';
import useCountries from 'hooks/useCountriesFilter';
import useOrganization from 'hooks/useOrganization';
import { useDispatch } from 'react-redux';
import { dateParser } from 'utils/dateParser';
import { expressionBucketExpressionModelToExpressionBucket } from 'utils/expressionTransformTo';
import {
  Skeleton
  //  Tooltip
} from 'antd';
import EmptyPlanning from 'components/Search/EmptyPlanning';
import { useFormatMessage } from 'react-intl-hooks';
import { Loading } from '../components/Loading';
import { DeletePlanningModal } from '../components/DeletePlanningModal';
import { DetailPlanningModal } from '../components/DetailPlanningModal';

export const PlanningsPage = () => {
  const t = useFormatMessage();
  const { folders, isLoading, getBlueprinMutation } = useBlueprints();
  const { currentOrganization } = useOrganization();
  const { deleteCustomPlanning, setPlanification } = usePlanning();
  const { onSetCountryFilter } = useCountries();
  const { setExpressionBucket } = useExpressionBucket();
  const dispatch = useDispatch();
  const { changeStep } = usePlanificationContext();
  const [openModal, setOpenModal] = useState(false);
  const [openModalDetail, setOpenModalDetail] = useState(false);
  const [currentPlanning, setCurrentPlanning] = useState<Blueprint>({} as Blueprint);

  const getBlueprint = async (planning: Planning) => {
    const payload = { segmentId: planning?.segmentId, clientId: currentOrganization?.clientId };
    const response = await getBlueprinMutation.mutateAsync(payload);
    if (Boolean(response?.country)) {
      onSetCountryFilter(response.country);
    }
    return response;
  };
  const remove = (blueprint: Blueprint) => {
    const clientId = currentOrganization?.clientId;
    deleteCustomPlanning({ blueprint, clientId } as any);
    setOpenModal(false);
  };

  const show = async (planning: Blueprint) => {
    dispatch(togglePanelRight(true));
    const response = await getBlueprint(planning as Planning);
    setPlanification({ ...response });
    dispatch(setCurrentPlanification(response));
    setExpressionBucket(expressionBucketExpressionModelToExpressionBucket(response.expression));
  };

  const edit = async (planning: Blueprint) => {
    await show(planning);
    dispatch(toggleShareModal(true));
  };

  const clone = async (planning: Blueprint) => {
    dispatch(togglePanelRight(true));
    const response = await getBlueprint(planning as Planning);
    setPlanification({ ...response, id: undefined, segmentId: undefined });
    dispatch(setCurrentPlanification({ ...response, id: undefined, segmentId: undefined, isNew: true }));
    setExpressionBucket(expressionBucketExpressionModelToExpressionBucket(response.expression));
  };

  const share = async (planning: Blueprint) => {
    await show(planning);
    changeStep(SHARE);
    dispatch(toggleShareModal(true));
  };

  return (
    <Flex alignItems="center" justifyContent="center" flexDirection="column">
      <Flex mr="auto" mb={1} mt="5px">
        <Span fontSize="0.75rem" color="neutrals.400" textTransform="uppercase">
          {t({
            id: `PLANIFICATIONS.SAVED`
          })}
        </Span>
      </Flex>

      {getBlueprinMutation.isLoading ? <Loading /> : null}

      {isLoading ? <Skeleton active /> : null}

      {folders?.length === 0 && !isLoading ? (
        <Flex alignItems="center" justifyContent="center">
          <EmptyPlanning />
        </Flex>
      ) : null}

      <Accordion allowToggle>
        {folders?.map(item => (
          <AccordionItem key={item.name}>
            <AccordionButton>
              <Flex flexDirection="row" gap={4} alignItems="center" as="span" flex="1" textAlign="left">
                <Icon icon={faFolder} name="folder" color="#1861F5" />
                <Text size="bodyBold">{item?.name}</Text>
              </Flex>
            </AccordionButton>
            <AccordionPanel>
              {item?.blueprints?.map(blueprint => (
                <Flex
                  py={2}
                  key={blueprint?.segmentId}
                  flexDirection="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ borderBottom: '1px solid #E3E6EE' }}
                >
                  <Flex gap={1} flexDirection="column">
                    <Flex flexDirection="row" alignItems="center" gap={2}>
                      <Text size="bodyBold">{blueprint?.name}</Text>
                      {blueprint.isShared === true ? (
                        <Badge variant="informativeSolid">
                          <Text size="bodySmallBold">
                            {t({
                              id: `GENERIC.LABEL.SHARED`
                            })}
                          </Text>
                        </Badge>
                      ) : null}
                    </Flex>
                    <Text size="bodySmall">{dateParser(blueprint?.created)}</Text>
                  </Flex>
                  <Flex>
                    <Tooltip
                      label={t({
                        id: `PLANIFICATION.DETAILS`
                      })}
                      placement="auto"
                      shouldWrapChildren
                      hasArrow
                    >
                      <Flex alignItems="center" justifyContent="center" width={8} height={8}>
                        <Button
                          icon={<Icon icon={faEye} name="eye" color="#004CAD" size="sm" />}
                          variant="ghost"
                          size="sm"
                          onClick={() => {
                            setOpenModalDetail(true);
                            setCurrentPlanning(blueprint);
                          }}
                        />
                      </Flex>
                    </Tooltip>

                    {blueprint.isShared ? (
                      <Tooltip label={t({ id: `PLANIFICATION.SHARE` })} placement="auto" shouldWrapChildren hasArrow>
                        <Flex alignItems="center" justifyContent="center" width={8} height={8}>
                          <Button
                            icon={<Icon icon={faShareNodes} name="share-nodes" color="#004CAD" size="sm" />}
                            variant="ghost"
                            size="sm"
                            onClick={() => share(blueprint)}
                          />
                        </Flex>
                      </Tooltip>
                    ) : null}
                    {!blueprint.isShared ? (
                      <Tooltip label={t({ id: `GENERIC.LABEL.EDIT` })} placement="auto" shouldWrapChildren hasArrow>
                        <Flex alignItems="center" justifyContent="center" width={8} height={8}>
                          <Button
                            icon={<Icon icon={faPencil} name="pencil" color="#004CAD" size="sm" />}
                            variant="ghost"
                            size="sm"
                            onClick={() => edit(blueprint)}
                          />
                        </Flex>
                      </Tooltip>
                    ) : null}
                    <Tooltip label={t({ id: `PLANIFICATION.CLONE` })} placement="auto" shouldWrapChildren hasArrow>
                      <Flex alignItems="center" justifyContent="center" width={8} height={8}>
                        <Button
                          icon={<Icon icon={faCopy} name="copy" color="#004CAD" size="sm" />}
                          variant="ghost"
                          size="sm"
                          onClick={() => clone(blueprint)}
                        />
                      </Flex>
                    </Tooltip>
                    <Tooltip label={t({ id: `GENERIC.LABEL.DELETE` })} placement="auto" shouldWrapChildren hasArrow>
                      <Flex alignItems="center" justifyContent="center" width={8} height={8}>
                        <Button
                          icon={<Icon icon={faTrash} name="trash" color="#004CAD" size="sm" />}
                          variant="ghost"
                          size="sm"
                          onClick={() => {
                            setCurrentPlanning(blueprint);
                            setOpenModal(true);
                          }}
                        />
                      </Flex>
                    </Tooltip>
                  </Flex>
                </Flex>
              ))}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
      {openModal ? (
        <DeletePlanningModal
          isOpen={openModal}
          onClose={() => setOpenModal(false)}
          onDelete={remove}
          planning={currentPlanning}
        />
      ) : null}
      {openModalDetail ? (
        <DetailPlanningModal
          isOpen={openModalDetail}
          onClose={() => setOpenModalDetail(false)}
          planning={currentPlanning}
        />
      ) : null}
    </Flex>
  );
};
