import * as actions from '../../constants/actionTypes';

export const togglePanelRight = panel => {
  return {
    type: actions.TOGGLE_PANEL_RIGHT,
    payload: panel
  };
};

export const toggleModal = modal => {
  return {
    type: actions.TOGGLE_MODAL,
    payload: modal
  };
};

export const toggleGuidedModal = modal => {
  return {
    type: actions.TOGGLE_GUIDE_MODAL,
    payload: modal
  };
};

export const toggleShareModal = modal => {
  return {
    type: actions.TOGGLE_SHARE_MODAL,
    payload: modal
  };
};

export const togglePlanifModal = modal => {
  return {
    type: actions.TOGGLE_PLANIF_MODAL,
    payload: modal
  };
};

export const toggleQuestionModal = modal => {
  return {
    type: actions.TOGGLE_QUESTION_MODAL,
    payload: modal
  };
};

export const toggleWelcomeModal = modal => {
  return {
    type: actions.TOGGLE_WELCOME,
    payload: modal
  };
};

export const toggleTour = tour => {
  return {
    type: actions.TOGGLE_TOUR,
    payload: tour
  };
};

export const toggleLogOut = val => {
  return {
    type: actions.TOGGLE_LOGOUT,
    payload: val
  };
};

export const togglePasswordModal = val => {
  return {
    type: actions.TOGGLE_PASSWORD_MODAL,
    payload: val
  };
};

export const setGlobalError = error => {
  return {
    type: actions.SET_GENERAL_ERROR,
    payload: error
  };
};

export const refreshInsightData = payload => {
  return {
    type: actions.REFRESH_INSIGHT_DATA,
    payload
  };
};
