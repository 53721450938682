import React, { FC } from 'react';
import { Icon, Box } from '@retargetly/ui-components';
import { faDownload } from '@fortawesome/pro-duotone-svg-icons';
import { divToImg } from 'utils/divToImg';

interface Props {
  color?: string;
  svgRef: React.RefObject<HTMLDivElement>;
  name: string;
}

const DivToSvg: FC<Props> = ({ color = 'primary.500', svgRef, name }) => {
  return (
    <Box onClick={() => divToImg(svgRef, name)} cursor="pointer">
      <Icon icon={faDownload} name="download" size="sm" color={color} />
    </Box>
  );
};

export default DivToSvg;
