import React, { useEffect, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import * as Yup from 'yup';
import { Formik, Field, Form, FormikProps, ErrorMessage } from 'formik';
import { Box, Text, Flex, Alert, DropDown } from '@retargetly/ui-components';
import { Tag, Input } from 'antd';
import { useCreateKeywordsAudience } from 'hooks/keywords/useCreateKeywordsAudience';
import FormikInput from 'widgets/forms/input/FormikInput';
import useOrganization from 'hooks/useOrganization';
import useGetGeoCountries from 'hooks/geotool/useGetGeoCountries';
import useEventTracker from 'hooks/useEventTracker';
import KeywordsEstimation from '../KeywordsEstimation';

const CreateKeywordsForm = () => {
  const t = useFormatMessage();
  const { trackEvent } = useEventTracker();

  const audienceKeywordSchema = Yup.object().shape({
    country: Yup.string().required(
      t({
        id: `GENERIC.LABEL.REQUIRED`
      })
    ),
    days: Yup.number().required(
      t({
        id: `GENERIC.LABEL.REQUIRED`
      })
    ),
    name: Yup.string()
      .trim()
      .min(
        3,
        t({
          id: `KEYWORDS.VALIDATION.NAME.MIN`
        })
      )
      .max(
        150,
        t({
          id: `KEYWORDS.VALIDATION.NAME.MAX`
        })
      )
      .required(
        t({
          id: `GENERIC.LABEL.REQUIRED`
        })
      ),
    description: Yup.string().max(
      150,
      t({
        id: `KEYWORDS.VALIDATION.NAME.MAX`
      })
    ),
    keywords: Yup.array().of(
      Yup.string()
        .matches(/^[a-zA-Z0-9\s|]*$/, 'Las palabras clave solo pueden contener letras, números y el carácter "|"')
        .test('minLength', 'Cada palabra clave debe tener al menos 3 caracteres', (value: any) => value.length >= 3)
    )
  });

  const { data: countries } = useGetGeoCountries();
  const { currentOrganization } = useOrganization();
  const [creationState, setCreationState] = useState<undefined | 'success' | 'error'>(undefined);

  // TODO: inputValue must be part of a keywords input custom component, with an onChage event that triggers setFieldValue into formik
  const [inputValue, setInputValue] = useState('');
  // TODO: this state will not be needed if we create a keyword component with a field value that can be managed by yup rules
  const [keywordInputIsValid, setKeywordInputIsValid] = useState(true); // [true, false, true

  const [tags, setTags] = useState<string[]>([]);

  const goToDMP = (path: string) => {
    window.location.replace(`${process.env.REACT_APP_REDIRECT_DMP_APP}${path}?view_as=${currentOrganization.clientId}`);
  };

  const onErrorCreateSegment = () => {
    setCreationState('error');
  };

  const onSuccessCreateSegment = (segmentId: number) => {
    setCreationState('success');
    setTags([]);
    setInputValue('');
    trackEvent('SEGMENT_KEYWORDS_CREATED', {});

    setTimeout(() => {
      goToDMP(`segments/share/custom/${segmentId}`);
    }, 1000);
  };

  const { mutate: onCreateSegment } = useCreateKeywordsAudience(onSuccessCreateSegment, onErrorCreateSegment);

  const [accentAlert, setAccentAlert] = useState(false);

  useEffect(() => {
    if (accentAlert) {
      setTimeout(() => {
        setAccentAlert(false);
      }, 5000);
    }
  }, [accentAlert]);

  const handleInputConfirm = () => {
    if (inputValue) {
      const cleanedInput = inputValue.trim();
      const hasAccent = /[áéíóúÁÉÍÓÚ]/.test(cleanedInput);

      if (/^[a-zA-Z0-9\s|]*$/.test(cleanedInput) && cleanedInput.length >= 3 && !hasAccent) {
        setTags(prevTags => {
          if (!prevTags.includes(cleanedInput)) {
            setAccentAlert(false);
            setInputValue('');
            return [...prevTags, cleanedInput];
          }
          setAccentAlert(false);
          setInputValue('');
          return prevTags;
        });
      } else {
        setAccentAlert(true);
        setInputValue('');
      }
    }

    if (tags.length === 0) {
      setKeywordInputIsValid(false);
    } else {
      setKeywordInputIsValid(true);
    }
  };

  const handleInputPaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    // eslint-disable-next-line prefer-destructuring
    const clipboardData = e.clipboardData;
    const pastedText = clipboardData.getData('text');
    const pastedKeywords = pastedText.split(',');

    pastedKeywords.forEach(keyword => {
      const cleanedKeyword = keyword.trim();

      if (cleanedKeyword.length >= 3) {
        if (/[áéíóúÁÉÍÓÚ]/.test(cleanedKeyword)) {
          setAccentAlert(true);
        } else {
          setTags(prevTags => [...prevTags, cleanedKeyword]);
        }
      }
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      <Formik
        initialValues={{
          country: '',
          days: '',
          name: '',
          crossdevice: true
        }}
        validationSchema={audienceKeywordSchema}
        onSubmit={(values, formikBag) => {
          const reset = () => {
            formikBag.resetForm();
          };
          const clientId = currentOrganization?.clientId;

          if (tags.length === 0) {
            setKeywordInputIsValid(false);
            return;
          }

          const payload = {
            type: 'keyword',
            crossdevice: true,
            name: values.name,
            reset,
            clientId,
            rules: [
              {
                type: 'country',
                value: values.country
              },
              {
                type: 'keyword',
                value: tags.join(',')
              },
              {
                type: 'recency',
                value: values.days
              },
              {
                type: 'crossdevice',
                value: true
              },
              {
                type: 'monthly_activation',
                value: true
              }
            ]
          };
          onCreateSegment(payload);
        }}
      >
        {(props: FormikProps<any>) => {
          return (
            <Form id="keywords-form">
              <Flex flexDirection="column" my={2}>
                <div className="mb-2">
                  <Text size="bodyBold">
                    {t({
                      id: `GEOTOOL.PLACEHOLDER.COUNTRY`
                    })}
                  </Text>
                </div>

                <Text size="bodyMedium">
                  {t({
                    id: `KEYWORDS.COUNTRY.SUBTITLE`
                  })}
                </Text>
              </Flex>
              <Field name="country">
                {({ field, form, meta }: any) => {
                  return (
                    <div>
                      <DropDown
                        name="country"
                        label=""
                        options={
                          countries !== undefined && countries.length > 1
                            ? countries
                            : [{ key: '1', label: 'no options', value: 1 }]
                        }
                        onChange={e => {
                          props.setValues({ ...props.values, country: e });
                        }}
                        onBlur={() => props.setTouched({ ...props.touched, country: true })}
                        value={meta.value}
                        error={meta?.touched === true && meta?.error !== undefined}
                        placeholder={`${t({
                          id: 'GEOTOOL.PLACEHOLDER.COUNTRY'
                        })}`}
                      />

                      <Box mt={2}>
                        <Text size="bodySmall" align="left" color="red">
                          <ErrorMessage name="country" />
                        </Text>
                      </Box>
                    </div>
                  );
                }}
              </Field>

              <Flex my={2}>
                <Text size="bodyBold">
                  {t({
                    id: `KEYWORDS.TIME_RANGE.TITLE`
                  })}
                </Text>
              </Flex>

              <Field name="days">
                {({ field, form, meta }: any) => {
                  return (
                    <div>
                      <DropDown
                        name="days"
                        onChange={e => {
                          props.setValues({ ...props.values, days: e });
                        }}
                        onBlur={() => props.setTouched({ ...props.touched, days: true })}
                        value={meta.value}
                        label=""
                        options={[
                          {
                            label: `${t({
                              id: 'GENERIC.LABEL.LASTDAY'
                            })}`,
                            value: 24
                          },
                          {
                            label: `${t({
                              id: 'GENERIC.LABEL.LAST7DAYS'
                            })}`,
                            value: 168
                          },
                          {
                            label: `${t({
                              id: 'GENERIC.LABEL.LAST14DAYS'
                            })}`,
                            value: 336
                          },
                          {
                            label: `${t({
                              id: 'GENERIC.LABEL.LASTMONTH'
                            })}`,
                            value: 720
                          }
                        ]}
                        placeholder={`${t({
                          id: 'KEYWORDS.TIME_RANGE.PLACEHOLDER'
                        })}`}
                      />

                      <Box mt={2}>
                        <Text size="bodySmall" align="left" color="red">
                          <ErrorMessage name="days" />
                        </Text>
                      </Box>
                    </div>
                  );
                }}
              </Field>

              <Flex my={2} alignItems="center">
                <Text size="bodyBold">
                  {t({
                    id: 'KEYWORDS.INPUT.TITLE'
                  })}
                </Text>
              </Flex>

              <Box borderRadius="4px" border="1px solid #E3E6EE" background="#FFFFFF">
                <Box>
                  <Box py="8px" px="12px">
                    {tags.map((tag, index) => (
                      <Tag
                        key={tag}
                        closable
                        onClose={() => setTags(tags.filter((_, i) => i !== index))}
                        style={{ borderRadius: '8px' }}
                      >
                        {tag}
                      </Tag>
                    ))}
                  </Box>

                  <Input
                    name="keywords"
                    type="text"
                    value={inputValue}
                    onChange={handleInputChange}
                    onBlur={handleInputConfirm}
                    onPressEnter={handleInputConfirm}
                    onKeyPress={e => {
                      if ((e.key === 'Enter' || e.key === ' ') && inputValue.trim()) {
                        e.preventDefault();
                        handleInputConfirm();
                      }
                    }}
                    bordered={false}
                    onPaste={handleInputPaste}
                  />
                </Box>
              </Box>
              <Flex my={2}>
                <Text size="bodySmall" align="left">
                  {t({
                    id: `KEYWORDS.INPUT.HELPER`
                  })}
                  <Box mt={2}>
                    {/* TODO: keyword input should be a custom component that triggers setFieldValue into formik values */}
                    {!keywordInputIsValid && (
                      <Text size="bodySmall" align="left" color="red">
                        {t({
                          id: `KEYWORDS.VALIDATION.QUANTITY.MIN`
                        })}
                      </Text>
                    )}
                  </Box>
                </Text>
              </Flex>

              <Flex my={2}>
                <KeywordsEstimation tags={tags} accentAlert={accentAlert} />
              </Flex>

              <Flex my={2}>
                <Text size="bodyBold">
                  {t({
                    id: `KEYWORDS.NAME.TITLE`
                  })}
                </Text>
              </Flex>
              <FormikInput
                name="name"
                placeholder={`${t({
                  id: 'KEYWORDS.NAME.PLACEHOLDER'
                })}`}
              />

              <Box mt={4}>
                {creationState === 'success' && (
                  <Alert
                    title={`${t({
                      id: 'GEOTOOL.CREATIONPANEL.SUCCESS.TITLE'
                    })}`}
                    status="success"
                    state={creationState === 'success' ? 'show' : 'hide'}
                    onClose={() => setCreationState(undefined)}
                  >
                    {t({
                      id: `GEOTOOL.CREATIONPANEL.SUCCESS.INFO`
                    })}
                  </Alert>
                )}
                {creationState === 'error' && (
                  <Alert
                    title={`${t({
                      id: 'GEOTOOL.CREATIONPANEL.ERROR.TITLE'
                    })}`}
                    status="warning"
                    state={creationState === 'error' ? 'show' : 'hide'}
                    onClose={() => setCreationState(undefined)}
                  >
                    {t({
                      id: `GEOTOOL.CREATIONPANEL.ERROR.INFO`
                    })}
                  </Alert>
                )}
              </Box>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default CreateKeywordsForm;
