import getDmpUrl from 'utils/get-dmp-url';
import { appLoopIcon, appDmpIcon } from './icons';

export const tresholdDevicesValueToShowChart = 500;

export const idLoopAccount = 1828;
export const idRelyAccount = 119;

export const starterPlanFeatures = [];
export const growthPlanFeatures = [
  'Explorar audiencias',
  'Combinar audiencias',
  "AI Insight's BOT",
  'Insights demográficos',
  'Insights actitudinales',
  'Insights de activación',
  'Encuestas ilimitadas'
];

export const apps = [
  { id: 1, name: 'Loop', icon: appLoopIcon, url: '/', bgColor: '#6471fa', description: 'APPSELECTOR.DESC.LOOP' },
  { id: 2, name: 'DMP', icon: appDmpIcon, url: getDmpUrl(), bgColor: '#730f4b', description: 'APPSELECTOR.DESC.DMP' }
];

export const CDN_URL = process.env.REACT_APP_CDN_URL;
