import React, { FC } from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Heading,
  Text
} from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';

type Props = {
  showModal: boolean;
  handleClose: () => void;
  handleConfirm: () => void;
  isLoading: boolean;
};

const LogOutModal: FC<Props> = ({ showModal, handleClose, handleConfirm, isLoading }) => {
  const t = useFormatMessage();

  return (
    <div>
      <Modal isOpen={showModal} onClose={handleClose} isCentered size="xl" autoFocus={false}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            <Heading size="100" color="text.500">
              {t({
                id: 'LOGOUT.CONFIRMATION.TITLE'
              })}
            </Heading>
            <ModalCloseButton size="md" />
          </ModalHeader>
          <ModalBody>
            <Text size="bodyMedium" color="black">
              {t({
                id: 'LOGOUT.CONFIRMATION.TEXT'
              })}
            </Text>
          </ModalBody>
          <ModalFooter justifyContent="flex-end" gap={2}>
            <Button
              variant="secondary"
              type="button"
              onClick={handleClose}
              label={`${t({
                id: 'LOGOUT.CONFIRMATION.CANCEL'
              })}`}
            />
            <Button
              variant="primary"
              type="button"
              onClick={handleConfirm}
              isLoading={isLoading}
              label={`${t({
                id: 'LOGOUT.CONFIRMATION.CONFIRM'
              })}`}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default LogOutModal;
