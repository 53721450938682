import { datadogRum } from '@datadog/browser-rum';
import { UserProfile } from 'utils/interfaces/index';

const useUserMonitoringLoader = () => {
  const setUserForMonitoringContext = (user: UserProfile) => {
    if (user) {
      // Add as many user attributes as you want
      datadogRum.setUser({
        id: user.internal_user_id.toString(),
        name: user.name.toString(),
        email: user.email,
        organizationName: user?.organization?.name,
        organizationId: user.main_org
      });
    }
  };

  return {
    setUserForMonitoringContext
  };
};

export default useUserMonitoringLoader;
