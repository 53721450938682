import useExpressionBucket from 'hooks/planning/useExpressionBucket';
import { Flex, Box, Text } from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';
import React from 'react';
import ExpressionBucketButton from './ExpressionBucketButton';
import ExpressionComponent from './ExpressionComponent';

export function ExpressionLayoutComponent() {
  const t = useFormatMessage();
  const { addBucketToExpression } = useExpressionBucket();

  return (
    <>
      <Flex flexDirection="column" align="center" flexGrow={1} overflowY="auto">
        <Box mt={2} mb={4} width="90%">
          <Text variant="bodySmall" color="neutrals.400">
            {t({
              id: `PLANIFICATION.INFO.DESCRIBE`
            })}
            :
          </Text>
        </Box>
        <Box mt={2} mb={4} width="90%">
          <ExpressionComponent />
        </Box>
        <Flex justify="left" py={4} pl={6} w="100%">
          <ExpressionBucketButton addBucket={addBucketToExpression} />
        </Flex>
      </Flex>
    </>
  );
}
