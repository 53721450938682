import React, { useState, useEffect } from 'react';
import { Modal } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import useEventTracker from 'hooks/useEventTracker';
import useCountries from 'hooks/useCountriesFilter';
import useExpressionBucket from 'hooks/planning/useExpressionBucket';
import { Box, DropDown, Flex, Text, Tooltip } from '@retargetly/ui-components';

type Props = {
  onCountrySelect: () => void;
};

const CountrySelector: React.FC<Props> = ({ onCountrySelect }) => {
  const t = useFormatMessage();
  const [showTooltip, setShowTooltip] = useState(false);
  const { trackEvent } = useEventTracker();
  const { countrySelected, isLoading, data: countries, onSetCountryFilter } = useCountries();
  const { hasValidExpression } = useExpressionBucket();

  useEffect(() => {
    if (!countrySelected) {
      setShowTooltip(true);
    } else {
      setShowTooltip(false);
    }
  }, [countrySelected]);

  const handleChangeCountry = (e: any) => {
    const handleCancel = (event: any) => {
      event();
    };
    const confirmFilterCountry = () => {
      const country = e;
      trackEvent('CHANGED_FILTER', {
        changed: country,
        countInFilter: e.length,
        filterApplied: e
      });
      onSetCountryFilter(country);
    };
    if (hasValidExpression && e.length > 0) {
      Modal.confirm({
        title: `${t({
          id: `GENERIC.LABEL.ATTENTION`
        })}!`,
        content: (
          <div>
            <p>
              {`${t({
                id: `COUNTRY.WARNING.FILTER`
              })}`}
            </p>
          </div>
        ),
        okText: `${t({
          id: `GENERIC.LABEL.UNDERSTOOD`
        })}`,
        cancelText: `${t({
          id: `GENERIC.LABEL.CANCEL`
        })}`,
        onCancel: handleCancel,
        onOk: () => {
          confirmFilterCountry();
          onCountrySelect();
        }
      });
    } else {
      confirmFilterCountry();
      onCountrySelect();
    }
  };

  return (
    <Flex flexDirection="column" width="100%">
      <Box mb={2}>
        <Text size="bodyMedium">
          {t({
            id: 'GENERIC.LABEL.COUNTRY'
          })}
        </Text>
      </Box>

      <Tooltip
        label={t({
          id: 'INFO.MANDATORY_COUNTRY'
        })}
        defaultIsOpen={showTooltip}
        isOpen={showTooltip}
        placement="right"
        hasArrow
        shouldWrapChildren
      >
        <DropDown
          isLoading={isLoading}
          options={countries ? countries.map((country: any) => ({ value: country.code, label: country.country })) : []}
          onChange={e => handleChangeCountry(e)}
          value={countrySelected}
          placeholder={`${t({
            id: 'GEOTOOL.PLACEHOLDER.COUNTRY'
          })}`}
        />
      </Tooltip>
    </Flex>
  );
};

export default CountrySelector;
