import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { ListItemDropdown, IconChip, Icon } from '@retargetly/ui-components';
import { faSquareUser } from '@fortawesome/pro-solid-svg-icons';
import { UserSettingsTypes } from '../UserSettings.types';

export const UserMenu: FC<UserSettingsTypes> = ({ setOpen }) => {
  const t = useFormatMessage();

  return (
    <div className="d-flex flex-column" onClick={() => setOpen(false)}>
      <Link to="/config">
        <ListItemDropdown
          chip={
            <IconChip
              ico={<Icon icon={faSquareUser} name="user-graduate" size="sm" color="primary.500" />}
              ariaLabel="icon-button"
            />
          }
          title={`${t({
            id: `GENERIC.LABEL.MY_ACCOUNT`
          })}`}
          subtitle={`${t({
            id: `USER.CARD.MYACCOUNT.SUBTITLE`
          })}`}
        />
      </Link>
    </div>
  );
};
