import React from 'react';
import { Flex, Heading, Text } from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';
import emptyBrief from './EmptyStateBrief.svg';

export const EmptyStateBrief = () => {
  const t = useFormatMessage();
  return (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
      <Flex w="calc(100% - 490px)" flexDirection="column" alignItems="center" justifyContent="center">
        <svg width="227px" height="227px">
          <image href={emptyBrief} />
        </svg>
      </Flex>
      <Flex flexDirection="column" textAlign="center" width="410px" gap={2}>
        <Heading size="200" color="neutrals.500">
          {t({
            id: `SEARCH.EMPTY.STATE.BRIEF.TITLE`
          })}
        </Heading>

        <Text size="bodyDefault">
          {t({
            id: `SEARCH.EMPTY.STATE.BRIEF.SUBTITLE`
          })}
        </Text>
      </Flex>
    </Flex>
  );
};
