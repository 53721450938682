/* eslint-disable camelcase */
import Repository from '../repository';

export const THEME_PATH = '/theme/';

export default {
  async getDomainTheme(domain: string) {
    const {
      data: { response }
    } = await Repository.get(`${THEME_PATH}`, {
      params: {
        domain,
        product: 'loop'
      }
    });
    return response;
  },

  async getClientIdTheme(id_client: number) {
    const {
      data: { response }
    } = await Repository.get(`${THEME_PATH}`, {
      params: {
        id_client,
        product: 'loop'
      }
    });
    return response;
  },

  async getAllThemes() {
    const {
      data: { response }
    } = await Repository.get(`${THEME_PATH}`, {
      params: {
        product: 'loop'
      }
    });
    return response;
  }
};
