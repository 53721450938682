import { useDispatch, useSelector } from 'react-redux';

import * as actions from '../../actions';
import * as selectors from '../../selectors';

const useExpressionBucket = () => {
  const dispatch = useDispatch();

  const bucketSelected = useSelector(selectors.getSelectedBucket);
  const expressionBucket = useSelector(selectors.getExpressionBucket);

  const selectBucket = bucketKey => dispatch(actions.setSelectedExpressionBucket(bucketKey));

  const addSegmentsToExpression = segments => dispatch(actions.addSelectedSegmentsToBucket(segments));
  const removeSegmentsToExpression = (segments, bucketKey) =>
    dispatch(actions.removeSegmentsFromBucket(segments, bucketKey));
  const addBucketToExpression = externalOperator => dispatch(actions.addBucketToExpressionBucket(externalOperator));
  const removeBucketToExpression = bucketKey => dispatch(actions.removeBucketToExpressionBucket(bucketKey));

  const setInternalOperatorToBucket = (bucketKey, operator) =>
    dispatch(actions.setInternalOperatorToBucket(bucketKey, operator));
  const setExternalOperatorToBucket = (operator, bucketKey) =>
    dispatch(actions.setExternalOperatorToBucket(operator, bucketKey));

  const hasValidExpression = !!expressionBucket.filter(bucket => bucket.segments.length > 0).length;

  const resetExpressionBucket = () => dispatch(actions.resetExpressionBucket());
  const setExpressionBucket = newExpressionBucket => {
    resetExpressionBucket();
    newExpressionBucket.forEach((bucket, index) => {
      addSegmentsToExpression(bucket.segments);
      if (bucket.internalOperator) setInternalOperatorToBucket(index, bucket.internalOperator);
      if (bucket.operator) addBucketToExpression(bucket.operator);
    });
  };

  return {
    bucketSelected,
    expressionBucket,
    selectBucket,
    addSegmentsToExpression,
    removeSegmentsToExpression,
    addBucketToExpression,
    removeBucketToExpression,
    setInternalOperatorToBucket,
    setExternalOperatorToBucket,
    hasValidExpression,
    setExpressionBucket,
    resetExpressionBucket
  };
};

export default useExpressionBucket;
