/* eslint-disable object-shorthand */
/* eslint-disable dot-notation */
import { useFormatMessage } from 'react-intl-hooks';
import { PathInfo, PathKey } from './pathInfo';
import overview from './assets/overview.svg';
import sources from './assets/sources.svg';
import segments from './assets/segments.svg';
import campaigns from './assets/campaigns.svg';
import analytics from './assets/analytics.svg';
import seats from './assets/seats.svg';

const getSvgForPath = (path: PathKey): string => {
  const svgMap: { [key: string]: string } = {
    overview: overview,
    sources: sources,
    segments: segments,
    'insights/demographics': analytics,
    campaigns: campaigns,
    default: seats
  };
  return svgMap[path] || svgMap['default'];
};

export const useModalInfo = (path: PathKey): PathInfo => {
  const t = useFormatMessage();

  const modalInfo: {
    overview: PathInfo;
    sources: PathInfo;
    segments: PathInfo;
    'insights/demographics': PathInfo;
    campaigns: PathInfo;
  } = {
    overview: {
      title: 'Overview',
      text1: `${t({ id: 'MODAL_NODMP_TEXT_OVERVIEW' })}`,
      text2: `${t({ id: 'MODAL_NODMP_TEXT_2' })}`,
      image: getSvgForPath('overview')
    },
    sources: {
      title: 'Sources',
      text1: `${t({ id: 'MODAL_NODMP_TEXT_SOURCES' })}`,
      text2: `${t({ id: 'MODAL_NODMP_TEXT_2' })}`,
      image: getSvgForPath('sources')
    },
    segments: {
      title: 'Segments',
      text1: `${t({ id: 'MODAL_NODMP_TEXT_SEGMENTS' })}`,
      text2: `${t({ id: 'MODAL_NODMP_TEXT_2' })}`,
      image: getSvgForPath('segments')
    },
    'insights/demographics': {
      title: 'Analytics',
      text1: `${t({ id: 'MODAL_NODMP_TEXT_ANALYTICS' })}`,
      text2: `${t({ id: 'MODAL_NODMP_TEXT_2' })}`,
      image: getSvgForPath('insights/demographics')
    },
    campaigns: {
      title: 'Campaigns',
      text1: `${t({ id: 'MODAL_NODMP_TEXT_CAMPAIGNS' })}`,
      text2: `${t({ id: 'MODAL_NODMP_TEXT_2' })}`,
      image: getSvgForPath('campaigns')
    }
  };
  return modalInfo[path];
};
