import React from 'react';
import { Text, Icon, Box, Flex } from '@retargetly/ui-components';
import { faCircleInfo } from '@fortawesome/pro-duotone-svg-icons';
import { useFormatMessage } from 'react-intl-hooks';

const Empty = () => {
  const t = useFormatMessage();

  return (
    <Flex flexDirection="column" minHeight="270px" alignItems="center" justifyContent="center" w="100%">
      <Box textAlign="center" pb="12px">
        <Icon icon={faCircleInfo} name="chart-mixed" size="md" />
      </Box>
      <Box textAlign="center" maxWidth="160px">
        <Text size="bodyDefault">{`${t({ id: 'GENERIC.LABEL.NODATA' })}`}</Text>
      </Box>
    </Flex>
  );
};

export default Empty;
