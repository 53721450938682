import { useQuery } from '@tanstack/react-query';
import useCountries from 'hooks/useCountriesFilter';
import { useSelector } from 'react-redux';
import { getSelectedIdSegments } from 'selectors';
import { ServiceFactory } from 'services/FactoryService';
import { treeToMap } from 'utils/common';

const services = ServiceFactory.get('segments');

export const GET_TAXONOMY_QUERY_KEY = 'GET_TAXONOMY';

export const useTaxonomy = () => {
  const { countrySelected } = useCountries();
  const idsSelectedSegments = useSelector(getSelectedIdSegments);
  const { isLoading, data, error, isFetching } = useQuery(
    [GET_TAXONOMY_QUERY_KEY, countrySelected],
    () => services.getSegmentsList(countrySelected),
    {
      enabled: !!countrySelected,
      staleTime: Infinity
    }
  );
  const [taxonomyRetargetly, taxonomyMarketplace] = data || [];
  let allSegments = new Map();

  if (data) {
    const [allSegmentsRaw] = treeToMap(data, false);
    allSegments = new Map(allSegmentsRaw.map((s: any) => [s.id, s]));
  }

  return {
    allSegments,
    isLoading,
    taxonomyRetargetly,
    taxonomyMarketplace,
    error,
    idsSelectedSegments,
    isFetching
  };
};

export default useTaxonomy;
