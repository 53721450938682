import React from 'react';
import { Breadcrumb } from 'antd';
import { useTaxonomyContext } from '../context/hooks';
import './TaxonomyList.scss';

export const TaxonomyBreadcrumb = () => {
  const { historyBreadcrumb, jumpToBreadcrumb } = useTaxonomyContext();

  return (
    <Breadcrumb className="pointer">
      {historyBreadcrumb.map(segment => (
        <Breadcrumb.Item key={segment?.id} onClick={() => jumpToBreadcrumb(segment)}>
          {segment?.name}
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};
