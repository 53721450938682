import React from 'react';
import { Result, Button } from 'antd';
import { Link } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import * as Routes from '../../constants/routes';
import useAuth from '../../hooks/useAuth.tsx';

const Error404 = () => {
  const t = useFormatMessage();
  const { isLoggedIn, redirectToLogin } = useAuth();
  if (!isLoggedIn) {
    redirectToLogin();
  }
  return (
    <Result
      status="404"
      title="404"
      subTitle={`${t({ id: 'GENERIC.ERROR.404' })}`}
      extra={
        <Link to={Routes.HOME}>
          <Button type="primary"> {`${t({ id: 'GENERIC.TEXT.BACKTO' })}`} Loop</Button>
        </Link>
      }
    />
  );
};

export default Error404;
