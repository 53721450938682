import React, { useEffect } from 'react';
import { Spin } from 'antd';
import { Flex } from '@retargetly/ui-components';
import { Feature } from 'geojson';
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import {
  CircleMode,
  DragCircleMode,
  DirectMode,
  SimpleSelectMode
  // @ts-ignore
} from 'mapbox-gl-draw-circle';
// @ts-ignore
import StaticMode from '@mapbox/mapbox-gl-draw-static-mode';
import GeoSearchBar from './SearchBar/GeoSearchBar';
import ToolBox from './ToolBox/ToolBox';
import ActionPanel from './ActionPanel/ActionPanel';
import { useMapContext } from '../context/Map/MapProvider';
import { useLocationContext } from '../context/Locations/LocationProvider';

export const MapView = () => {
  const { isLoading, userLocation, addPlaces, radius, locations } = useLocationContext();
  const { toolSelected, setMap, setDrawInstance, map, drawInstance } = useMapContext();
  let Draw: MapboxDraw | undefined;

  const addPlace = (e: { features: Feature; type: string }) => {
    addPlaces(e.features);
  };

  /**
   * initialization of mapbox map instance
   *  */
  useEffect(() => {
    if (!isLoading) {
      const mapboxMap = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v12',
        center: userLocation,
        zoom: 12
      });

      Draw = new MapboxDraw({
        displayControlsDefault: false,
        userProperties: true,
        defaultMode: 'draw_circle',
        clickBuffer: 10,
        touchBuffer: 10,

        modes: {
          ...MapboxDraw.modes,
          draw_circle: CircleMode,
          direct_select: DirectMode,
          simple_select: SimpleSelectMode,
          drag_circle: DragCircleMode,
          static: StaticMode
        },
        controls: {
          point: false,
          line_string: false,
          polygon: false,
          trash: false,
          combine_features: false,
          uncombine_features: false
        }
      });
      // this is needed to add the draw control to the map although it is not used
      mapboxMap.addControl(Draw, 'top-right');
      // add a function that is called when a layer is created
      mapboxMap.on('draw.create', addPlace);
      Draw.changeMode('static');

      // add the draw control and the map to the context map
      setDrawInstance(Draw);
      setMap(mapboxMap);
    }
  }, [isLoading]);

  /**
   * this effect is used to change the mode of the draw instance
   */
  useEffect(() => {
    if (map !== undefined && toolSelected === 'pin') {
      drawInstance.changeMode('static');
      drawInstance.changeMode('draw_circle', { initialRadiusInKm: radius / 1000 });
    }
  }, [locations, radius, toolSelected]);

  if (isLoading) {
    return (
      <Flex height="100%" width="100%" alignItems="center" justifyContent="center">
        <Spin size="large" />
      </Flex>
    );
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <GeoSearchBar userLocation={userLocation} />
      <ToolBox />
      {toolSelected && toolSelected !== 'hand' && <ActionPanel />}
      <div
        id="map"
        style={{
          height: '100%',
          width: '100%',
          zIndex: 1
        }}
      />
    </div>
  );
};
