import React, { useEffect, useState } from 'react';
import { faChevronRight } from '@fortawesome/pro-duotone-svg-icons';
import { Badge, Box, Flex, Icon, Stack, Text, Checkbox } from '@retargetly/ui-components';
import { numberParser } from 'utils/numberParser';
import { useFormatMessage } from 'react-intl-hooks';
import { SearchAffinity } from 'components/Search/SearchAffinity';
import useTaxonomyItem from '../hooks/useTaxonomyItem';
import { useTaxonomyContext } from '../context/hooks';

export interface Segment {
  segmentId: number;
  groupId: string;
  devices: number;
  name: string;
  fullName: string;
  clientId: number;
  parentId: string;
  id: string;
  rootParentId: string;
  rootParentName: string;
  childrens?: Array<Segment>;
  affinity?: number;
  parentCategoryName: string;
  segments?: Array<Segment>;
}

export const TaxonomyItem = ({ segment }: { segment: Segment }) => {
  const t = useFormatMessage();
  const { onCheck, idsSelectedSegments } = useTaxonomyItem(segment);
  const { handleSetSelectedListItem } = useTaxonomyContext();
  const isUsed = idsSelectedSegments && Array.isArray(idsSelectedSegments) && idsSelectedSegments.includes(segment?.id);

  const childrenInUsed =
    idsSelectedSegments &&
    Array.isArray(idsSelectedSegments) &&
    segment?.childrens?.some(child => idsSelectedSegments.includes(child.id)) &&
    !segment?.childrens?.every(child => idsSelectedSegments.includes(child.id));

  const hasChildren = !!segment?.childrens;

  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
    onCheck();
  };

  useEffect(() => {
    const selected =
      isUsed ||
      (idsSelectedSegments &&
        Array.isArray(idsSelectedSegments) &&
        !!segment?.childrens?.every(child => idsSelectedSegments.includes(child.id)));
    setIsChecked(selected);
  }, [idsSelectedSegments, isUsed]);

  return (
    <Stack
      sx={{
        borderRadius: '4px',
        border: '1px solid #E3E6EE',
        p: '8px 12px',
        transition: 'background 200ms',
        mb: 2,
        ':hover': {
          backgroundColor: '#F3F6F9'
        }
      }}
    >
      <Stack flexDirection="row" alignItems="center" gap={2}>
        <Checkbox onChange={handleCheckboxChange} isChecked={isChecked} isIndeterminate={childrenInUsed}>
          {' '}
        </Checkbox>
        <Flex
          flexDirection="row"
          alignItems="center"
          gap={2}
          width="100%"
          sx={{
            marginTop: '0px !important',
            cursor: `${hasChildren ? 'pointer' : 'default'}`
          }}
          onClick={hasChildren ? () => handleSetSelectedListItem(segment) : null}
        >
          <Box
            sx={{
              marginTop: '0px !important'
            }}
            width="100%"
          >
            <Flex flexDirection="row" gap={2} alignItems="center">
              <Text size="bodyBold" color="neutrals.500">
                {segment?.name}
              </Text>
              <Text size="bodyDefault">{segment?.segmentId ? `- ID ${segment?.segmentId}` : null}</Text>
              {segment?.devices > -1 ? (
                <Badge variant="informativeSolid">
                  <Text size="bodyDefault">
                    {numberParser(segment?.devices)}{' '}
                    {t({
                      id: `GENERIC.LABEL.DEVICES`
                    })}
                  </Text>
                </Badge>
              ) : null}
            </Flex>
            <Text size="bodySmall">{segment?.fullName ?? segment?.parentCategoryName}</Text>
          </Box>
          {segment?.affinity ? <SearchAffinity affinity={segment?.affinity} /> : null}
          {hasChildren ? (
            <Box sx={{ marginTop: '0px !important' }}>
              <Icon icon={faChevronRight} name="chevron-rigth" size="sm" color="neutrals.400" />
            </Box>
          ) : null}
        </Flex>
      </Stack>
    </Stack>
  );
};
