export const HOME = '/';
export const PLANNER_SUBSECTION = '/planner/:section';
export const INSIGHTS = '/planner/insights';
export const SEARCH = '/planner/search';
export const SEARCH_PLANNING = '/planner/plannings';
export const MARKETPLACE = '/marketplace';
export const CONFIG_USER = '/config';
export const ERROR404 = '/404';
export const REGISTER = '/register';
export const RESET = '/reset';
export const INVITE = '/invite';
export const LOADING = '/loading';
export const GEOTOOL = '/geotool';
export const KEYWORDS = '/keywords';
export const OVERVIEW = '/overview';
export const SOURCES = '/sources';
export const SEGMENTS = '/segments';
export const CAMPAIGNS = '/campaigns';
export const CALLBACK_AUTH = '/auth/callback/retargetly';
export const SINGLE_SIGN_ON_AUTH = '/auth/single-sign-on';
export const CALLBACK_LOGOUT = '/auth/callback/logout';
export const AUTH = '/auth';
export const WELCOME = '/welcome';
