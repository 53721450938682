import React from 'react';
import logo from 'images/searchGeo.svg';
import { Flex, Box, Heading, Text } from '@retargetly/ui-components';
import { Progress } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';

export const PlanningLoader = () => {
  const t = useFormatMessage();

  return (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
      <Flex
        w="calc(100% - 490px)"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxW="200px"
        maxH="300px"
        minW="80px"
        minH="80px"
      >
        <img src={logo} alt="search logo" width="100%" height="100%" />
      </Flex>
      <Box>
        <Box my={2} width="100%">
          <Progress type="line" percent={100} status="active" strokeColor="#004CAD" showInfo={false} />
        </Box>
        <Box my={2} textAlign="center">
          <Heading size="200" color="black">
            {t({
              id: `SEARCH.GPT.LOADER.TITLE`
            })}
          </Heading>
        </Box>
        <Text size="bodyDefault" color="black">
          {t({
            id: `SEARCH.GPT.LOADER.SUBTITLE`
          })}
        </Text>
      </Box>
    </Flex>
  );
};
