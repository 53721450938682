import React from 'react';
import { Flex, Box, Heading, Text, Divider, Button, Icon, Tooltip } from '@retargetly/ui-components';
import Breadcrumbs from 'widgets/Breadcrumbs/Breadcrumbs';
import CreateKeywordsForm from 'components/Keywords/CreationForm/CreateKeywordsForm';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { SEARCH } from 'constants/routes';
import { Layout } from 'antd';

const Keywords = () => {
  const t = useFormatMessage();
  const history = useHistory();

  const handleBack = () => {
    history.push(SEARCH);
  };

  return (
    <Layout.Content className="content" style={{ borderTop: '1px solid rgba(0, 0, 0, .05)' }}>
      <Flex flexDirection="column" height="100%">
        <Flex flexDirection="column" align="left" my={4} ml={8} mr={4}>
          <Breadcrumbs />
          <Flex mt={1} alignItems="center" justifyContent="space-between">
            <Box>
              <Heading color="neutrals.500" size="200">
                {t({
                  id: `KEYWORDS.CREATE.TITLE`
                })}
              </Heading>

              <Text size="bodySmall" color="neutrals.400">
                {t({
                  id: `KEYWORDS.CREATE.SUBTITLE`
                })}
              </Text>
            </Box>
            <Flex ml="auto">
              <Button
                type="button"
                onClick={handleBack}
                label={`${t({
                  id: 'GENERIC.LABEL.CANCEL'
                })}`}
                variant="secondary"
              />
              <Box ml={2} mr={4}>
                <Button
                  type="submit"
                  onClick={() => null}
                  label={`${t({
                    id: 'GENERIC.LABEL.CREATE'
                  })}`}
                  form="keywords-form"
                />
              </Box>
            </Flex>
          </Flex>
        </Flex>

        <Divider orientation="horizontal" variant="solid" />
        <Box mx={8} my={4}>
          <CreateKeywordsForm />
        </Box>
      </Flex>
    </Layout.Content>
  );
};

export default Keywords;
