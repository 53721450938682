import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import sessionStorage from 'redux-persist/lib/storage/session';
import localStorage from 'redux-persist/lib/storage';
import segmentsReducers from './segmentsReducer';
import planningsReducer from './planningsReducer';
import authReducer from './authReducer';
import insightsReducer from './insightsReducer';
import uiReducer from './uiReducer';
import searchReducers from './searchReducers';
import organizationReducer from './organizationReducer';

const authPersistConfig = {
  key: 'auth',
  storage: localStorage,
  blacklist: ['showNewUIModal']
};

const segmentsPersistConfig = {
  key: 'segments',
  storage: sessionStorage,
  blacklist: []
};

const customPlanificationsPersistConfig = {
  key: 'customPlanifications',
  storage: sessionStorage,
  blacklist: ['currentPlanification']
};

const orgPersistConfig = {
  key: 'organizations',
  storage: localStorage,
  blacklist: []
};

export default combineReducers({
  auth: persistReducer(authPersistConfig, authReducer),
  segments: persistReducer(segmentsPersistConfig, segmentsReducers),
  searchSegments: searchReducers,
  customPlanifications: persistReducer(customPlanificationsPersistConfig, planningsReducer),
  insights: insightsReducer,
  ui: uiReducer,
  organizations: persistReducer(orgPersistConfig, organizationReducer)
});
