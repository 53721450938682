import { CALLBACK_AUTH, CALLBACK_LOGOUT } from 'constants/routes';

import Repository from '../repository';

const resourceLogin = 'jwt/login/';
const resourceLogout = 'logout';

const getOptions = () => {
  const token = localStorage.getItem('token');
  const refreshtoken = localStorage.getItem('rtToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt: refreshtoken
    }
  };
};

export default {
  loginWithEmailPassword(payload) {
    return Repository.post(`${resourceLogin}`, payload);
  },
  logout() {
    return Repository.post(`${resourceLogout}`, {}, getOptions());
  },
  register(payload, type) {
    if (type === 'invite') {
      return Repository.post('/user/invitesignup', payload);
    }
    return Repository.post('signup', payload);
  },
  checkIfUserExist(payload) {
    return Repository.get(`user/checkemail?email=${payload}`);
  },
  confirmUserAccount(payload) {
    return Repository.post(`user/invite/${payload}`);
  },
  changePassword(payload) {
    return Repository.post('user/setpassword', payload, getOptions());
  },
  forgetPassword(payload) {
    // to generate email with change link
    return Repository.post('user/reset_password', payload);
  },
  forgetChangePassword(payload) {
    // to set the new password
    return Repository.post(`user/invite/${payload.hash}`, { password: payload.password });
  },
  checkConfirmationToken(payload) {
    return Repository.post(`user/login/confirmation/`, { token: payload.hash });
  },
  getUserPermissions(idUser) {
    return Repository.get(`user/permission/${idUser}`, getOptions());
  },
  getPlanData({ idUser = '' }) {
    return Repository.get(`user/getplandata/${idUser}`, getOptions());
  },
  updateUserPlan({ idUser = '', plan }) {
    return Repository.put(`user/plandata/${idUser}`, { plan }, getOptions());
  },
  checkToken(token, userId) {
    return Repository.get(`user/${userId}`, getOptions());
  },
  authWithSingleSignOn() {
    return Repository.get(`api/auth/login`, {
      params: {
        callback_url: `${window.location.origin}${CALLBACK_AUTH}`
      }
    });
  },
  // eslint-disable-next-line camelcase
  authWithSingleSignOnCallback({ code, org_name }) {
    return Repository.get(`api/auth/login/code`, {
      params: {
        callback_url: `${window.location.origin}${CALLBACK_AUTH}`,
        code,
        org_name
      }
    });
  },
  logoutService() {
    return Repository.get('api/auth/logout', {
      params: {
        callback_url: `${window.location.origin}${CALLBACK_LOGOUT}`
      },
      headers: getOptions().headers
    });
  }
};
