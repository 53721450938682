export const numberParser = number => {
  const numberToEvaluate = number !== '--' ? Math.abs(number) : '--';
  let formatedNumber = numberToEvaluate;
  if (numberToEvaluate < 10 ** 9 && numberToEvaluate >= 10 ** 6) {
    formatedNumber = `${(numberToEvaluate / 10 ** 6).toFixed(1)}M`;
  } else if (numberToEvaluate < 10 ** 6 && numberToEvaluate >= 10 ** 3) {
    formatedNumber = `${(numberToEvaluate / 10 ** 3).toFixed(1)}K`;
  } else if (numberToEvaluate < 10 ** 12 && numberToEvaluate >= 10 ** 9) {
    formatedNumber = `${(numberToEvaluate / 10 ** 9).toFixed(1)}B`;
  }
  return formatedNumber;
};
