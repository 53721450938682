import { useQuery } from '@tanstack/react-query';
import { ServiceFactory } from 'services/FactoryService';
import { BucketExpressionModel } from 'types/expression.types';
import { expressionBucketToBucketExpressionModel } from 'utils/expressionTransformTo';
import useExpressionBucket from './planning/useExpressionBucket';
import useCountries from './useCountriesFilter';

type EstimationPayload = {
  country: string;
  metrics: string[];
  expression: BucketExpressionModel;
};

type EstimationParams = {
  metrics: string[];
  transformData?: (data: any) => void;
};

const getMetrics = (configuration: { queryKey: ['insights', EstimationPayload] }): Promise<any> => {
  const payload = configuration.queryKey[1];

  const service = ServiceFactory.get('insights');
  return service.getEstimations(payload);
};

export const useEstimationMetrics = ({ metrics, transformData }: EstimationParams) => {
  const { hasValidExpression, expressionBucket } = useExpressionBucket();
  const { countrySelected } = useCountries();

  const payload = {
    country: countrySelected,
    metrics,
    expression: expressionBucketToBucketExpressionModel(expressionBucket)
  };
  return useQuery(['insights', payload], getMetrics, {
    enabled: hasValidExpression,
    select: data => {
      return transformData ? transformData(data) : data;
    }
  });
};

export default useEstimationMetrics;
