import { useState } from 'react';
import { useSelector } from 'react-redux';
import * as selectors from 'selectors/index';
import { expressionBucketToBucketExpressionModel } from 'utils/expressionTransformTo';
import { message } from 'antd';
import { ServiceFactory } from '../services/FactoryService';
import { expression as Expression } from '../utils/interfaces';
import { downloadFile as fd } from '../utils/common';
import useCountries from './useCountriesFilter';
import useExpressionBucket from './planning/useExpressionBucket';

const planning = ServiceFactory.get('plannings');

const useDownloadFile = () => {
  const [error, setError] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<any>(false);
  const planificationId = useSelector(selectors.getSelectedPlanif);
  const { countrySelected } = useCountries();
  const { expressionBucket } = useExpressionBucket();

  const downloadFile = async (
    expression: Expression,
    name: string = `planning-${Math.round(Math.random() * 1000)}`
  ) => {
    setIsLoading(true);
    setError(null);

    try {
      const resp = await planning.downloadPlanification({
        expression: expressionBucketToBucketExpressionModel(expressionBucket),
        planificationId,
        country: countrySelected
      });
      // start download
      fd(resp, name);

      setIsLoading(false);
    } catch (e) {
      message.error((e as { error: string })?.error);
      setError(true);
      setIsLoading(false);
    }
  };

  return { isLoading, error, downloadFile };
};

export default useDownloadFile;
