import React from 'react';
import { Flex, Box, Icon } from '@retargetly/ui-components';
import { Input } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { faSparkles } from '@fortawesome/pro-duotone-svg-icons';
import { useTaxonomyContext } from 'components/Planner/context/hooks';

const IaSearch: React.FC = () => {
  const t = useFormatMessage();
  const { showError, setShowError, valueIa, setValueIa, handleIaSearch } = useTaxonomyContext();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (showError) {
      setShowError(false);
    }
    if (e.target.value.split(' ').length > 1000) return;
    setValueIa(e.target.value);
  };

  return (
    <Flex flexDirection="column" height="100%">
      <Box mt={6}>
        <Input
          placeholder={`${t({ id: 'SEARCH.GPT.TEXT.AREA.PLACEHOLDER' })}`}
          value={valueIa}
          defaultValue={valueIa !== null ? valueIa : ''}
          autoCorrect="off"
          autoCapitalize="off"
          spellCheck="false"
          size="large"
          onChange={handleInputChange}
          onPressEnter={handleIaSearch}
          prefix={<Icon icon={faSparkles} name="magnifying-glass" size="sm" color="primary.500" />}
          className="custom-search-box"
        />
      </Box>
    </Flex>
  );
};

export default IaSearch;
