import { useQuery } from '@tanstack/react-query';
import { PlacesResponse } from 'components/GeoTool/context/Locations/types';
import { ServiceFactory } from '../../services/FactoryService';

const mapboxServices = ServiceFactory.get('geo');

const getPlaces = async (params: any): Promise<PlacesResponse> => {
  const queryParams = params.queryKey;
  const proximity = queryParams[2];
  const query = queryParams[1];
  return mapboxServices.getPlaces(query, proximity);
};

export const useGetPlaces = ({ query, userLocation }: any) => {
  return useQuery(['places', query, userLocation], getPlaces, {
    enabled: !!userLocation && !!query,
    onError: (error: any) => {
      return error;
    }
  });
};

export default useGetPlaces;
