import React, { useContext } from 'react';
import { LocationContext } from './LocationContext';
import { locationReducer } from './LocationReducer';
import { Feature } from './types';

export const getUserLocation = async (): Promise<[number, number]> => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve([position.coords.longitude, position.coords.latitude]);
      },
      error => {
        reject(error);
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );
  });
};

export interface LocationState {
  isLoading: boolean;
  userLocation?: [number, number];
  isLoadingPlaces: boolean;
  places: Feature[];
  locations: Feature[];
  radius: number;
}

const INITIAL_STATE: LocationState = {
  isLoading: true,
  userLocation: [0, 0],
  isLoadingPlaces: false,
  places: [],
  locations: [],
  radius: 100
};

interface Props {
  children: JSX.Element | JSX.Element[];
}

export function useLocationContext(): any {
  return useContext(LocationContext);
}

export const LocationProvider = ({ children }: Props) => {
  const [state, dispatch] = React.useReducer(locationReducer, INITIAL_STATE);

  React.useEffect(() => {
    getUserLocation()
      .then(lngLat => {
        dispatch({ type: 'SET_USER_LOCATION', payload: lngLat });
      })
      .catch(err => {
        // when user denies location, set default location
        dispatch({ type: 'SET_USER_LOCATION', payload: [-58.38156883332205, -34.60374225700725] });
      });
  }, []);

  const setRadius = (radius: number) => {
    dispatch({ type: 'SET_RADIUS', payload: radius });
  };

  const addPlaces = (places: Feature) => {
    dispatch({ type: 'ADD_LOCATION', payload: places });
  };

  const deletePlaces = (id: string) => {
    dispatch({ type: 'DELETE_LOCATION', payload: id });
  };

  const resetPlaces = () => {
    dispatch({ type: 'RESET_LOCATION' });
  };

  return (
    <LocationContext.Provider value={{ ...state, setRadius, addPlaces, deletePlaces, resetPlaces }}>
      {children}
    </LocationContext.Provider>
  );
};
