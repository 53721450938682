import Repository from '../repository';

const src = 'v2/sondeo/insights';

const getOptions = () => {
  const refreshtoken = localStorage.getItem('rtToken');
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt: refreshtoken
    }
  };
};

export default {
  async getEstimations(payload) {
    const estimations = await Repository.post(src, payload, getOptions());
    return estimations.data.response.metrics || [];
  },
  async getAffinityData(payload) {
    const response = await Repository.post('v2/data/getTopClustersElastic', payload, getOptions());
    if (payload.segment_id) {
      return response.data.response.segment_id[payload.segment_id]?.segments || [];
    }
    return response.data.response.cluster_id[payload.cluster_id]?.segments || [];
  }
};
