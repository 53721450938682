import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text
} from '@retargetly/ui-components';
import { setCurrentPlanification } from 'actions/plannings';
import { Modal, Skeleton } from 'antd';
import ExpressionComponent from 'components/Expression/ExpressionComponent';
import useBlueprints, { Blueprint } from 'hooks/planning/useBlueprints';
import useExpressionBucket from 'hooks/planning/useExpressionBucket';
import usePlanning, { Planning } from 'hooks/planning/usePlannings';
import useCountries from 'hooks/useCountriesFilter';
import useOrganization from 'hooks/useOrganization';
import React, { useEffect, useMemo, useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { faArrowDownToBracket } from '@fortawesome/pro-duotone-svg-icons';
import { useDispatch } from 'react-redux';
import { expressionBucketExpressionModelToExpressionBucket } from 'utils/expressionTransformTo';
import { getPlatformIcon } from '../utils/platforms';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  // onDelete: (blueprin: Blueprint) => void;
  planning: Blueprint;
}

export function DetailPlanningModal({ isOpen, onClose, planning }: Props) {
  const t = useFormatMessage();
  const { getBlueprinMutation } = useBlueprints();
  const { currentOrganization } = useOrganization();
  const { onSetCountryFilter } = useCountries();
  const { setPlanification, downloadPlanning } = usePlanning();
  const dispatch = useDispatch();
  const { setExpressionBucket } = useExpressionBucket();
  const [planningDetail, setPlanningDetail] = useState<Planning>({} as Planning);
  const [tabIndex, setTabIndex] = useState(0);

  const planningName = useMemo(() => {
    const list = planningDetail.name?.split('|');
    const name = list?.slice(1, list.length);
    return name;
  }, [planningDetail?.name]);

  const getDetailPlanningInfo = async () => {
    const payload = { segmentId: planning?.segmentId, clientId: currentOrganization?.clientId };
    const response = await getBlueprinMutation.mutateAsync(payload);
    setPlanningDetail(response);
    // eslint-disable-next-line no-extra-boolean-cast
    if (Boolean(response?.country)) {
      onSetCountryFilter(response.country);
    }
    setPlanification({ ...response });
    dispatch(setCurrentPlanification(response));
    setExpressionBucket(expressionBucketExpressionModelToExpressionBucket(response.expression));
  };

  const isUsed = useMemo(() => {
    return (
      Number(planningDetail.usage?.dsp.total) +
        Number(planningDetail?.usage?.segments?.total) +
        Number(planningDetail?.usage?.segments?.total) >
      0
    );
  }, [planningDetail]);

  useEffect(() => {
    getDetailPlanningInfo();
  }, []);

  return (
    <Modal visible={isOpen} onCancel={onClose} footer={null} centered width={700}>
      {getBlueprinMutation.isLoading ? (
        <Skeleton active />
      ) : (
        <>
          <Flex
            flexDirection="row"
            alignItems="center"
            sx={{
              marginBottom: '20px',
              paddingRight: '14px',
              marginTop: '-10px',
              justifyContent: 'space-between'
            }}
          >
            <Heading size="100">{planningName}</Heading>
            <Button
              icon={<Icon name="arrow-down-to-bracket" size="small" icon={faArrowDownToBracket} />}
              variant="ghost"
              size="sm"
              onClick={downloadPlanning}
            />
          </Flex>

          <Tabs onChange={index => setTabIndex(index)} tabIndex={tabIndex}>
            <TabList>
              <Tab>Detalles</Tab>
              {/* @ts-ignore */}
              <Tab isDisabled={!isUsed}>Usos</Tab>
            </TabList>
            <TabPanels>
              <TabPanel>
                <Stack
                  flexDirection="column"
                  gap={2}
                  sx={{
                    height: '400px',
                    overflow: 'scroll',
                    scrollWidth: 'none',
                    '&::-webkit-scrollbar ': {
                      display: 'none'
                    }
                  }}
                >
                  <Stack sx={{ margin: '14px 0px !important' }}>
                    {planningDetail?.desc ? (
                      <Text size="bodyDefault">
                        {t({ id: 'PLANIFICATIONS.DETAIL.DESCRIPTION' })} <b>{planningDetail?.desc}</b>
                      </Text>
                    ) : null}

                    <Text size="bodyDefault">
                      {t({ id: 'PLANIFICATIONS.DETAIL.COUNTRY' })} <b>{planningDetail?.countryName}</b>
                    </Text>
                  </Stack>
                  <Text size="bodyDefault"> {t({ id: 'PLANIFICATIONS.DETAIL.USAGE' })}</Text>
                  <ExpressionComponent />
                </Stack>
              </TabPanel>
              <TabPanel>
                <Stack flexDirection="column" gap={2} sx={{ height: '400px' }}>
                  <Text size="bodyDefault"> {t({ id: 'PLANIFICATIONS.DETAIL.COUNTRY' })}</Text>
                  {planningDetail?.usage?.dsp?.list?.map(platform => (
                    <>
                      {platform?.licenses?.map(license => (
                        <Flex flexDirection="row" gap={10} alignItems="center" sx={{ padding: '20px 0px' }}>
                          <Box>
                            <img src={getPlatformIcon(platform.platformId)} alt={platform.platformName} width={60} />
                          </Box>
                          <Flex flexDirection="column">
                            <Text size="bodyBold">{platform?.platformName}</Text>
                            <Text size="bodyDefault">
                              Seat ID - {license.seatId}, {license?.name}
                            </Text>
                          </Flex>
                        </Flex>
                      ))}
                    </>
                  ))}
                </Stack>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </>
      )}
    </Modal>
  );
}
