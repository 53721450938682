import React, { useEffect, useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { notification } from 'antd';
import { Flex, Box, Icon, Button, Heading, Tooltip } from '@retargetly/ui-components';
import { useHistory } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { faArrowDownToBracket, faArrowsMinimize } from '@fortawesome/pro-duotone-svg-icons';
import PropTypes from 'prop-types';
import '../styles/components/rightPanel.scss';
import useEstimationMetrics from 'hooks/useEstimationMetrics';
import useExpressionBucket from 'hooks/planning/useExpressionBucket';
import usePlanning from 'hooks/planning/usePlannings';
import { getCurrentPlanification } from 'selectors/planification';
import { GET_BLUEPRINT } from 'hooks/planning/useBlueprints';
import { useQueryClient } from '@tanstack/react-query';
import * as actions from '../actions';
import * as selectors from '../selectors';
import SharedPlanificationModal from './SharedPlanification/SharedModal';
import * as Routes from '../constants/routes';
import EstimationComponent from './EstimationComponent';
import Modal from './Modals/Modal/Modal';
import Confirmation from './Modals/Confirmation/Confirmation';
import { ExpressionLayoutComponent } from './Expression/ExpressionLayoutComponent';

const RightMenuComponent = ({ onTogglePanelRight, onToggleShareModal }) => {
  const t = useFormatMessage();
  const history = useHistory();
  const [modalCancelPlanning, setModalCancelPlanning] = useState(false);
  const insightsScreen = history.location.pathname.includes(Routes.INSIGHTS);
  const { hasValidExpression } = useExpressionBucket();
  const queryClient = useQueryClient();
  const currentPlanification = useSelector(getCurrentPlanification);
  const metrics = [
    'total_devices',
    'android_devices',
    'ios_devices',
    'top_clusters',
    'web_no_mobile_devices',
    'web_mobile_devices'
  ];
  const { data, isLoading: isLoadingMetrics, isError } = useEstimationMetrics({ metrics });
  const { hasValidPlanification, cancelPlanning, downloadPlanning, isDownloading, errorDownloading } = usePlanning();
  const isOpenRigthMenu = useSelector(selectors.getRightPanel);
  const planningName = useMemo(() => {
    const list = currentPlanification.name?.split('|');
    const name = list?.slice(1, list.length);
    return name;
  }, [currentPlanification?.name]);

  const handleOnEdit = () => {
    onToggleShareModal(true);
  };

  const handleCloseAction = () => {
    onTogglePanelRight(false);
  };

  useEffect(() => {
    if (!hasValidPlanification) {
      onTogglePanelRight(false);
    }
    if (hasValidPlanification && !isOpenRigthMenu) {
      onTogglePanelRight(true);
    }
  }, [hasValidPlanification]);

  useEffect(() => {
    if (errorDownloading) {
      notification.error({
        message: t({ id: 'GENERIC.ERROR_1' }),
        description: t({ id: 'GENERIC.ERROR_2' })
      });
    }
  }, [errorDownloading]);

  const showInsightsHandler = () => {
    history.push(Routes.INSIGHTS);
  };

  const handleCancelPlanning = () => {
    cancelPlanning();
    setModalCancelPlanning(false);
    onTogglePanelRight(false);
  };

  return (
    <>
      <SharedPlanificationModal />
      <Modal open={modalCancelPlanning}>
        <Confirmation
          open={modalCancelPlanning}
          title={t({ id: 'PLANIFICATION.MODAL.REMOVE.TITLE' })}
          description={t({ id: 'PLANIFICATION.MODAL.REMOVE.DESCRIPTION' })}
          confirmationText={t({ id: 'GENERIC.LABEL.CONTINUE' })}
          onConfirmation={handleCancelPlanning}
          cancelText={t({ id: 'PLANIFICATION.CANCEL' })}
          onCancel={() => setModalCancelPlanning(false)}
        />
      </Modal>

      <Flex
        align="space-between"
        direction="column"
        id="right-menu-component"
        style={{ height: 'calc(100vh - 60px)', width: '25vw', minWidth: 315 }}
        data-testid="rightMenuComponent"
      >
        <Flex justify="space-between" m={4}>
          <div>
            <Heading size="200" ml={2} className="planner-title text-capitalize">
              {currentPlanification?.name
                ? planningName
                : t({
                    id: 'PLANIFICATION.INFO.YOURS'
                  })}
            </Heading>
          </div>

          <Flex flexDirection="row">
            <Tooltip
              hasArrow
              placement="auto"
              label={t({
                id: `PLANIFICATION.DOWNLOAD`
              })}
              shouldWrapChildren
            >
              <Flex width="32px" height="32px" justifyContent="center" alignItems="center">
                <Button
                  disable={isDownloading.toString() || !hasValidExpression}
                  loading={isDownloading.toString()}
                  icon={<Icon name="arrow-down-to-bracket" size="small" icon={faArrowDownToBracket} />}
                  variant="ghost"
                  size="sm"
                  onClick={downloadPlanning}
                />
              </Flex>
            </Tooltip>

            <Tooltip
              hasArrow
              placement="auto"
              label={t({
                id: `PLANIFICATION.CLOSE`
              })}
              shouldWrapChildren
            >
              <Flex width="32px" height="32px" justifyContent="center" alignItems="center">
                <Button
                  icon={<Icon name="arrows-minimize" size="small" icon={faArrowsMinimize} />}
                  variant="ghost"
                  size="sm"
                  onClick={handleCloseAction}
                />
              </Flex>
            </Tooltip>
          </Flex>
        </Flex>

        <EstimationComponent
          data={data}
          isLoadingMetrics={isLoadingMetrics || queryClient.isMutating({ queryKey: GET_BLUEPRINT })}
          isError={isError}
        />

        <ExpressionLayoutComponent />

        <Flex justify="end" m={5}>
          <Box mr={2}>
            <Button
              variant="secondary"
              onClick={() => setModalCancelPlanning(true)}
              label={t({
                id: `PLANIFICATION.CLOSE`
              })}
            />
          </Box>
          {!insightsScreen ? (
            <Button
              variant="primary"
              onClick={showInsightsHandler}
              disabled={!hasValidExpression}
              label={t({
                id: `PLANIFICATION.INSIGHTS`
              })}
            />
          ) : (
            <Button
              variant="primary"
              onClick={handleOnEdit}
              disabled={!hasValidExpression}
              label={t({
                id: `PLANIFICATION.CREATE`
              })}
            />
          )}
        </Flex>
      </Flex>
    </>
  );
};

function mapStateToProps(state) {
  return {
    modal: selectors.getModal(state)
  };
}

const mapDispatchToProps = dispatch => {
  return {
    onTogglePanelRight: (payload = false) => dispatch(actions.togglePanelRight(payload)),
    onToggleModal: () => dispatch(actions.toggleModal()),
    onToggleShareModal: val => dispatch(actions.toggleShareModal(val))
  };
};

RightMenuComponent.propTypes = {
  onTogglePanelRight: PropTypes.func.isRequired,
  onToggleShareModal: PropTypes.func.isRequired
};

RightMenuComponent.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(RightMenuComponent);
