import React, { useState, useEffect } from 'react';
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';

export interface Props {}

const AsyncFlagProvider: React.FC<Props> = ({ children }) => {
  const [Provider, setProvider] = useState<React.FC<any> | null>(null);

  /**
   * Calls feature provider asynchronously and sets the Provider for render
   */
  useEffect(() => {
    async function startFlagProvider() {
      try {
        const TempFlagProvider = await asyncWithLDProvider({
          clientSideID: `${process.env.REACT_APP_FLAG_CLIENT_ID}`,
          context: {
            kind: 'user',
            key: 'unlogged-user'
          },
          options: {
            /* ... */
          }
        });
        setProvider(() => TempFlagProvider);
      } catch (e) {
        console.error('the feature provider has failed', e);
      }
    }

    startFlagProvider();
  }, []);

  return Provider && <Provider>{children}</Provider>;
};

export default AsyncFlagProvider;
