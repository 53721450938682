import React from 'react';
import useTheme from 'hooks/themes/useTheme';
import { customTheme, RelyProvider as RelyBaseProvider } from '@retargetly/ui-components';
import { shadeColor } from 'utils/shadeColor';

// @ts-ignore
export const RelyProvider = ({ children }) => {
  const { getThemeProp } = useTheme();

  const dmpTheme = customTheme({
    styles: {
      global: () => ({
        body: {
          bg: '',
          fontFamily: '',
          backgroundColor: '',
          fontSize: '',
          lineHeight: '',
          color: '',
          fontWeight: '',
          transitionProperty: '',
          transitionDuration: ''
        },
        root: {
          bg: '',
          fontFamily: '',
          backgroundColor: '',
          fontSize: '',
          lineHeight: '',
          color: '',
          fontWeight: '',
          transitionProperty: '',
          transitionDuration: ''
        },
        html: {
          bg: '',
          fontFamily: '',
          backgroundColor: '',
          fontSize: '',
          lineHeight: '',
          color: '',
          fontWeight: '',
          transitionProperty: '',
          transitionDuration: ''
        }
      })
    },
    textStyles: {
      h1: {
        fontSize: '28px',
        fontWeight: 'bold',
        lineHeight: '32px',
        fontFamily: 'Avenir Next LT Pro'
      },
      h2: {
        fontSize: '24px',
        fontWeight: 'bold',
        lineHeight: '28px',
        fontFamily: 'Avenir Next LT Pro'
      },
      h3: {
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: '24px',
        fontFamily: 'Avenir Next LT Pro'
      },
      h4: {
        fontSize: '16px',
        fontWeight: 'medium',
        lineHeight: '18px',
        fontFamily: 'Avenir Next LT Pro'
      },
      subtitle: {
        fontSize: '16px',
        fontWeight: 'bold',
        lineHeight: '20px',
        fontFamily: 'Avenir Next LT Pro'
      },
      body: {
        fontSize: '14px',
        fontWeight: 'normal',
        lineHeight: '16px',
        fontFamily: 'Avenir Next LT Pro'
      },
      helperText: {
        fontSize: '10px',
        fontWeight: 'normal',
        lineHeight: '16px',
        fontFamily: 'Avenir Next LT Pro'
      },
      caption: {
        fontSize: '9px',
        fontWeight: 'normal',
        lineHeight: '10px',
        fontFamily: 'Avenir Next LT Pro'
      },
      bodyUppercase: {
        textTransform: 'uppercase',
        fontFamily: 'Avenir Next LT Pro',
        fontSize: '16px',
        fontWeight: 'normal',
        lineHeight: '18px'
      },
      bodyBold: {
        fontSize: '14px',
        fontFamily: 'Avenir Next LT Pro',
        fontWeight: 'bold',
        lineHeight: '16px'
      },
      bodyMedium: {
        fontSize: '14px',
        fontFamily: 'Avenir Next LT Pro',
        fontWeight: 'medium',
        lineHeight: '16px'
      },
      bodyDefault: {
        fontSize: '14px',
        fontFamily: 'Avenir Next LT Pro',
        fontWeight: 'normal',
        lineHeight: '16px'
      },
      bodyDemibold: {
        fontSize: '14px',
        fontWeight: 'bold',
        lineHeight: '16px'
      },
      bodySmall: {
        fontSize: '12px',
        fontFamily: 'Avenir Next LT Pro',
        fontWeight: 'normal',
        lineHeight: '14px'
      }
    },
    letterSpacings: {
      tighter: '-0.05em',
      tight: '-0.025em',
      normal: '0',
      wide: '0.025em',
      wider: '0.05em',
      widest: '0.1em'
    },
    fontWeights: {
      hairline: 100,
      thin: 200,
      light: 300,
      normal: 400,
      medium: 500,
      semibold: 600,
      bold: 700,
      extrabold: 800,
      black: 900
    },
    fonts: {
      heading: 'Avenir Next LT Pro',
      body: 'Avenir Next LT Pro',
      mono: 'Avenir Next LT Pro'
    },
    lineHeights: {
      normal: 'normal',
      none: 1,
      shorter: 1.25,
      short: 1.375,
      base: 1.5,
      tall: 1.625,
      taller: '2',
      '3': '.75rem',
      '4': '1rem',
      '5': '1.25rem',
      '6': '1.5rem',
      '7': '1.75rem',
      '8': '2rem',
      '9': '2.25rem',
      '10': '2.5rem'
    },
    fontSizes: {
      xs: '9px',
      sm: '12px',
      md: '14px',
      lg: '16px',
      xl: '20px',
      '2xl': '24px',
      '3xl': '32px',
      '4xl': '40px',
      '5xl': '48px',
      '6xl': '56px',
      '7xl': '64px',
      '8xl': '72px',
      '9xl': '11px'
    },
    colors: {
      primary: {
        500: getThemeProp('primary_color'),
        600: shadeColor(getThemeProp('primary_color'), -10)
      },
      secondary: {
        500: getThemeProp('secondary_color'),
        600: shadeColor(getThemeProp('secondary_color'), -10)
      }
    },
    sizes: {
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      8: '32px',
      10: '40px',
      14: '56px'
    },
    space: {
      0.5: '2px',
      1: '4px',
      2: '8px',
      3: '12px',
      4: '16px',
      5: '20px',
      6: '24px',
      8: '32px',
      10: '40px',
      14: '56px'
    }
  });
  return <RelyBaseProvider theme={dmpTheme}>{children}</RelyBaseProvider>;
};
