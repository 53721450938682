/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import { useFlagLoader } from 'hooks/featureflag';
import { useSelector } from 'react-redux';
import { Layout, Spin } from 'antd';
import { Redirect, Route, Switch, useLocation, useHistory } from 'react-router-dom';
import { Sidebar } from 'components/Sidebar/Sidebar';
import { useQueryParams } from 'hooks/useQueryParams';
import { PlanificationProvider } from 'components/SharedPlanification/context';
import { useCookies } from 'react-cookie';
import useUserMonitoringLoader from 'hooks/SyntheticMonitoring/hooks/useUserMonitoringLoader';
import useOrganization from 'hooks/useOrganization';
import Keywords from 'containers/Keywords/Keywords';
import * as selectors from '../../selectors';
import Header from '../../components/Header/Header';
import ContentPlannerComponent from '../../components/ContentPlannerComponent';
import useAuth from '../../hooks/useAuth.tsx';
import WithErrorHandling from '../../hocs/Auth/WithErrorHandling';
import * as Routes from '../../constants/routes';
import { AbilityContext } from '../../components/casl/Can.ts';
import { ability } from '../../components/casl/ability.ts';
import GeoTool from '../GeoTool/GeoTool';
import ModalReDesing from './ModalReDesing';

const Home = () => {
  const [cookie, setCookie] = useCookies(['jwtToken', 'rtToken', 'sidebar']);
  const query = useQueryParams();
  const view_as = query.get('view_as');
  const { isLoggedIn: userIsLoggedIn, user, token, refreshToken } = useAuth();
  const { organizations, onSetCurrentOrganization, currentOrganization } = useOrganization();
  const location = useLocation();
  const { setUserForFlagContext } = useFlagLoader();
  const logOutScreen = useSelector(selectors.getLogOutScreen);
  const { setUserForMonitoringContext } = useUserMonitoringLoader();
  const cookieOptions = {
    path: '/',
    domain: process.env.REACT_APP_API_DOMAIN
  };

  // Setting config based on user and token
  if (userIsLoggedIn) {
    setUserForFlagContext(user);
    setUserForMonitoringContext(user);
    setCookie('jwtToken', token, cookieOptions);
    setCookie('rtToken', refreshToken, cookieOptions);
  }

  /**
   * @description This is to store sidebar state across the platform DMP/LOOP
   */
  const [open, setOpen] = useState(cookie.sidebar === 'true');
  const closeSidebar = state => {
    setOpen(state);
    setCookie('sidebar', state, cookieOptions);
  };

  /**
   * @description This is different from client_id of the logged user, this selector refers to selected organization for viewAs handling
   */
  useEffect(() => {
    const viewAs = parseInt(view_as, 0);
    if (viewAs && viewAs !== currentOrganization?.id && organizations?.length > 0) {
      const org = organizations.find(client => client.clientId === viewAs);
      if (org) {
        onSetCurrentOrganization({ ...org, id: org.clientId });
      }
    }
  }, [view_as, currentOrganization, organizations, onSetCurrentOrganization]);

  return (
    <AbilityContext.Provider value={ability}>
      <ModalReDesing />
      <Spin size="large" spinning={logOutScreen}>
        <Header />
        <Layout
          style={{
            height: 'calc(100vh - 60px)',
            overflow: 'hidden',
            position: 'relative',
            zIndex: 0,
            display: 'flex',
            flexDirection: 'row'
          }}
        >
          <Sidebar open={open} setOpen={closeSidebar} position="static" />
          <Switch>
            <Route exact path="/" render={() => <Redirect to={{ ...location, pathname: Routes.SEARCH }} />} />
            <Route path={Routes.KEYWORDS} exact component={Keywords} />
            <Route path={Routes.GEOTOOL} exact component={GeoTool} />
            <Route to="*">
              <PlanificationProvider>
                <ContentPlannerComponent />
              </PlanificationProvider>
            </Route>
          </Switch>
        </Layout>
      </Spin>
    </AbilityContext.Provider>
  );
};

Home.propTypes = {};

export default WithErrorHandling(Home);
