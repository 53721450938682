import React, { FC } from 'react';
import { Box, Span } from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';

export const ContainerResultSearch: FC = ({ children }) => {
  const t = useFormatMessage();

  return (
    <Box display="flex" flexDirection="column" position="relative" pb={4}>
      <Box mb={2} position="sticky" top={0} backgroundColor="#fff" zIndex={9}>
        <Span fontSize="0.75rem" color="neutrals.400" textTransform="uppercase">
          {t({ id: 'SEARCH.RESULTS.TITLE' })}
        </Span>
      </Box>
      {children}
    </Box>
  );
};
