import React, { FC } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  Heading,
  Text,
  Flex
} from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';
import { useModalInfo } from './useModalInfo';
import { PathKey } from './pathInfo';

type Props = {
  showModal: boolean;
  handleClose: () => void;
  path: string;
};

export const NoDmpModal: FC<Props> = ({ path, showModal, handleClose }) => {
  const t = useFormatMessage();

  const modalInfo = useModalInfo(path as PathKey);

  return (
    <>
      <Modal isOpen={showModal} onClose={handleClose} size="xl" autoFocus={false}>
        <ModalOverlay />
        <ModalContent height="544px">
          <ModalHeader px={0} pt={0}>
            <img src={modalInfo?.image} alt={modalInfo?.title} />
          </ModalHeader>
          <ModalBody px={8} my={2}>
            <Flex flexDirection="column" alignItems="center" justifyContent="center">
              <Heading size="200" color="text.500">
                {t(
                  {
                    id: 'MODAL.NODMP.TITLE'
                  },
                  { path: modalInfo?.title }
                )}
              </Heading>
              <Flex
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                mt={2}
                textAlign="center"
                px={8}
                gap={4}
              >
                <Text size="bodyDefault" color="neutrals.400">
                  {modalInfo?.text1}
                </Text>
                <Text size="bodyDefault" color="neutrals.400">
                  {modalInfo?.text2}
                </Text>
              </Flex>
            </Flex>
          </ModalBody>
          <ModalFooter justifyContent="center">
            <Button
              variant="primary"
              type="button"
              onClick={handleClose}
              label={`${t({
                id: 'GENERIC.LABEL.UNDERSTOOD'
              })}`}
            />
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
