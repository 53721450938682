/* eslint-disable camelcase */
import React, { FC } from 'react';
import { Flex } from '@retargetly/ui-components';
import Skeleton from 'antd/lib/skeleton';
import useEstimationMetrics from 'hooks/useEstimationMetrics';
import PlatformStatics from './PlatformStatics';

type Props = {
  totalDeviceData: any;
};

const PlatformReachCard: FC<Props> = ({ totalDeviceData }) => {
  const metrics = ['devices_by_platform'];
  const { data: dataMetrics, isLoading, isError } = useEstimationMetrics({ metrics });

  return (
    <>
      {isLoading && !isError && (
        <Skeleton
          active
          title={false}
          paragraph={{
            rows: 2,
            width: 100
          }}
        />
      )}

      <Flex flexWrap="wrap" flexDirection="row" align="center" gap={6} px={2}>
        {dataMetrics?.devices_by_platform?.map((item: any) => {
          return (
            <PlatformStatics
              key={item.id}
              platformId={item.id}
              percentage={item.total_devices > 0 ? (item.total_devices / totalDeviceData?.total_devices) * 100 : 0}
              volume={item.total_devices}
            />
          );
        })}
      </Flex>
    </>
  );
};

export default PlatformReachCard;
