import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import './SearchInput.scss';
import { Flex, Icon } from '@retargetly/ui-components';
import { Input } from 'antd';
import { faMagnifyingGlass } from '@fortawesome/pro-duotone-svg-icons';

type Props = {
  query: string | null;
  searchSegments: (query: string) => void;
  loading: boolean;
  resetListResults: () => void;
};

const SearchInput: React.FC<Props> = ({ query, searchSegments, loading, resetListResults }) => {
  const t = useFormatMessage();

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value === '') {
      resetListResults();
    }
  };

  const searchHandle = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const inputValue = (e.target as HTMLInputElement).value;
    if (inputValue === '' || inputValue.length === 1) {
      return;
    }
    searchSegments(inputValue);
  };

  return (
    <Flex>
      <Input
        placeholder={`${t({
          id: `SEARCH.INPUT.PLACEHOLDER`
        })}`}
        defaultValue={query !== null ? query : ''}
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        onChange={value => handleInputChange(value)}
        onPressEnter={v => searchHandle(v)}
        className="custom-search-box"
        prefix={<Icon icon={faMagnifyingGlass} name="magnifying-glass" size="sm" color="primary.500" />}
      />
    </Flex>
  );
};

export default SearchInput;
