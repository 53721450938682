import { AbilityBuilder, Ability, detectSubjectType, AbilityClass } from '@casl/ability';
import isEqual from 'lodash/isEqual';
import configStore from '../../store/store';

type Actions = 'manage' | 'create' | 'see' | 'update' | 'delete';
type Subjects = 'BOT-SECTION' | 'PLAN-PANEL' | 'LICENSE' | 'all';

export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

const { store } = configStore();

export default function defineRulesFor(permissions: any) {
  const { can, rules } = new AbilityBuilder<AppAbility>();

  /*   if (role === 'account admin') {
    can('manage', 'all');
  } else {
    cannot('see', 'PLAN-PANEL');
     can(['update', 'delete'], 'Todo', { assignee: 'me' }); 
  } */

  if (permissions?.audience?.create) {
    can('create', 'LICENSE');
  }

  return rules;
}

/**
 * Read for details: https://stalniy.github.io/casl/v4/en/guide/subject-type-detection
 */
function detectAppSubjectType(subject?: Subjects) {
  /*   if (subject && typeof subject === 'object' && subject.type) {
    return subject.type;
  } */

  return detectSubjectType(subject);
}

/* export function buildAbilityFor(role: string, plan: string): AppAbility {
    return new AppAbility(defineRulesFor(role, plan), {
        detectSubjectType: detectAppSubjectType
      }); 
} */

export const ability = new AppAbility([], {
  detectSubjectType: detectAppSubjectType
});

let currentPermissions: {};
store.subscribe(() => {
  const prevPermissions = currentPermissions;
  currentPermissions = store.getState().auth.permissions;

  if (!isEqual(prevPermissions, currentPermissions)) {
    ability.update(defineRulesFor(currentPermissions));
  }
});
