import React, { FC } from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import { usLocalizeFormate } from 'utils/usLocalizeFormate';
import { Box } from '@retargetly/ui-components';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

type Props = {
  data: {
    labels: string[];
    responsive: boolean;
    datasets: {
      label: string;
      data: number[];
      backgroundColor: string;
      borderColor: string;
      borderWidth: number;
    }[];
  }[];
};

const options = {
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    r: {
      ticks: {
        callback: (value: any) => {
          return `${value} %`;
        }
      }
    }
  },
  plugins: {
    legend: {
      position: 'bottom' as 'bottom',
      labels: {
        usePointStyle: true,
        boxHeight: 6
      }
    },
    tooltip: {
      callbacks: {
        label: (context: any) => {
          const localizeFormat = usLocalizeFormate.format(',.2f');
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.raw !== null) {
            label += `${localizeFormat(context.raw)} %`;
          }
          return label;
        }
      }
    }
  }
};

const InterestRadarGraph: FC<Props> = ({ data }) => {
  return (
    <Box h="400px" width="100%" maxW="800px" mt={8}>
      <Radar data={data[0]} options={options} />
    </Box>
  );
};

export default InterestRadarGraph;
