import produce from 'immer';
import { SEARCH_SEGMENTS } from '../constants/actionTypes';

const prevState = {
  results: [],
  query: ''
};

export default (state = prevState, action) => {
  switch (action.type) {
    case SEARCH_SEGMENTS:
      return produce(state, draftState => {
        draftState.query = action.payload.query;
        draftState.results = action.payload.segmentsResultsData;
      });
    default:
      return state;
  }
};
