/* eslint-disable camelcase */
import React, { FC } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { usLocalizeFormate } from 'utils/usLocalizeFormate';
import { colors } from 'utils/insightsGraphConfiguration';
import useEstimationMetrics from 'hooks/useEstimationMetrics';
import transparentize from 'utils/transparentize';
import { Error, Loading, Empty } from 'components/StateComponents';
import { Flex } from '@retargetly/ui-components';

ChartJS.register(ArcElement, Tooltip, Legend);

const IdentifiersGraph: FC = () => {
  const transformDataDemographic = (response: any) => {
    let cookiePercent: number | undefined = 0;
    let mobileIdPercent: number | undefined = 0;
    const { web_mobile_devices, web_no_mobile_devices, android_devices, ios_devices } = response;
    const total = web_mobile_devices + web_no_mobile_devices + android_devices + ios_devices;

    if (total > 0) {
      cookiePercent = ((web_mobile_devices + web_no_mobile_devices) * 100) / total;
      mobileIdPercent = ((android_devices + ios_devices) * 100) / total;
    } else {
      return undefined;
    }

    const data = {
      labels: ['Cookies', 'Mobile Ad ID'],
      datasets: [
        {
          data: [cookiePercent, mobileIdPercent],
          backgroundColor: [transparentize(colors[0], 0.5), transparentize(colors[2], 0.5)],
          borderColor: [transparentize(colors[0], 0.5), transparentize(colors[2], 0.5)],
          borderWidth: 1
        }
      ]
    };

    return data;
  };

  const { data: totalDeviceData, isLoading, isError } = useEstimationMetrics({
    metrics: [
      'total_devices',
      'android_devices',
      'ios_devices',
      'people',
      'web_no_mobile_devices',
      'web_mobile_devices'
    ],
    transformData: transformDataDemographic
  });

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        position: 'right' as 'right',
        labels: {
          usePointStyle: true,
          boxHeight: 6
        }
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const localizeFormat = usLocalizeFormate.format(',.2f');
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.raw !== null) {
              label += `${localizeFormat(context.raw)} %`;
            }
            return label;
          }
        }
      }
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      {isError && !isLoading && <Error />}

      {!isError && !isLoading && (totalDeviceData?.datasets?.length === 0 || totalDeviceData === undefined) && (
        <Empty />
      )}

      {!isLoading && totalDeviceData !== undefined && (
        <Flex flexDirection="column" alignItems="center" h="300px" width="90%">
          <Pie data={totalDeviceData} options={options} />
        </Flex>
      )}
    </>
  );
};

export default IdentifiersGraph;
