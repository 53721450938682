import React, { createRef, useState } from 'react';
import { Flex, Card, CardHeader, CardContent, Text, Box } from '@retargetly/ui-components';
import { InfoTooltip } from 'components/Ui';
import { useFormatMessage } from 'react-intl-hooks';
import DownloadButton from 'components/Ui/DownloadButton/DownloadButton';
import useAffinity from 'hooks/useAffinity';
import TabSelector from './TabSelector';

const Interest = () => {
  const t = useFormatMessage();
  const [clustersSelected, setClustersSelected] = useState([{ name: 'Interest', id: 7, type: 'clusterId' }]);
  const selected = clustersSelected[clustersSelected.length - 1];

  const payload = {
    clusterId: selected.type === 'clusterId' ? selected.id : undefined,
    segmentId: selected.type === 'segmentId' ? selected.id : undefined
  };

  const { isLoading, data, isError } = useAffinity(payload);
  const ref = createRef<HTMLDivElement>();

  const handleOpenChildAudience = (id: number, name: string) => {
    setClustersSelected([...clustersSelected, { name, id, type: 'segmentId' }]);
  };

  const handleBack = () => {
    if (clustersSelected.length > 1) {
      setClustersSelected(clustersSelected.slice(0, clustersSelected.length - 1));
    }
  };

  return (
    <div ref={ref}>
      <Flex flexDirection="column" alignItems="center" mb={6} px={4} my={4}>
        <Card height="auto" width="100%">
          <CardHeader bgColor="white">
            <Flex flexDirection="row" alignItems="center">
              <Flex flexDirection="row" alignItems="center" flexGrow={1}>
                <Box flex>
                  <Text size="bodyBold">{t({ id: 'INSIGHTS.INTEREST.TOPIC' })}</Text>
                </Box>
                <Box pl={2}>
                  <InfoTooltip text={`${t({ id: 'INSIGHTS.INTEREST.HEADER.TOOLTIP' })}`} />
                </Box>
              </Flex>
              <DownloadButton svgRef={ref} name={`${t({ id: 'INSIGHTS.INTEREST.TOPIC' })}`} />
            </Flex>
          </CardHeader>
          <CardContent>
            <TabSelector
              isLoading={isLoading}
              data={data}
              isError={isError}
              handleOpenChildAudience={handleOpenChildAudience}
              handleBack={handleBack}
              clustersSelected={clustersSelected}
            />
          </CardContent>
        </Card>
      </Flex>
    </div>
  );
};

export default Interest;
