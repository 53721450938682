import React, { FC, useState, useEffect, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { SideBarMenuList, SideBarMenuListMenuItem, Sidebar as SideBar, Icon } from '@retargetly/ui-components';
import useEventTracker from 'hooks/useEventTracker';
import {
  faChartMixed,
  faDatabase,
  faFileCertificate,
  faObjectsColumn,
  faRectangleAd,
  faUsers,
  faMagnifyingGlass,
  faBookFont,
  faGlobe,
  faBars
} from '@fortawesome/pro-duotone-svg-icons';
import { UserGuides } from 'constants/user-guides';
import useAuth from 'hooks/useAuth';
import { useFormatMessage } from 'react-intl-hooks';
import useFeatureFlag from 'hooks/featureflag/useFeatureFlag';
import { NoDmpModal } from 'components/NoDmpModal/NoDmpModal';
import { GEOTOOL, KEYWORDS, SEARCH } from 'constants/routes';
import useOrganization from '../../hooks/useOrganization';

const tabs = {
  tabId0: 'overview_tab',
  tabId1: 'segments_tab',
  tabId2: 'seats_tab',
  tabId3: 'sources_tab',
  tabId4: 'insights_tab',
  tabId5: 'campaigns_tab',
  tabId6: 'planner_tab',
  tabId7: 'keywords_tab',
  tabId8: 'geotool_tab',
  tabId9: 'planning_tab'
};

type Props = {
  open: boolean;
  setOpen: (open: boolean) => void;
};

export const Sidebar: FC<Props> = ({ open, setOpen }) => {
  const { trackEvent } = useEventTracker();
  const t = useFormatMessage();
  const history = useHistory();
  const { currentOrganization } = useOrganization();
  const hasDmpLicense = currentOrganization?.products?.dmp === true;
  const matchRoute = useRouteMatch('/:section');
  const section = matchRoute?.url || '';
  const { geotool: GeoToolFlag, keywords: KeywordsFlag } = useFeatureFlag();

  const getActualSection = useCallback(() => {
    switch (true) {
      case section.indexOf('overview') !== -1:
        return tabs.tabId0;
      case section.indexOf('segments') !== -1:
        return tabs.tabId1;
      case section.indexOf('seats') !== -1:
        return tabs.tabId2;
      case section.indexOf('sources') !== -1:
        return tabs.tabId3;
      case section.indexOf('insights') !== -1:
        return tabs.tabId4;
      case section.indexOf('campaigns') !== -1:
        return tabs.tabId5;
      case section.includes('planner'):
        return tabs.tabId6;
      case section.indexOf('keywords') !== -1:
        return tabs.tabId7;
      case section.indexOf('geotool') !== -1:
        return tabs.tabId8;
      case section.indexOf('planning') !== -1:
        return tabs.tabId9;
      default:
        return '';
    }
  }, [section]);

  const [selected, setSelected] = useState(getActualSection());

  useEffect(() => {
    const actualSection = getActualSection();
    if (actualSection !== selected) {
      setSelected(actualSection);
    }
  }, [matchRoute, selected, getActualSection]);

  const { userLang, token } = useAuth();

  const [showModal, setShowModal] = useState(false);
  const [modalPath, setModalPath] = useState<string>('');

  const handleClose = () => {
    setShowModal(false);
  };

  const showNoDmpModal = (path: string) => {
    setShowModal(true);
    setModalPath(path);
  };

  const goToDMP = (path: string) => {
    if (!hasDmpLicense) {
      showNoDmpModal(path);
    } else {
      // updateCookie(token);
      window.location.replace(
        `${process.env.REACT_APP_REDIRECT_DMP_APP}auth/?view_as=${currentOrganization.clientId}&path=/${path}`
      );
    }
  };

  const items: SideBarMenuListMenuItem[] = [
    {
      id: 'general',
      label: 'General',
      onClick: () => null,
      visible: true,
      disabled: true,
      selected: false,
      type: 'title'
    },
    {
      id: 'overview',
      label: 'Overview',
      icon: <Icon icon={faObjectsColumn} name="objects-column" />,
      onClick: () => {
        goToDMP('overview');
      },
      visible: true,
      disabled: true,
      selected: selected === tabs.tabId0
    },
    {
      id: 'segments',
      label: 'Segments',
      icon: <Icon icon={faUsers} name="users" />,
      onClick: () => {
        goToDMP('segments');
      },
      visible: true,
      disabled: true,
      selected: selected === tabs.tabId1
    },
    {
      id: 'seats',
      label: 'Seats',
      icon: <Icon icon={faBars} name="bars" />,
      onClick: () => {
        goToDMP('seats');
      },
      visible: true,
      disabled: true,
      selected: selected === tabs.tabId2
    },
    {
      id: 'firstParty',
      label: 'First Party',
      onClick: () => null,
      visible: true,
      disabled: true,
      selected: false,
      type: 'title'
    },

    {
      id: 'source',
      label: 'Sources',
      icon: <Icon icon={faDatabase} name="database" />,
      onClick: () => {
        goToDMP('sources');
      },
      visible: true,
      disabled: true,
      selected: selected === tabs.tabId3
    },
    {
      id: 'analytics',
      label: 'Analytics',
      icon: <Icon icon={faChartMixed} name="chart-mixed" />,
      onClick: () => {
        goToDMP('insights/demographics');
      },
      visible: true,
      disabled: true,
      selected: selected === tabs.tabId4
    },
    {
      id: 'campaigns',
      label: 'Campaigns',
      icon: <Icon icon={faRectangleAd} name="rectangle-ad" />,
      onClick: () => {
        goToDMP('campaigns');
      },
      visible: true,
      disabled: true,
      selected: selected === tabs.tabId5
    },
    {
      id: 'licence',
      label: 'Licence',
      icon: <Icon icon={faFileCertificate} name="file-certificate" />,
      onClick: () => history.push('/TODO'),
      visible: false,
      disabled: false,
      selected: false
    },
    {
      id: 'thirdParty',
      label: 'Third Party',
      onClick: () => null,
      visible: true,
      disabled: true,
      selected: false,
      type: 'title'
    },
    {
      id: 'planner',
      label: 'Planner',
      icon: <Icon icon={faMagnifyingGlass} name="magnifying-glass" />,
      onClick: () => history.push(SEARCH),
      visible: true,
      disabled: true,
      selected: selected === tabs.tabId6
    },
    {
      id: 'keywords',
      label: 'Keywords',
      icon: <Icon icon={faBookFont} name="book-font" />,
      onClick: () => history.push(KEYWORDS),
      visible: KeywordsFlag,
      disabled: true,
      selected: selected === tabs.tabId7
    },
    {
      id: 'geotool',
      label: 'Geographics',
      icon: <Icon icon={faGlobe} name="globe" />,
      onClick: () => {
        trackEvent('GEO_SIDEBAR_CLICK', {});
        history.push(GEOTOOL);
      },
      visible: GeoToolFlag,
      disabled: true,
      selected: selected === tabs.tabId8
    }
  ];

  const showUserGuide = () => {
    const lang = userLang === 'pt' ? 'pt' : 'es';
    window.open(UserGuides[lang], 'new');
  };

  return (
    <>
      <SideBar
        position="static"
        variant="collapsible"
        collapsed={open}
        setCollapsed={setOpen}
        paddingTop="8px"
        version={`v${process.env.REACT_APP_VERSION}`}
        showUserHelper={false}
        userHelperAction={showUserGuide}
        userHelperText={`${t({ id: 'SIDEBAR.HELP.TEXT' })}`}
        userHelperButtonText={`${t({ id: 'SIDEBAR.BUTTON.TEXT' })}`}
      >
        <SideBarMenuList collapsed={open} items={items} />
      </SideBar>
      <NoDmpModal path={modalPath} showModal={showModal} handleClose={handleClose} />
    </>
  );
};
