import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import storageSession from 'redux-persist/lib/storage/session';

import rootReducer from '../reducers/index';

const persistConfig = {
  key: 'root',
  storage: storageSession,
  throttle: 1000,
  timeout: 1000,
  blacklist: ['auth', 'searchSegments', 'insights', 'segments', 'errors', 'organizations']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, {}, composeWithDevTools(applyMiddleware(thunk)));

export default () => {
  const persistor = persistStore(store);
  return {
    store,
    persistor
  };
};
