import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { Formik, Field, Form, FormikProps, ErrorMessage } from 'formik';
import { Button, TextArea, Box, Text, Flex, Alert, DropDown } from '@retargetly/ui-components';
import { useLocationContext } from 'components/GeoTool/context/Locations/LocationProvider';
import { useCreateGeoAudience } from 'hooks/geotool/useCreateGeoAudience';
import { useFormatMessage } from 'react-intl-hooks';
import { useMapContext } from 'components/GeoTool/context/Map/MapProvider';
import FormikInput from 'widgets/forms/input/FormikInput';
import useOrganization from 'hooks/useOrganization';
import useGetGeoCountries from 'hooks/geotool/useGetGeoCountries';

// TODO: Create reusable component for this
const TextAreaInput = ({ field, form, ...props }: any) => {
  return <TextArea height="80px" {...field} {...props} />;
};

const CreationForm = () => {
  const { locations, resetPlaces } = useLocationContext();
  const { drawInstance, bulkFileError, setBulkError } = useMapContext();
  const [creationState, setCreationState] = useState<undefined | 'success' | 'error'>(undefined);
  const { currentOrganization } = useOrganization();
  const t = useFormatMessage();
  const { data: countries } = useGetGeoCountries();

  useEffect(() => {
    setTimeout(() => {
      setCreationState(undefined);
    }, 5000);
  }, [creationState]);

  /* Hide bulk error warning, when the headers are invalid */
  useEffect(() => {
    setTimeout(() => {
      setBulkError(false);
    }, 5000);
  }, [setBulkError]);

  const onSuccessCreateSegment = () => {
    setCreationState('success');
    drawInstance.deleteAll();
    resetPlaces();
  };

  const onErrorCreateSegment = () => {
    setCreationState('error');
  };

  const { mutate: onCreateSegment, isLoading: isLoadingCreate } = useCreateGeoAudience(
    onSuccessCreateSegment,
    onErrorCreateSegment
  );

  const creationAudienceSchema = Yup.object().shape({
    country: Yup.string().required(
      t({
        id: `GENERIC.LABEL.REQUIRED`
      })
    ),
    days: Yup.number().required(
      t({
        id: `GENERIC.LABEL.REQUIRED`
      })
    ),
    name: Yup.string()
      .min(
        3,
        t({
          id: `GEOTOOL.ACTIONPANEL.SEARCH.PLACEHOLDER`
        })
      )
      .max(
        25,
        t({
          id: `GEOTOOL.CREATIONPANEL.NAME.LONG`
        })
      )
      .required(
        t({
          id: `GENERIC.LABEL.REQUIRED`
        })
      ),
    description: Yup.string().max(
      100,
      t({
        id: `GEOTOOL.CREATIONPANEL.DESCRIPTION.LONG`
      })
    )
  });

  return (
    <Flex flexDirection="column" mx={4} width="100%" my="1rem">
      <Formik
        initialValues={{
          country: '',
          days: '',
          name: '',
          description: '',
          locations: []
        }}
        validationSchema={creationAudienceSchema}
        onSubmit={(values, formikBag) => {
          const reset = () => {
            formikBag.resetForm();
          };
          const clientId = currentOrganization?.clientId;
          onCreateSegment({
            name: values.name,
            description: values.description,
            locations,
            reset,
            clientId,
            country: values.country,
            days: values.days
          });
        }}
      >
        {(props: FormikProps<any>) => {
          return (
            <Form>
              <Flex my={2}>
                <Text size="bodyMedium">
                  {t({
                    id: `GENERIC.LABEL.NAME`
                  })}
                </Text>
              </Flex>
              <FormikInput
                name="name"
                placeholder={`${t({
                  id: 'GEOTOOL.PLACEHOLDER.NAME'
                })}`}
              />

              <Flex my={2}>
                <Text size="bodyMedium">
                  {t({
                    id: `GENERIC.LABEL.COUNTRY`
                  })}
                </Text>
              </Flex>
              <Field name="country">
                {({ field, form, meta }: any) => {
                  return (
                    <div>
                      <DropDown
                        name="country"
                        label=""
                        options={
                          countries !== undefined && countries.length > 1
                            ? countries
                            : [{ key: '1', label: 'no options', value: 1 }]
                        }
                        onChange={e => props.setValues({ ...props.values, country: e })}
                        onBlur={() => props.setTouched({ ...props.touched, country: true })}
                        value={meta.value}
                        error={meta?.touched === true && meta?.error !== undefined}
                        placeholder={`${t({
                          id: 'GEOTOOL.PLACEHOLDER.COUNTRY'
                        })}`}
                      />

                      <Box mt={2}>
                        <Text size="bodySmall" align="left" color="red">
                          <ErrorMessage name="country" />
                        </Text>
                      </Box>
                    </div>
                  );
                }}
              </Field>

              <Flex my={2}>
                <Text size="bodyMedium">
                  {t({
                    id: `GENERIC.LABEL.TIME.RANGE`
                  })}
                </Text>
              </Flex>

              <Field name="days">
                {({ field, form, meta }: any) => {
                  return (
                    <div>
                      <DropDown
                        name="days"
                        onChange={e => {
                          props.setValues({ ...props.values, days: e });
                        }}
                        onBlur={() => props.setTouched({ ...props.touched, days: true })}
                        value={meta.value}
                        label=""
                        options={[
                          {
                            label: `1 ${t({
                              id: `GENERIC.LABEL.MOTH`
                            })}`,
                            value: 30
                          },
                          {
                            label: `3 ${t({
                              id: `GENERIC.LABEL.MOTHS`
                            })}`,
                            value: 60
                          },
                          {
                            label: `6 ${t({
                              id: `GENERIC.LABEL.MOTHS`
                            })}`,
                            value: 180
                          },
                          {
                            label: `12 ${t({
                              id: `GENERIC.LABEL.MOTHS`
                            })}`,
                            value: 365
                          }
                        ]}
                        placeholder={`${t({
                          id: 'GEOTOOL.PLACEHOLDER.RANGE'
                        })}`}
                      />

                      <Box mt={2}>
                        <Text size="bodySmall" align="left" color="red">
                          <ErrorMessage name="days" />
                        </Text>
                      </Box>
                    </div>
                  );
                }}
              </Field>

              <Flex my={2}>
                <Text size="bodyMedium">
                  {t({
                    id: `GENERIC.LABEL.DESCRIPTION`
                  })}
                </Text>
              </Flex>
              <Field
                name="description"
                component={TextAreaInput}
                placeholder={`${t({
                  id: 'GEOTOOL.PLACEHOLDER.DESCRIPTION'
                })}`}
              />
              <Box mt={4}>
                {creationState === 'success' && (
                  <Alert
                    title={`${t({
                      id: 'GEOTOOL.CREATIONPANEL.SUCCESS.TITLE'
                    })}`}
                    status="success"
                    state={creationState === 'success' ? 'show' : 'hide'}
                    onClose={() => setCreationState(undefined)}
                  >
                    {t({
                      id: `GEOTOOL.CREATIONPANEL.SUCCESS.INFO`
                    })}
                  </Alert>
                )}
                {creationState === 'error' && (
                  <Alert
                    title={`${t({
                      id: 'GEOTOOL.CREATIONPANEL.ERROR.TITLE'
                    })}`}
                    status="warning"
                    state={creationState === 'error' ? 'show' : 'hide'}
                    onClose={() => setCreationState(undefined)}
                  >
                    {t({
                      id: `GEOTOOL.CREATIONPANEL.ERROR.INFO`
                    })}
                  </Alert>
                )}

                {bulkFileError === true && (
                  <Alert
                    title={`${t({
                      id: 'GEOTOOL.CREATIONPANEL.ERROR.TITLE'
                    })}`}
                    status="warning"
                    state={bulkFileError === true ? 'show' : 'hide'}
                    onClose={() => setBulkError(false)}
                  >
                    {t({
                      id: `GEOTOOL.CREATIONPANEL.FILE.ERROR`
                    })}
                  </Alert>
                )}
              </Box>
              <Flex mt={8} mb={4} justifyContent="end">
                <Box display="block">
                  <Button
                    variant="primary"
                    type="submit"
                    onClick={() => {}}
                    label="Create audience"
                    isLoading={isLoadingCreate}
                    disabled={locations.length === 0 || props.values.name === ''}
                  />
                </Box>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};

export default CreationForm;
