import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box, Icon, Chip, ChipIconRight, Tooltip } from '@retargetly/ui-components';
import { faXmark } from '@fortawesome/pro-duotone-svg-icons';

/**
 * Description: This component render the segment inside a group
 * @param { onClick  } Func;
 * @param { parentSegment } Object
 */

const ExpressionSegment = ({ onClick, segment, fullPath }) => {
  const title = segment && segment.name ? segment.name : 'generic';

  return (
    <Tooltip label={fullPath} hasArrow shouldWrapChildren placement="auto">
      <Box my={1} mr={2}>
        <Chip>
          <Text size="bodySmall" color="neutrals.600">
            {title}
          </Text>
          <ChipIconRight onClick={onClick}>
            <Icon icon={faXmark} name="xmark" size="sm" />
          </ChipIconRight>
        </Chip>
      </Box>
    </Tooltip>
  );
};

ExpressionSegment.propTypes = {
  segment: PropTypes.shape({ name: PropTypes.string, fullName: PropTypes.string }).isRequired,
  onClick: PropTypes.func.isRequired,
  fullPath: PropTypes.string
};

ExpressionSegment.defaultProps = {
  fullPath: ''
};

export default ExpressionSegment;
