import React from 'react';
import { Box } from '@retargetly/ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { Modal, Typography } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { getShareModal } from 'selectors';
import { toggleShareModal } from 'actions';
import { SAVE, SHARE } from 'constants/flows';
import ClientsStep from './ClientsStep';
import NameFolderStep from './NameFolderStep';
import { usePlanificationContext } from '../context';

const { Title } = Typography;

export const SaveShareSteps = () => {
  const {
    resetPlanification,
    isNewPlanification,
    planificationHasBeenSaved,
    planificationHasBeenShared,
    formState
  } = usePlanificationContext();
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const shareModal = useSelector(getShareModal);

  return (
    <Box>
      <Modal
        visible={shareModal}
        footer={null}
        maskClosable={false}
        cancelText={t({ id: 'PLANIFICATION.DISMISS' })}
        onCancel={() => dispatch(toggleShareModal(false))}
        afterClose={resetPlanification}
        destroyOnClose
        keyboard={false}
        style={{ width: '564px', height: 'auto' }}
      >
        {isNewPlanification && !planificationHasBeenSaved && formState.step === SAVE ? (
          <Title level={3}>
            {t({
              id: `PLANIFICATION.INFO.SAVE`
            })}
          </Title>
        ) : null}

        {planificationHasBeenSaved && !planificationHasBeenShared && formState.step === SAVE ? (
          <Title level={3}>
            {t({
              id: `PLANIFICATION.INFO.UPDATE`
            })}
          </Title>
        ) : null}

        {formState.step === SHARE ? (
          <Title level={3}>
            {t({
              id: `PLANIFICATION.SHARE`
            })}
          </Title>
        ) : null}

        {formState.step === SAVE ? <NameFolderStep /> : <ClientsStep />}
      </Modal>
    </Box>
  );
};
