import React, { FC } from 'react';
import { Flex, Box, Text, Center } from '@retargetly/ui-components';
import { MetaIcon, XanderIcon, AddManagerIcon } from 'svg';
import { numberLabelFormate } from 'utils/numberLabelFormate';
import { usLocalizeFormate } from 'utils/usLocalizeFormate';

type Props = {
  platformId: number;
  percentage: number;
  volume: number;
};

const PlatformStatics: FC<Props> = ({ platformId, percentage = 72, volume }) => {
  const localizeFormat = usLocalizeFormate.format(',.2f');

  const getPlatformIcon = () => {
    switch (platformId) {
      case 11: {
        return <AddManagerIcon size="sm" />;
      }
      case 25: {
        return <MetaIcon size="sm" />;
      }
      case 2: {
        return <XanderIcon size="sm" />;
      }
      default: {
        return <MetaIcon size="sm" />;
      }
    }
  };

  return (
    <Flex>
      <Center>
        <Box mr={1}>{getPlatformIcon()}</Box>
        <Text size="bodySmall">{numberLabelFormate(volume)}</Text>
        <Text size="bodySmall">({localizeFormat(percentage)} %)</Text>
      </Center>
    </Flex>
  );
};

export default PlatformStatics;
