import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Stack } from '@retargetly/ui-components';
import { ServiceFactory } from 'services/FactoryService';
import { loginFromToken } from 'actions';
import { Spin } from 'antd';
import { useCookies } from 'react-cookie';
import useQuery from 'hooks/useQuery';
import useAuth from 'hooks/useAuth';
import * as routes from 'constants/routes';

const authFactory = ServiceFactory.get('auth');

export function AuthSingleSignOnCallback() {
  const [, setCookie, removeCookie] = useCookies(['jwtToken', 'rtToken']);
  const parmas = useQuery();
  const dispatch = useDispatch();
  const history = useHistory();
  const { userAuth, token, refreshToken } = useAuth();

  const goToDashboard = () => {
    history.push(routes.HOME);
  };

  const onError = () => {
    removeCookie('jwtToken', {
      path: '/',
      domain: process.env.REACT_APP_API_DOMAIN,
      sameSite: false
    });
    removeCookie('rtToken', {
      path: '/',
      domain: process.env.REACT_APP_API_DOMAIN,
      sameSite: false
    });
    history.push(routes.WELCOME);
  };

  useEffect(() => {
    authFactory
      .authWithSingleSignOnCallback({ code: parmas.get('code'), org_name: null })
      .then((response: any) => {
        dispatch(loginFromToken(response.headers.at, response.headers.rt, undefined, onError));
      })
      .catch(() => history.push(routes.WELCOME));
  }, []);

  useEffect(() => {
    if (userAuth) {
      setCookie('jwtToken', token, {
        path: '/',
        domain: process.env.REACT_APP_API_DOMAIN
      });
      setCookie('rtToken', refreshToken, {
        path: '/',
        domain: process.env.REACT_APP_API_DOMAIN
      });
      goToDashboard();
    }
  }, [userAuth]);

  return (
    <Stack h="100vh" alignItems="center" justifyContent="center">
      <Spin />
    </Stack>
  );
}
