import repository from '../repository';
/* import { savedPlanifications } from '../mockedData'; */

const getOptions = () => {
  const token = localStorage.getItem('token');
  const rt = localStorage.getItem('rtToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt
    }
  };
};

export default {
  async getOrganizations() {
    const accounts = await repository.get('sondeo/clients/list?enabled=true', getOptions());
    return accounts.data?.response || [];
  }
};
