import React from 'react';
import mapboxgl from 'mapbox-gl';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import useAuth from 'hooks/useAuth';
import { AuthSingleSignOn } from 'components/Auth/AuthSingleSignOn';
import { AuthSingleSignOnCallback } from 'components/Auth/AuthSingleSignOnCallback';
import SSOlogout from 'components/Auth/SSOLogout.tsx';
import useGetAllThemes from 'hooks/themes/useGetAllThemes';
import DefaultSpinner from 'components/SplashSpinner';
import Auth from 'components/Auth/Auth';
import Welcome from 'components/Auth/Welcome';
import { RelyProvider } from './Providers/RelyProvider';
import { AlertVersion } from './components/AlertVersion/AlertVersion';
import * as Routes from './constants/routes';
import './styles/components/footer.scss';
import Home from './containers/Home/Home';
import Error404 from './containers/Error/Error404';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX;

const App = () => {
  const { isLoading } = useGetAllThemes();
  const { isLoggedIn } = useAuth();

  return (
    <RelyProvider>
      {process.env.REACT_APP_ENVIRONMENT === 'production' && <AlertVersion />}

      <Router>
        {/* if the theme are loading await to finish */}
        {isLoading ? (
          <Route path={Routes.LOADING} exact component={DefaultSpinner} />
        ) : (
          <Switch>
            <Route path={Routes.SINGLE_SIGN_ON_AUTH} exact component={AuthSingleSignOn} />
            <Route path={Routes.CALLBACK_AUTH} exact component={AuthSingleSignOnCallback} />
            <Route path={Routes.ERROR404} component={Error404} exact />
            <Route path={Routes.CALLBACK_LOGOUT} component={SSOlogout} exact />
            <Route path={Routes.AUTH} component={Auth} exact />
            <Route path={Routes.WELCOME} component={Welcome} />

            <Route
              render={({ location }) => {
                return isLoggedIn ? (
                  <Route path={Routes.HOME} component={Home} />
                ) : (
                  <Redirect to={Routes.AUTH} from={location.pathname} />
                );
              }}
            />
          </Switch>
        )}
      </Router>
    </RelyProvider>
  );
};
export default App;
