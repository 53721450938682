/* eslint-disable camelcase */
import React, { createRef } from 'react';
import { Box, CardHeader, Text, Card, Flex, CardContent } from '@retargetly/ui-components';
import { GenderResponse } from 'utils/interfaces/insights';
import { useMediaQuery } from '@material-ui/core';
import { colors, styleConfig } from 'utils/insightsGraphConfiguration';
import { InfoTooltip } from 'components/Ui';
import { useFormatMessage } from 'react-intl-hooks';
import Grid from '@material-ui/core/Grid';
import DownloadButton from 'components/Ui/DownloadButton/DownloadButton';
import transparentize from 'utils/transparentize';
import useEstimationMetrics from 'hooks/useEstimationMetrics';
import AgeGraph from '../Charts/AgeGraph';
import GenderGraph from '../Charts/GenderGraph';
import IdentifiersGraph from '../Charts/IdentifiersGraph';

const GENDER_KEY = 0;
const AGE_KEY = 1;

const AgeGenderAndIdentifiers = () => {
  const mediaQueryBreakPoint = useMediaQuery('(min-width:1500px)');

  const t = useFormatMessage();
  const refAge = createRef<HTMLDivElement>();
  const refGender = createRef<HTMLDivElement>();
  const refId = createRef<HTMLDivElement>();
  const transformDataTopCluster = (data: any) => {
    let hiddenPlanningGender = 0;
    let hiddenRetargetlyGender = 0;

    data.top_clusters[GENDER_KEY].segments.forEach((value: GenderResponse) => {
      hiddenPlanningGender += value.percent;
    });

    data.top_clusters[GENDER_KEY].segments.forEach((value: GenderResponse) => {
      hiddenRetargetlyGender += value.percent;
    });

    const genderDataSet = [
      {
        label: t({ id: 'INSIGHTS.TABLE.COMPARISON.PLANNING' }),
        backgroundColor: transparentize(colors[0], 0.5),
        data: data.top_clusters[GENDER_KEY].segments.map((item: any) => item.percent),
        hidden: hiddenPlanningGender === 0,
        ...styleConfig
      },
      {
        label: 'Retargetly',
        backgroundColor: transparentize(colors[1], 0.5),
        data: data.top_clusters[GENDER_KEY].segments.map((item: any) => item.idfPercent),
        hidden: hiddenRetargetlyGender === 0,
        ...styleConfig
      }
    ];

    const genderLabels = data.top_clusters[GENDER_KEY].segments.map((item: any) => item.segment_name);

    const ageData = data.top_clusters[AGE_KEY].segments;

    let hiddenPlanningAge = 0;
    let hiddenRetargetlyAge = 0;

    ageData.forEach((value: GenderResponse) => {
      hiddenPlanningAge += value.percent;
    });

    ageData.forEach((value: GenderResponse) => {
      hiddenRetargetlyAge += value.percent;
    });

    const ageLabels = ageData.map((item: any) => item.segment_name);

    const ageDataSet = [
      {
        label: t({ id: 'INSIGHTS.TABLE.COMPARISON.PLANNING' }),
        backgroundColor: transparentize(colors[0], 0.5),
        data: ageData.map((item: any) => item.percent),
        hidden: hiddenPlanningAge === 0,
        ...styleConfig
      },
      {
        label: 'Retargetly',
        backgroundColor: transparentize(colors[1], 0.5),
        data: ageData.map((item: any) => item.idfPercent),
        hidden: hiddenRetargetlyAge === 0,
        ...styleConfig
      }
    ];

    const genderDataSetR = hiddenPlanningGender === 0 && hiddenRetargetlyGender === 0 ? undefined : genderDataSet;

    const ageDataSetR = hiddenPlanningAge === 0 && hiddenRetargetlyAge === 0 ? undefined : ageDataSet;

    return { genderDataSet: genderDataSetR, genderLabels, ageDataSet: ageDataSetR, ageLabels };
  };

  const { data: topClusterData, isLoading: isLoadingCluster, isError: isErrorTopCluster } = useEstimationMetrics({
    metrics: ['top_clusters'],
    transformData: transformDataTopCluster
  });

  return (
    <Flex flexDirection="column" gap={4} px={4}>
      <div ref={refAge}>
        <Card height="auto" width="100%">
          <CardHeader bgColor="white">
            <Flex flexDirection="row" alignItems="center">
              <Flex flexDirection="row" alignItems="center" flexGrow={1}>
                <Box flex>
                  <Text size="bodyBold">{t({ id: 'INSIGHTS.AGE.DISTRIBUTION' })}</Text>
                </Box>
                <Box pl={2}>
                  <InfoTooltip text={`${t({ id: 'INSIGHTS.AGE.DISTRIBUTION.TOOLTIP' })}`} />
                </Box>
              </Flex>
              <DownloadButton svgRef={refAge} name={`${t({ id: 'INSIGHTS.AGE.DISTRIBUTION' })}`} />
            </Flex>
          </CardHeader>
          <CardContent>
            <Flex flexDirection="column" alignItems="center" h="auto" width="100%">
              <GenderGraph
                dataSet={topClusterData?.ageDataSet}
                labels={topClusterData?.ageLabels}
                isError={isErrorTopCluster}
                isLoading={isLoadingCluster}
              />
            </Flex>
          </CardContent>
        </Card>
      </div>

      <Grid container spacing={3}>
        <Grid item xs={mediaQueryBreakPoint ? 6 : 12}>
          <div ref={refGender}>
            <Card height="auto" width="100%">
              <CardHeader bgColor="white">
                <Flex flexDirection="row" alignItems="center">
                  <Flex flexDirection="row" alignItems="center" flexGrow={1}>
                    <Box flex>
                      <Text size="bodyBold">{t({ id: 'INSIGHTS.GENDER.DISTRIBUTION' })}</Text>
                    </Box>
                    <Box pl={2}>
                      <InfoTooltip text={`${t({ id: 'INSIGHTS.AGE.DISTRIBUTION.TOOLTIP' })}`} />
                    </Box>
                  </Flex>
                  <DownloadButton svgRef={refGender} name={`${t({ id: 'INSIGHTS.AGE.DISTRIBUTION' })}`} />
                </Flex>
              </CardHeader>
              <CardContent>
                <Flex flexDirection="column" alignItems="center" h="auto" width="100%">
                  <AgeGraph
                    dataSet={topClusterData?.genderDataSet}
                    labels={topClusterData?.genderLabels}
                    isError={isErrorTopCluster}
                    isLoading={isLoadingCluster}
                  />
                </Flex>
              </CardContent>
            </Card>
          </div>
        </Grid>
        <Grid item xs={mediaQueryBreakPoint ? 6 : 12}>
          <div ref={refId}>
            <Card height="auto" width="100%">
              <CardHeader bgColor="white">
                <Flex flexDirection="row" alignItems="center">
                  <Flex flexDirection="row" alignItems="center" flexGrow={1}>
                    <Box flex>
                      <Text size="bodyBold">{t({ id: 'INSIGHTS.IDENTIFIERS' })}</Text>
                    </Box>
                    <Box pl={2}>
                      <InfoTooltip text={`${t({ id: 'INSIGHTS.IDENTIFIERS.TOOLTIP' })}`} />
                    </Box>
                  </Flex>
                  <DownloadButton svgRef={refId} name={`${t({ id: 'INSIGHTS.IDENTIFIERS' })}`} />
                </Flex>
              </CardHeader>
              <CardContent>
                <IdentifiersGraph />
              </CardContent>
            </Card>
          </div>
        </Grid>
      </Grid>
    </Flex>
  );
};

export default AgeGenderAndIdentifiers;
