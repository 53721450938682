import { useQuery } from '@tanstack/react-query';
import { ServiceFactory } from '../../services/FactoryService';

const geoServices = ServiceFactory.get('geo');

const getCountries = async (): Promise<any> => {
  return geoServices.getGeoCountries();
};

export const useGetGeoCountries = () => {
  return useQuery(['geoCountries'], getCountries, {
    select: data => {
      const mutateResponse = data.map((item: any) => {
        return {
          // ...item,
          key: item.code,
          value: item.code,
          label: item.country
        };
      });
      return mutateResponse;
    },
    onError: (error: any) => {
      return error;
    }
  });
};

export default useGetGeoCountries;
