import React from 'react';
import { CircularProgress, Text, Box, Flex } from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';

const Loading = () => {
  const t = useFormatMessage();
  return (
    <Flex flexDirection="column" minHeight="270px" alignItems="center" justifyContent="center" w="100%">
      <Box textAlign="center" pb="12px">
        <CircularProgress color="primary" value={0} label={false} size="16px" />
      </Box>
      <Box textAlign="center" width="100%">
        <Text size="bodyDefault">{`${t({ id: 'GENERIC.INFO.LOADING' })}`}</Text>
      </Box>
    </Flex>
  );
};

export default Loading;
