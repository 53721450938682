import React, { useCallback, useMemo } from 'react';
import { faCloudArrowUp } from '@fortawesome/pro-duotone-svg-icons';
import { useDropzone } from 'react-dropzone';
import { Slider, Text, Flex, Box, Icon } from '@retargetly/ui-components';
import { Divider } from 'antd';
import { useLocationContext } from 'components/GeoTool/context/Locations/LocationProvider';
import Papa from 'papaparse';
import { useMapContext } from 'components/GeoTool/context/Map/MapProvider';
import { useFormatMessage } from 'react-intl-hooks';

const baseStyle = {
  flex: 1,
  height: '130px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  borderWidth: 1,
  borderRadius: 2,
  borderColor: '#5CA5E6',
  borderStyle: 'dashed',
  backgroundColor: 'transparent',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const activeStyle = {
  borderColor: '#FE0C5D'
};

const acceptStyle = {
  borderColor: '#FE0C5D'
};

const rejectStyle = {
  borderColor: '#7B7B7B'
};

const BulkTool = () => {
  const { radius, setRadius } = useLocationContext();
  const { addByBulk } = useMapContext();
  const t = useFormatMessage();

  const onDrop = useCallback(
    acceptedFiles => {
      acceptedFiles.forEach((file: any) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: results => {
            addByBulk(results.data);
          }
        });
      });
    },
    [addByBulk]
  );

  const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject } = useDropzone({
    onDrop,
    // @ts-ignore
    accept:
      '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
    maxFiles: 1,
    maxSize: 1000000000
  });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {})
    }),
    [isDragActive, isDragReject, isDragAccept]
  );

  return (
    <Box>
      <Box mb={2}>
        <Text size="bodyBold" align="left">
          {t({
            id: `GEOTOOL.ACTIONPANEL.BULK.TITLE`
          })}
        </Text>
      </Box>
      <Box>
        <Text size="bodySmall" align="left">
          {t({
            id: `GEOTOOL.ACTIONPANEL.BULK.DESCRIPTION`
          })}
        </Text>
      </Box>
      <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
      <Box>
        <Box mb={2}>
          <Text size="bodyBold" align="left">
            {t({
              id: `GEOTOOL.ACTIONPANEL.BULK.IMPORT`
            })}
          </Text>
        </Box>
        <div {...getRootProps({ style })}>
          <input {...getInputProps()} />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <Flex flexDirection="column">
              <Box>
                <Icon icon={faCloudArrowUp} name="grid" color="blue.500" size="md" />
              </Box>
              <Box>
                <p>
                  {t({
                    id: `GEOTOOL.ACTIONPANEL.BULK.IMPORT.DROP`
                  })}
                </p>
              </Box>
            </Flex>
          )}
        </div>
      </Box>
      <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
      <Flex flexDirection="column" height="auto">
        <Box my={2}>
          <Text size="bodyBold" align="left">
            {t({
              id: `GENERIC.LABEL.RADIUS`
            })}
          </Text>
        </Box>
        <Box>
          <Text size="bodySmall" align="left">
            {t({
              id: `GEOTOOL.ACTIONPANEL.PINTOOL.RADIUS.DESCRIPTION`
            })}
          </Text>
        </Box>
        <Flex height="50px">
          <Slider
            defaultValue={radius}
            min={100}
            max={10000}
            step={50}
            label="m"
            sliderValue={radius}
            setSliderValue={setRadius}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default BulkTool;
