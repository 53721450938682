import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import { ServiceFactory } from 'services/FactoryService';
import { getCountrySelected } from '../selectors';
import { setCountrySelected } from '../actions';

const services = ServiceFactory.get('segments');

export const GET_COUNTRIES_QUERY_KEY = 'countries';

const useCountries = () => {
  const dispatch = useDispatch();

  const onSetCountryFilter = (country: string) => dispatch(setCountrySelected(country));
  const countrySelected = useSelector(getCountrySelected);

  const { isLoading, error, data } = useQuery([GET_COUNTRIES_QUERY_KEY], () => services.getCountries(), {
    staleTime: Infinity
  });

  return {
    onSetCountryFilter,
    isLoading,
    error,
    data,
    countrySelected
  };
};

export default useCountries;
