import { Dispatch } from 'redux';
import { Organization } from 'utils/interfaces';
import * as actions from '../../constants/actionTypes';
import { ServiceFactory } from '../../services/FactoryService';

const service = ServiceFactory.get('organization');

export const setCurrentOrganization = (organization?: Organization) => {
  return {
    type: actions.SET_CURRENT_ORGANIZATION,
    payload: organization
  };
};

export const setOrganizations = (accounts: Organization[]) => {
  return {
    type: actions.GET_ORGANIZATIONS,
    payload: accounts
  };
};

export const loadingOrganizations = (loading: boolean) => {
  return {
    type: actions.LOADING_ORGANIZATIONS,
    payload: loading
  };
};

export const getOrganizations = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(loadingOrganizations(true));
      const organizations = await service.getOrganizations();
      dispatch(setOrganizations(organizations));
      dispatch(loadingOrganizations(false));
      return organizations;
    } catch (error) {
      console.error('[Planning Action Error Get Clients]', error);
      return Promise.reject(new Error(`${error}`));
    }
  };
};

export const clearCurrentOrganization = () => {
  return {
    type: actions.CLEAR_CURRENT_ORGANIZATION
  };
};
