import { useEffect } from 'react';
import useTheme from 'hooks/themes/useTheme';

declare global {
  interface Window {
    less: any;
  }
}

const updateThemeColor = (primaryColor: string, secondaryColor: string) => {
  document.documentElement.style.setProperty('--primary-color', primaryColor);
  document.documentElement.style.setProperty('--secondary-color', secondaryColor);
  // eslint-disable-next-line no-unused-expressions
  window?.less
    ?.modifyVars({
      '@primary-color': primaryColor,
      '@secondary-color': secondaryColor
    })
    .then(() => {})
    .catch((error: any) => {
      console.error(error);
    });
};

const AntThemeProvider: React.FC = () => {
  const { getThemeProp } = useTheme();
  const primaryColor = getThemeProp('secondary_color');
  const secondaryColor = getThemeProp('primary_color');

  useEffect(() => {
    updateThemeColor(primaryColor, secondaryColor);
  }, [primaryColor, secondaryColor]);

  return null;
};

export default AntThemeProvider;
