import React, { useState } from 'react';
import { faUser } from '@fortawesome/pro-duotone-svg-icons';
import { DropDownButtonPrimary, Icon } from '@retargetly/ui-components';
import useAuth from '../../../hooks/useAuth';
import { Container } from './components/Container';

export const UserSettings = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { user } = useAuth();

  return (
    <DropDownButtonPrimary
      value={user.name}
      leftIcon={<Icon icon={faUser} name="user" className="svgPrimary" />}
      open={open}
      setOpen={val => setOpen(val)}
    >
      <Container user={user} setOpen={setOpen} />
    </DropDownButtonPrimary>
  );
};
