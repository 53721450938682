export const calculatePercentage = (total: number, partial: number, decimalPlaces: number = 1) => {
  if (total === 0) {
    return total;
  }

  const percentage = (partial * 100) / total;

  if (percentage === 0) {
    return 0;
  }

  return percentage.toFixed(decimalPlaces);
};
