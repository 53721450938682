import useCountries from 'hooks/useCountriesFilter';
import { useContext, useState } from 'react';
import useQuery from 'hooks/useQuery';
import { useGetIaSearch } from 'components/Search/hooks/useGetIaSearch';
import useEventTracker from 'hooks/useEventTracker';
import { useHistory } from 'react-router-dom';
import { REGULAR_KEY } from 'components/Search/hooks/useGetRegularSearch';
import { useQueryClient } from '@tanstack/react-query';
import { SEARCH } from 'constants/routes';
import { useFeatureFlag } from 'hooks/featureflag';
import { TaxonomyContext } from './index';
import { Segment } from '../components/TaxonomyItem';

export const useTaxonomyContextSetup = () => {
  const { trackEvent } = useEventTracker();
  const { hasDefaultAi } = useFeatureFlag();
  const { countrySelected } = useCountries();
  const [selectedListItem, setSelectedListItem] = useState<Segment>({} as Segment);
  const queryURL = useQuery();
  const [queryToShow, setQueryToShow] = useState<string | null>(queryURL.get('q') ? queryURL.get('q') : '');
  const { mutate: getSegments, isLoading, data: searchIaResults, isError: isIaError, reset } = useGetIaSearch();
  const [historyBreadcrumb, setHistoryBreadcrumb] = useState<Array<Segment>>([]);
  const [showError, setShowError] = useState(false);
  const [valueIa, setValueIa] = useState<string>('');
  const { push } = useHistory();
  const queryClient = useQueryClient();
  const [useIaSearch, setUseIaSearch] = useState(hasDefaultAi);

  const handleSetSelectedListItem = (segment: Segment) => {
    setSelectedListItem(segment);
    setHistoryBreadcrumb(prev => [...prev, segment]);
  };

  const handleListItemClick = (item: Segment | null) => {
    setQueryToShow('');
    setValueIa('');
    setUseIaSearch(false);
    queryClient.removeQueries({ queryKey: [REGULAR_KEY] });
    reset();
    setHistoryBreadcrumb([]);
    handleSetSelectedListItem(item as Segment);
  };

  const jumpToBreadcrumb = (segment: Segment) => {
    const idx = historyBreadcrumb.findIndex(value => value.id === segment.id);
    setSelectedListItem(historyBreadcrumb[idx]);
    const breadcrumbs = historyBreadcrumb.slice(0, idx + 1);
    setHistoryBreadcrumb(breadcrumbs);
  };

  const handleIaSearch = () => {
    if (valueIa.split(' ').length < 5) {
      setShowError(true);
    }
    if (countrySelected) {
      setQueryToShow('');
      push(SEARCH);
      queryClient.removeQueries({ queryKey: [REGULAR_KEY] });
      handleSetSelectedListItem({} as Segment);
      setHistoryBreadcrumb([]);
      getSegments({ query: valueIa, country: countrySelected });
      trackEvent('GPT_SEARCH', {});
    }
  };

  return {
    countrySelected,
    selectedListItem,
    handleListItemClick,
    queryToShow,
    setQueryToShow,
    setSelectedListItem,
    valueIa,
    setValueIa,
    handleIaSearch,
    getSegments,
    searchIaResults,
    isLoading,
    isIaError,
    showError,
    setShowError,
    handleSetSelectedListItem,
    historyBreadcrumb,
    jumpToBreadcrumb,
    reset,
    useIaSearch,
    setUseIaSearch
  };
};

export type TaxonomyContextProps = ReturnType<typeof useTaxonomyContextSetup>;

export const useTaxonomyContext = () => {
  const context = useContext(TaxonomyContext);
  if (context === undefined) {
    throw Error('useTaxonomyContext should be inside a TaxonomyProvider');
  }
  return context;
};
