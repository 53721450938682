import React from 'react';
import { Box, Button, Icon, Text } from '@retargetly/ui-components';
import { faArrowUpFromBracket, faMapPin, faLocationCrosshairs, faHand } from '@fortawesome/pro-duotone-svg-icons';
import { useMapContext } from 'components/GeoTool/context/Map/MapProvider';
import { useLocationContext } from 'components/GeoTool/context/Locations/LocationProvider';
import { useFormatMessage } from 'react-intl-hooks';

const ToolBox = () => {
  const { toolSelected, setTool, drawInstance } = useMapContext();
  const { radius } = useLocationContext();
  const t = useFormatMessage();

  return (
    <Box
      position="absolute"
      top="60px"
      left="0px"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      width="62px"
      height="260px"
      backgroundColor="white"
      borderRadius="5px"
      boxShadow="0 0 5px 0 rgba(0, 0, 0, 0.2)"
      zIndex={100}
      marginLeft="32px"
      paddingTop="10px"
      paddingBottom="10px"
    >
      <Button
        icon={<Icon icon={faMapPin} name="check" size="sm" />}
        active={toolSelected === 'pin'}
        onClick={() => {
          setTool('pin');
          drawInstance.changeMode('draw_circle', { initialRadiusInKm: radius });
        }}
        variant="ghost"
      />
      <Text size="bodyDefault" color="black">
        Pin
      </Text>
      <Button
        icon={<Icon icon={faLocationCrosshairs} name="check" size="sm" />}
        active={toolSelected === 'latlong'}
        onClick={() => {
          drawInstance.changeMode('static');
          setTool('latlong');
        }}
        variant="ghost"
      />
      <Text size="bodyDefault" color="black">
        LatLong
      </Text>
      <Button
        icon={<Icon icon={faArrowUpFromBracket} name="check" size="sm" />}
        active={toolSelected === 'bulk'}
        onClick={() => {
          drawInstance.changeMode('static');
          setTool('bulk');
        }}
        variant="ghost"
      />
      <Text size="bodyDefault" color="black">
        Bulk
      </Text>
      <Button
        icon={<Icon icon={faHand} name="check" size="sm" />}
        active={toolSelected === 'hand'}
        onClick={() => {
          drawInstance.changeMode('static');
          setTool('hand');
        }}
        variant="ghost"
      />
      <Text size="bodyDefault" color="black">
        {t({
          id: `GEOTOOL.TOOLBOX.MOVE`
        })}
      </Text>
    </Box>
  );
};

export default ToolBox;
