import * as actions from '../../constants/actionTypes';
import storeConfig from '../../store/store';

import { ServiceFactory } from '../../services/FactoryService';

const service = ServiceFactory.get('insights');
const { store } = storeConfig();

export const loadEstimations = estimations => {
  return {
    type: actions.LOAD_ESTIMATIONS,
    payload: estimations
  };
};

export const loadTableEstimations = estimations => {
  return {
    type: actions.LOAD_TABLE_ESTIMATIONS,
    payload: estimations
  };
};

export const loadBotData = payload => {
  return {
    type: actions.LOAD_BOT_DATA,
    payload
  };
};

export const getTableEstimations = payload => {
  return async dispatch => {
    const countries = store.getState().segments.countriesFilter;
    const payloadWithCountriesFilter = { ...payload, countries };
    try {
      const estimations = await service.getEstimations(payloadWithCountriesFilter);
      dispatch(loadTableEstimations(estimations));
    } catch (error) {
      // TODO: Error Handling here
      console.log(error);
    }
  };
};

export const getEstimations = payload => {
  return async dispatch => {
    const countries = store.getState().segments.countriesFilter;
    const payloadWithCountriesFilter = { ...payload, countries };
    try {
      const estimations = await service.getEstimations(payloadWithCountriesFilter);
      dispatch(loadEstimations(estimations));
    } catch (error) {
      // TODO: Error Handling here
      console.log(error);
    }
  };
};

export const getBotData = payload => {
  return async dispatch => {
    const countries = store.getState().segments.countriesFilter;
    // TODO - faltaria sumar al key el array de countries, no se si es necesario
    const botTimestamp = JSON.stringify(store.getState().segments.expression); // -${countries.join('-')}
    const payloadWithCountriesFilter = { ...payload, countries };
    try {
      const estimations = await service.getEstimations(payloadWithCountriesFilter);
      if (estimations.errors) {
        // clear avatar data when error is rcived
        dispatch(loadBotData({ avatar_bot: {} }));
      } else {
        dispatch(loadBotData({ ...estimations.avatar_bot, botTimestamp }));
      }
    } catch (error) {
      // TODO: Error Handling here
      console.log(error);
    }
  };
};

export const setAvatarProps = payload => {
  return {
    type: actions.SET_AVATAR_PROPS,
    payload
  };
};
