import { Box, Heading, OnboardingModal, Stack, Text } from '@retargetly/ui-components';
import { CDN_URL } from 'constants/config';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { useFeatureFlag } from 'hooks/featureflag';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../../actions/auth/index';
import * as selectors from '../../selectors';

export function ModalReDesing() {
  const t = useFormatMessage();
  const dispatch = useDispatch();
  const { showNewUIModal, everHiddenModalReDesing } = useSelector(selectors.getModalsUI);
  const { redesingModal } = useFeatureFlag();

  return (
    <OnboardingModal
      body={
        <Stack gap={3} alignItems="center" justifyContent="center">
          <Heading size="300" color="grey.500">
            {t({ id: 'AUTH.MODAL.REDESING.TITLE' })}{' '}
          </Heading>
          <Stack flexDirection="row" gap={4}>
            <img width={60} src={`${CDN_URL}/images/loop/control_loop.svg`} alt="control_loop" />
            <Box textAlign="left">
              <Text size="bodyBold" color="grey.500">
                {t({ id: 'AUTH.MODAL.REDESING.CONTROL_TITLE' })}
              </Text>
              <Text size="bodyDefault" color="grey.500">
                {t({ id: 'AUTH.MODAL.REDESING.CONTROL_DESCRIPTION' })}
              </Text>
            </Box>
          </Stack>
          <Stack flexDirection="row" gap={4}>
            <img width={60} src={`${CDN_URL}/images/loop/graphic_loop.svg`} alt="graphic_loop" />
            <Box textAlign="left">
              <Text size="bodyBold" color="grey.500">
                {t({ id: 'AUTH.MODAL.REDESING.GRAPHIC_TITLE' })}
              </Text>
              <Text size="bodyDefault" color="grey.500">
                {t({ id: 'AUTH.MODAL.REDESING.GRAPHIC_DESCRIPTION' })}
              </Text>
            </Box>
          </Stack>
          <Stack flexDirection="row" gap={4}>
            <img width={60} src={`${CDN_URL}/images/loop/navigation_loop.svg`} alt="navigation_loop" />
            <Box textAlign="left">
              <Text size="bodyBold" color="grey.500">
                {t({ id: 'AUTH.MODAL.REDESING.NAVIGATION_TITLE' })}
              </Text>
              <Text size="bodyDefault" color="grey.500">
                {t({ id: 'AUTH.MODAL.REDESING.NAVIGATION_DESCRIPTION' })}
              </Text>
            </Box>
          </Stack>
        </Stack>
      }
      primaryLabel={t({ id: 'AUTH.MODAL_ONBOARDING.UNDERSTAND' })}
      primaryAction={() => {
        dispatch(actions.everHiddenModalUI());
      }}
      onClose={() => dispatch(actions.hiddenUIModals())}
      isOpen={showNewUIModal && !everHiddenModalReDesing && redesingModal}
    />
  );
}

export default ModalReDesing;
