import React, { FC } from 'react';
import { BrandIcons } from 'utils/interfaces';

const getSize = (size: BrandIcons['size']) => {
  switch (size) {
    case 'sm': {
      return {
        width: '20px',
        height: '14px'
      };
    }
    case 'md': {
      return {
        width: '30px',
        height: '24px'
      };
    }
    case 'lg': {
      return {
        width: '40px',
        height: '28px'
      };
    }
    default: {
      return {
        width: '20px',
        height: '14px'
      };
    }
  }
};

const MetaIcon: FC<BrandIcons> = ({ size = 'sm' }) => {
  const { width, height } = getSize(size);

  return (
    <svg width={width} height={height} viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group 1078">
        <path
          id="Vector"
          d="M2.16023 8.76059C2.16023 9.52429 2.32778 10.1105 2.54693 10.4653C2.8342 10.9299 3.26263 11.1271 3.69943 11.1271C4.26273 11.1271 4.77812 10.9873 5.77127 9.61359C6.56714 8.51262 7.5045 6.96722 8.13533 5.99836L9.20363 4.35695C9.94571 3.21702 10.8047 1.94984 11.7895 1.09089C12.5938 0.389776 13.4608 0.000305176 14.3337 0.000305176C15.7992 0.000305176 17.195 0.849534 18.2633 2.44227C19.4325 4.18655 20 6.38373 20 8.65102C20 9.99896 19.7343 10.9893 19.2822 11.7718C18.8455 12.5285 17.9942 13.2845 16.5621 13.2845V11.1267C17.7883 11.1267 18.0943 9.99996 18.0943 8.7105C18.0943 6.87297 17.6658 4.8338 16.722 3.3767C16.0523 2.34316 15.1843 1.71166 14.2294 1.71166C13.1965 1.71166 12.3654 2.49077 11.4313 3.87952C10.9347 4.61749 10.4249 5.51674 9.85247 6.5315L9.22231 7.64781C7.95663 9.89257 7.63594 10.4036 7.00302 11.247C5.89375 12.7242 4.94642 13.2842 3.69943 13.2842C2.22004 13.2842 1.28461 12.6436 0.70522 11.6782C0.232309 10.8917 0 9.85947 0 8.68344L2.16023 8.76059Z"
          fill="#0081FB"
        />
        <path
          id="Vector_2"
          d="M1.70337 2.59427C2.69376 1.06763 4.12305 0 5.76236 0C6.7117 0 7.65568 0.280982 8.64105 1.08573C9.71906 1.96537 10.868 3.41468 12.3015 5.80227L12.8155 6.65887C14.0563 8.72594 14.7622 9.78938 15.1753 10.2909C15.7066 10.9348 16.0788 11.1269 16.5622 11.1269C17.7884 11.1269 18.0943 10.0001 18.0943 8.71061L20 8.6508C20 9.99874 19.7344 10.9891 19.2823 11.7716C18.8455 12.5282 17.9942 13.2843 16.5622 13.2843C15.6719 13.2843 14.8832 13.091 14.011 12.2681C13.3408 11.6366 12.5567 10.5148 11.9537 9.50631L10.16 6.51009C9.26006 5.00641 8.4342 3.88524 7.95669 3.37748C7.44264 2.83143 6.78191 2.17212 5.72751 2.17212C4.87418 2.17212 4.14944 2.77095 3.54299 3.68695L1.70337 2.59427Z"
          fill="url(#paint0_linear_5532_13081)"
        />
        <path
          id="Vector_3"
          d="M5.72746 2.17212C4.87413 2.17212 4.14939 2.77095 3.54294 3.68695C2.68533 4.98127 2.16065 6.90919 2.16065 8.76062C2.16065 9.52432 2.3282 10.1106 2.54735 10.4653L0.70522 11.6783C0.232309 10.8917 0 9.8595 0 8.68347C0 6.54477 0.587013 4.31568 1.70332 2.59427C2.69371 1.06763 4.123 0 5.76231 0L5.72746 2.17212Z"
          fill="url(#paint1_linear_5532_13081)"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_5532_13081"
          x1="4.33559"
          y1="6.29789"
          x2="18.1066"
          y2="6.99339"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0064E1" />
          <stop offset="0.4" stopColor="#0064E1" />
          <stop offset="0.83" stopColor="#0073EE" />
          <stop offset="1" stopColor="#0082FB" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5532_13081"
          x1="2.88111"
          y1="9.66758"
          x2="2.88111"
          y2="4.59038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0082FB" />
          <stop offset="1" stopColor="#0064E0" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default MetaIcon;
