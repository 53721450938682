import repository from '../repository';

const getOptions = () => {
  const token = localStorage.getItem('token');
  const rt = localStorage.getItem('rtToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt
    }
  };
};

export default {
  async getSegmentsList(country) {
    let url = 'sondeo/v2/segment/list';

    if (country) {
      url += `?country=${Array.isArray(country) ? country.join() : country}`;
    }
    const response = await repository.get(url, getOptions());
    return response?.data?.response;
  },
  async getSegmentsSearch(searchTerm, country) {
    let url = `sondeo/v2/search?q=${searchTerm}`;

    if (country) {
      url += `&country=${Array.isArray(country) ? country.join() : country}`;
    }
    const searchResults = await repository.get(url, getOptions());
    return searchResults;
  },
  async getSegmentsByIa(query, country) {
    const segments = await repository.post(`sondeo/v2/brief`, { query, country }, getOptions());
    return segments;
  },
  async getCountries() {
    const response = await repository.get('countries?feature=planner', getOptions());
    return response.data.response;
  }
};
