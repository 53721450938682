import React, { FC, useState } from 'react';
import { IconChip, Icon, ProfileSvg, Span, Text, Heading } from '@retargetly/ui-components';
import { faEnvelope } from '@fortawesome/pro-solid-svg-icons';
import { useLogout } from 'components/Auth/hooks/useLogout';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { clearCurrentOrganization } from 'actions/organizations/index';
import { useHistory } from 'react-router-dom';
import LogOutModal from 'components/Auth/LogOutModal';
import useAuth from 'hooks/useAuth';

const UserInfo: FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const { name, email, internal_user_id: id } = user;
  const dispatch = useDispatch();
  const [, , removeCookie] = useCookies(['jwtToken', 'rtToken']);

  const onSuccessLogout = (redirect?: string) => {
    dispatch(clearCurrentOrganization());
    removeCookie('jwtToken', {
      path: '/',
      domain: process.env.REACT_APP_API_DOMAIN
    });
    removeCookie('rtToken', {
      path: '/',
      domain: process.env.REACT_APP_API_DOMAIN
    });
    if (redirect) {
      window.location.href = redirect;
    } else {
      history.push('/');
    }
  };

  const { mutate, isLoading } = useLogout(onSuccessLogout, onSuccessLogout);

  const handleClose = () => {
    setShowModal(false);
  };

  const logoutClick = () => {
    setShowModal(true);
  };

  const handleLogoutConfirm = async () => {
    mutate();
  };

  return (
    <div className="d-flex flex-row">
      <div className="d-flex flex-column">
        <IconChip ico={<ProfileSvg />} ariaLabel="icon-button" height="73px" width="73px" />
        <button
          type="button"
          className="d-flex justify-content-center align-items-center mt-1 is"
          onClick={logoutClick}
        >
          <Span color="blue" textDecoration="underline" fontSize="12px" fontWeight="bold">
            Log Out
          </Span>
        </button>
      </div>
      <LogOutModal
        showModal={showModal}
        handleClose={handleClose}
        handleConfirm={handleLogoutConfirm}
        isLoading={isLoading}
      />
      <div className="d-flex flex-column ms-3">
        <Heading size="100" color="text.500" dataTestid="heading">
          {name}
        </Heading>
        <Text size="bodySmall" color="text.400">
          ID: {id}
        </Text>
        <div className="d-flex d-column align-items-center mt-4">
          <div className="me-2">
            <Icon icon={faEnvelope} name="envelope" size="sm" color="primary.500" />
          </div>
          <div className="mt-1">
            <Text size="bodySmall" color="text.500">
              {email}
            </Text>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserInfo;
