import TagManager from 'react-gtm-module';

const useCustomWindowEvent = () => {
  /**
   * @description adds custom event to dataLayer extended windows object
   * @param eventName {string}
   */
  const pushEventForAnalytics = (eventName: string) => {
    if (process.env.REACT_APP_ENVIRONMENT === 'production') {
      TagManager.dataLayer({
        dataLayer: {
          event: `${eventName}`
        }
      });
    }
  };

  return {
    pushEventForAnalytics
  };
};

export default useCustomWindowEvent;
