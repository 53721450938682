import React, { FC } from 'react';
import { Icon, Card, Flex, Box, Text, Center, IconChip } from '@retargetly/ui-components';
import { faMobile, faLaptop } from '@fortawesome/pro-duotone-svg-icons';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { Skeleton } from 'antd';
import { numberLabelFormate } from 'utils/numberLabelFormate';
import { useMediaQuery } from '@material-ui/core';
import { useFormatMessage } from 'react-intl-hooks';

type Props = {
  mobileCookies: number;
  desktopCookies: number;
  androidDevices: number;
  iosDevices: number;
  isLoading: boolean;
  isError: boolean;
};

const MetricsCard: FC<Props> = ({ mobileCookies, desktopCookies, androidDevices, iosDevices, isLoading, isError }) => {
  const matches = useMediaQuery('(min-width:1541px)');
  const t = useFormatMessage();
  return (
    <Center flexGrow={1}>
      <Card height={matches ? '115px' : 'auto'} width="100%" padding={4}>
        {isError && <>error</>}

        {isLoading && !isError && (
          <Skeleton
            active
            title={false}
            paragraph={{
              rows: 2,
              width: 100
            }}
          />
        )}

        {mobileCookies !== undefined &&
          desktopCookies !== undefined &&
          androidDevices !== undefined &&
          iosDevices !== undefined &&
          !isError && (
            <Flex flexDirection="column" h="100%">
              <Box>
                <Text size="bodyBold" align="left">
                  {t({ id: 'INSIGHTS.METRICS' })}
                </Text>
              </Box>
              <Flex alignItems="center" mt={2} flexGrow={1}>
                <Box pr={4}>
                  <Text size="caption" align="left">
                    COOKIES
                  </Text>

                  <Flex
                    flexDirection="row"
                    alignItems="center"
                    h="auto"
                    flexWrap="wrap"
                    justifyContent="left"
                    mt={1}
                    gap={1}
                  >
                    <Flex flexDirection="row" alignItems="center" h="100%">
                      <Box mr={1}>
                        <IconChip
                          height="25px"
                          width="25px"
                          ico={<Icon icon={faMobile} color="info.500" size="xsm" name="mobile" />}
                          ariaLabel="icon-button"
                        />
                      </Box>
                      <Box mr={1}>
                        <Text size="bodySmallBold">{t({ id: 'INSIGHTS.METRICS.MOBILE' })}</Text>
                      </Box>
                      <Box>
                        <Text size="bodySmall">{numberLabelFormate(mobileCookies)}</Text>
                      </Box>
                    </Flex>

                    <Flex flexDirection="row" alignItems="center" h="100%">
                      <Box mr={1}>
                        <IconChip
                          height="25px"
                          width="25px"
                          ico={<Icon icon={faLaptop} color="info.500" size="xsm" name="laptop" />}
                          ariaLabel="icon-button"
                        />
                      </Box>
                      <Box mr={1}>
                        <Text size="bodySmallBold">{t({ id: 'INSIGHTS.METRICS.COMPUTER' })}</Text>
                      </Box>
                      <Box>
                        <Text size="bodySmall">{numberLabelFormate(desktopCookies)}</Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Box>

                <Box>
                  <Text size="caption" align="left">
                    MOBILE AD IDS
                  </Text>

                  <Flex flexDirection="row" alignItems="center" h="auto" flexWrap="wrap" justifyContent="left" mt={1}>
                    <Flex flexDirection="row" alignItems="center" h="100%" mr={2}>
                      <Box mr={1} mb={1}>
                        <IconChip
                          height="25px"
                          width="25px"
                          ico={<Icon icon={faAndroid} color="info.500" size="xsm" name="mobile-android" />}
                          ariaLabel="icon-button"
                        />
                      </Box>
                      <Box mr={1}>
                        <Text size="bodySmallBold">Android</Text>
                      </Box>
                      <Box flexDirection="column">
                        <Text size="bodySmall">{numberLabelFormate(androidDevices)}</Text>
                      </Box>
                    </Flex>
                    <Flex flexDirection="row" alignItems="center" h="100%">
                      <Box mr={1} mb={1}>
                        <IconChip
                          height="25px"
                          width="25px"
                          ico={<Icon icon={faApple} color="info.500" size="xsm" name="apple" />}
                          ariaLabel="icon-button"
                        />
                      </Box>
                      <Box mr={1}>
                        <Text size="bodySmallBold">IOS</Text>
                      </Box>
                      <Box flexDirection="column">
                        <Text size="bodySmall">{numberLabelFormate(iosDevices)}</Text>
                      </Box>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Flex>
          )}
      </Card>
    </Center>
  );
};

export default MetricsCard;
