import React, { FC } from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { usLocalizeFormate } from 'utils/usLocalizeFormate';
import { Error, Loading, Empty } from 'components/StateComponents';
import { Box } from '@retargetly/ui-components';

type Props = {
  dataSet: any;
  labels: any;
  isLoading: boolean;
  isError: boolean;
};

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AgeGraph: FC<Props> = ({ dataSet, labels, isLoading, isError }) => {
  const data = {
    labels,
    datasets: dataSet
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scales: {
      y: {
        ticks: {
          callback: (value: any) => {
            return `${value} %`;
          }
        },
        grid: {
          display: false
        }
      },
      x: {
        grid: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        position: 'bottom' as 'bottom',
        labels: {
          usePointStyle: true,
          boxHeight: 6
        }
      },
      tooltip: {
        callbacks: {
          label: (context: any) => {
            const localizeFormat = usLocalizeFormate.format(',.2f');
            let label = context.dataset.label || '';

            if (label) {
              label += ': ';
            }
            if (context.raw !== null) {
              label += `${localizeFormat(context.raw)} %`;
            }
            return label;
          }
        }
      }
    }
  };

  return (
    <>
      {isLoading && <Loading />}

      {isError && !isLoading && <Error />}

      {!isError && !isLoading && (dataSet === undefined || dataSet?.length === 0) && <Empty />}

      {!isLoading && dataSet !== undefined && labels && (
        <Box h="300px" width="90%" maxW="800px">
          <Bar data={data} options={options} />
        </Box>
      )}
    </>
  );
};

export default AgeGraph;
