import getUrlDomain from './get-url-domain';

const getDmpUrl = () => {
  const domain = getUrlDomain(window.location.href);
  switch (true) {
    case domain.indexOf('cvgd') !== -1:
      return 'https://converged.retargetly.com';
    case domain.indexOf('eqfx') !== -1:
      return 'https://dmp.equifaxdigital.com.ar';
    case domain.indexOf('mtk') !== -1:
      return 'https://comportamiento.theaudienceplanner.com';
    default:
      return process.env.REACT_APP_REDIRECT_DMP_APP;
  }
};
export default getDmpUrl;
