import React from 'react';
import { faAngleDown } from '@fortawesome/pro-solid-svg-icons';
// eslint-disable-next-line no-restricted-imports
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from '@material-ui/core';
import { Icon } from '@retargetly/ui-components';

const AutocompleteStyled = withStyles(theme => ({
  root: {
    '& .MuiAutocomplete-endAdornment': {
      marginTop: '5px'
    }
  },
  endAdornment: {
    marginRight: '5px'
  },
  clearIndicator: {
    color: theme.palette.primary.main
  },
  popper: {
    backgroundColor: theme.palette.primary.main
  },
  paper: {
    margin: 0,
    color: theme.palette.white,
    backgroundColor: theme.palette.white
  },
  option: {
    height: '48px',
    '&[aria-selected="true"]': {
      color: '#ffffff',
      backgroundColor: theme.palette.primary.light
    },
    '&[data-focus="true"]': {
      color: '#424250',
      backgroundColor: '#F3F6F9'
    }
  }
}))(Autocomplete);

const TextFieldStyled = withStyles(theme => ({
  root: {
    width: '100%',
    height: '44px',
    fontFamily: 'Avenir Next LT Pro',
    marginBottom: theme.spacing(2),
    '& label.Mui-focused': {
      color: theme.palette.primary.main,
      border: theme.palette.primary.main
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.palette.primary.main
    },
    '& .MuiInput-underline.Mui-error:after': {
      borderBottomColor: 'red'
    },
    '& .MuiOutlinedInput-root': {
      '&:hover fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&.Mui-focused fieldset': {
        borderColor: theme.palette.primary.main
      },
      '&:error fieldset': {
        borderColor: 'red'
      }
    }
  }
}))(TextField);

const DropDownSdS = ({ ...props }) => {
  return (
    <div>
      <AutocompleteStyled
        {...props}
        renderInput={params => (
          <TextFieldStyled
            {...params}
            variant="outlined"
            InputProps={{
              ...params.InputProps
            }}
          />
        )}
        popupIcon={<Icon icon={faAngleDown} name="angle-down" size="xsm" />}
      />
    </div>
  );
};

export default DropDownSdS;
