import { useQuery } from '@tanstack/react-query';
import { ServiceFactory } from '../../../services/FactoryService';

const services = ServiceFactory.get('segments');

export const REGULAR_KEY = 'regularSearch';

const getSegmentsSearch = async (params: any): Promise<any> => {
  const queryToShow = params.queryKey[1];
  const countryFilter = params.queryKey[2];
  return services.getSegmentsSearch(queryToShow, countryFilter, []);
};

export const useGetRegularSearch = (queryToShow: string | null | undefined, countryFilter: string) => {
  return useQuery([REGULAR_KEY, queryToShow, countryFilter], getSegmentsSearch, {
    enabled: queryToShow !== '' && !!countryFilter,
    select: data => {
      return data?.data;
    }
  });
};

export default useGetRegularSearch;
