import React from 'react';
import { CardHeader, Text, Box, Button, Icon, CardContent, Flex, Card, Span } from '@retargetly/ui-components';
import { faClose } from '@fortawesome/pro-duotone-svg-icons';
import PropTypes from 'prop-types';
import { useFormatMessage } from 'react-intl-hooks';
import ExpressionSegment from './ExpressionSegment';
import ExpressionConnector from './ExpressionConnector';

/**
 * Description: This component render the groups with the their segments present on ExpressionComponent
 * @param { Title, classesNames, groupId } String;
 * @param { onClick, onDeleteItem } Func;
 * @param { showGroupConector } Boolean;
 * @param { cardSegments, groups } Object
 */

const GroupExpressionComponent = ({
  onClick,
  onDeleteItem,
  cardSegments,
  groupId,
  isSelected,
  remove,
  internalOperator,
  changeInternalOperator,
  notRemovable
}) => {
  const t = useFormatMessage();

  const groupSegmentByRoot = segments => {
    const segmentsByRoot = {};
    segments.forEach(segment => {
      const root = segment.rootParentId || segment.rootParentName || null;
      if (root) {
        if (segmentsByRoot[root]) {
          segmentsByRoot[root].push(segment);
        } else {
          segmentsByRoot[root] = [segment];
        }
      }
    });
    return segmentsByRoot;
  };

  return (
    <Box>
      <Card withShadowEffect>
        <Box onClick={() => !isSelected && onClick(groupId)} cursor={isSelected === undefined ? 'default' : 'pointer'}>
          <CardHeader bgColor={isSelected ? 'blue.300' : 'white'}>
            <Flex alignItems="center" justifyContent="space-between">
              <Text size="bodyBold" color={isSelected ? 'white' : 'neutrals.400'}>
                Grupo {groupId + 1}
              </Text>
              <Flex alignItems="center">
                <ExpressionConnector
                  bucketKey={groupId}
                  operator={internalOperator}
                  onChangeOperator={changeInternalOperator}
                />

                <Button
                  icon={<Icon name="xmark" size="sm" color={isSelected ? 'white' : 'neutrals.400'} icon={faClose} />}
                  onClick={e => {
                    e.stopPropagation();
                    remove(groupId);
                  }}
                  variant="ghost"
                  size="xs"
                  disabled={notRemovable}
                />
              </Flex>
            </Flex>
          </CardHeader>
        </Box>
        <CardContent>
          <Flex flexWrap="wrap">
            {cardSegments.length === 0 && (
              <p style={{ fontStyle: 'italic', lineHeight: '20px' }} className="text--size-2">
                {t({
                  id: `EXPRESSION.TITLE_EMPTY`
                })}
              </p>
            )}
            {Object.keys(groupSegmentByRoot(cardSegments)).map(rootId => {
              const segments = groupSegmentByRoot(cardSegments)[rootId];
              return (
                <Box key={rootId} width="100%">
                  <Span fontSize="0.75rem" color="neutrals.400" textTransform="uppercase">
                    {segments[0].rootParentName.toUpperCase()}
                  </Span>
                  <Flex flexWrap="wrap">
                    {segments.map(
                      segment =>
                        segment &&
                        segment.segmentId && (
                          <ExpressionSegment
                            fullPath={segment.fullName}
                            segment={segment}
                            key={segment.id}
                            onClick={() => onDeleteItem(segment, groupId)}
                          />
                        )
                    )}
                  </Flex>
                </Box>
              );
            })}
          </Flex>
        </CardContent>
      </Card>
    </Box>
  );
};

GroupExpressionComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  onDeleteItem: PropTypes.func.isRequired,
  groupId: PropTypes.number.isRequired,
  cardSegments: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSelected: PropTypes.bool.isRequired,
  notRemovable: PropTypes.bool.isRequired,
  internalOperator: PropTypes.string.isRequired,
  changeInternalOperator: PropTypes.func.isRequired,
  remove: PropTypes.PropTypes.func.isRequired
};

GroupExpressionComponent.defaultProps = {};

export default GroupExpressionComponent;
