import React, { useReducer, useContext } from 'react';
import { Map } from 'mapbox-gl';
import * as turf from '@turf/turf';
import { mapReducer } from './MapReducer';
import { MapContext, MapContextProps } from './MapContext';
import { useLocationContext } from '../Locations/LocationProvider';
import { bulkPlaces } from './types';

export interface MapState {
  isMapReady: boolean;
  map?: Map;
  toolSelected?: string | undefined;
  drawInstance: MapboxDraw | undefined;
  bulkFileError: boolean;
}

const INITIAL_STATE: MapState = {
  isMapReady: true,
  map: undefined,
  toolSelected: undefined,
  drawInstance: undefined,
  bulkFileError: false
};

export interface Props {
  children: JSX.Element | JSX.Element[];
}

export function useMapContext(): any {
  return useContext(MapContext);
}

export const MapProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(mapReducer, INITIAL_STATE);
  const { addPlaces, radius: contextRadius } = useLocationContext();
  const { drawInstance } = state;

  const setBulkError = (errorState: boolean) => {
    dispatch({ type: 'SET_BULK_ERROR', payload: errorState });
  };

  const setMap = (map: Map) => {
    dispatch({ type: 'SET_MAP', payload: map });
  };

  const setDrawInstance = (draw: MapboxDraw) => {
    dispatch({ type: 'SET_DRAW', payload: draw });
  };

  const setTool = (tool: string | undefined) => {
    dispatch({ type: 'SET_TOOL', payload: tool });
  };

  const goToPoint = (longitude: number, latitude: number) => {
    const { map } = state;
    // eslint-disable-next-line no-unused-expressions
    map?.flyTo({ center: [longitude, latitude], zoom: 12 });
  };

  const addByLatAndLong = (lat: number, long: number) => {
    const { map } = state;
    // eslint-disable-next-line no-unused-expressions
    map?.flyTo({ center: [long, lat], zoom: 12 });
    const center = turf.point([long, lat]);
    const rad = contextRadius;
    const options = {
      steps: 60,
      units: 'meters',
      properties: {
        center: [long, lat],
        isCircle: true,
        radiusInMeters: contextRadius,
        latLongTool: true,
        bulkTool: false
      }
    };
    // @ts-ignore
    const circle = turf.circle(center, rad, options);
    const id = drawInstance?.add(circle) || '';
    // @ts-ignore
    const feature = drawInstance?.get(id);
    addPlaces(feature);
  };

  const addByBulk = (data: bulkPlaces[]) => {
    // eslint-disable-next-line array-callback-return
    data.map((location: bulkPlaces) => {
      const latitude = parseFloat(location.latitude);
      const longitude = parseFloat(location.longitude);
      if (latitude && longitude) {
        const rad = location.radius !== undefined ? parseFloat(location.radius) : contextRadius;
        // omits invalid coordinates
        if (latitude < -90 || latitude > 90 || longitude < -180 || longitude > 180) {
          return 0;
        }
        const center = turf.point([longitude, latitude]);
        const options = {
          steps: 60,
          units: 'meters',
          properties: {
            center: [longitude, latitude],
            isCircle: true,
            radiusInKm: rad,
            latLongTool: false,
            bulkTool: true
          }
        };

        // @ts-ignore
        const circle = turf.circle(center, rad, options);
        const id = drawInstance?.add(circle);
        // @ts-ignore
        const feature = drawInstance?.get(id);
        addPlaces(feature);
      } else {
        setBulkError(true);
      }

      return null;
    });
  };

  const value: MapContextProps = {
    setMap,
    goToPoint,
    setTool,
    setDrawInstance,
    addByLatAndLong,
    addByBulk,
    setBulkError,
    ...state
  };

  return <MapContext.Provider value={value}>{children}</MapContext.Provider>;
};
