/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { Flex, Box } from '@retargetly/ui-components';
import '../../styles/components/expression.scss';
import useExpressionBucket from 'hooks/planning/useExpressionBucket';
import GroupExpressionComponent from './GroupExpressionComponent';
import ExpressionConnector from './ExpressionConnector';
/**
 * Description: Display the expression of selected segments
 */

const ExpressionComponent = props => {
  const {
    expressionBucket,
    bucketSelected,
    selectBucket,
    removeBucketToExpression,
    removeSegmentsToExpression,
    setExternalOperatorToBucket,
    setInternalOperatorToBucket
  } = useExpressionBucket();

  const deleteItem = (segment, bucketKey) => {
    removeSegmentsToExpression([segment], bucketKey);
  };

  return (
    <>
      {expressionBucket.map((group, index) => (
        <Box w="100%" key={Math.random()}>
          <GroupExpressionComponent
            groupId={index}
            notRemovable={expressionBucket.length === 1}
            internalOperator={group.internalOperator}
            changeInternalOperator={setInternalOperatorToBucket}
            cardSegments={group.segments}
            onDeleteItem={deleteItem}
            remove={removeBucketToExpression}
            onClick={selectBucket}
            isSelected={bucketSelected === index}
          />
          {index < expressionBucket.length - 1 && expressionBucket.length > 1 && (
            <Flex width="100%" justify="left" height="65px" align="center" position="relative">
              <div
                style={{
                  width: '10px',
                  height: '65px',
                  zIndex: 0,
                  position: 'absolute'
                }}
              >
                <svg width="50px" height="65px">
                  <line x1="40" y1="0" x2="40" y2="100" stroke="#D6D6E0" color="red" />
                </svg>
              </div>
              <ExpressionConnector
                bucketKey={index}
                operator={group.operator}
                onChangeOperator={setExternalOperatorToBucket}
              />
            </Flex>
          )}
        </Box>
      ))}
    </>
  );
};

export default ExpressionComponent;
