import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { isAuthorized, getUser } from '../selectors';
import { fetchLogout } from '../actions';

const useAuth = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const userAuth = useSelector(isAuthorized);
  const token = localStorage.getItem('token');
  const refreshToken = localStorage.getItem('rtToken');
  const user = useSelector(getUser);
  const userLang = user?.lang;
  const isLoggedIn = userAuth && token && refreshToken;
  const onLogOut = () => dispatch(fetchLogout());

  return {
    isLoggedIn,
    isAuthorized,
    redirectToLogin: () => history.push('/'),
    userLang,
    user,
    onLogOut,
    token,
    refreshToken,
    userAuth
  };
};

export default useAuth;
