/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Flex, Box, Heading, Text, Divider, Button, Icon, Tooltip } from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';
import CountryAndSearchContainer from 'components/CountryAndSearch/CountryAndSearchContainer';
import { useTaxonomyContext } from 'components/Planner/context/hooks';
import { SEARCH, SEARCH_PLANNING } from 'constants/routes';
import useUIContext from 'hooks/useUIContext';
import useExpressionBucket from 'hooks/planning/useExpressionBucket';
import { faArrowUpRightAndArrowDownLeftFromCenter, faBrowsers } from '@fortawesome/pro-duotone-svg-icons';
import Breadcrumbs from 'widgets/Breadcrumbs/Breadcrumbs';
import { SearchPage } from './SearchPage';
import { TaxonomyList } from '../components/TaxonomyList';
import '../../Search/SearchComponent.scss';
import { PlanningsPage } from './PlanningsPage';

export function PlannerPage() {
  const t = useFormatMessage();
  const { countrySelected, handleListItemClick } = useTaxonomyContext();
  const { push } = useHistory();

  const { isRightMenuOpen, setIsRightMenuOpen } = useUIContext();
  const { hasValidExpression } = useExpressionBucket();

  const toggleRightPanel = () => {
    setIsRightMenuOpen(true);
  };

  return (
    <Flex flexDirection="column" height="100%">
      <Flex flexDirection="column" align="left" my={4} ml={8} mr={4}>
        <Breadcrumbs />
        <Flex mt={1} width="90%" alignItems="center" justifyContent="space-between">
          <Box>
            <Heading color="neutrals.500" size="200">
              {t({
                id: `SEARCH.INFO.WELCOME`
              })}
            </Heading>

            <Text size="bodySmall" color="neutrals.400">
              {t({
                id: `SEARCH.INFO.SUBTITLE`
              })}
            </Text>
          </Box>
          {!isRightMenuOpen && hasValidExpression && (
            <Tooltip label={t({ id: 'PLANIFICATION.OPEN' })} hasArrow placement="bottom" shouldWrapChildren>
              <Flex width="32px" height="32px" ml="auto">
                <Button
                  icon={
                    <Icon
                      icon={faArrowUpRightAndArrowDownLeftFromCenter}
                      size="sm"
                      name="faArrowUpRightAndArrowDownLeftFromCenter"
                    />
                  }
                  size="sm"
                  onClick={toggleRightPanel}
                  disabled={!hasValidExpression}
                  variant="ghost"
                />
              </Flex>
            </Tooltip>
          )}
        </Flex>
      </Flex>

      <Divider orientation="horizontal" variant="solid" />
      <Box ml={8} mr={4} mt={4}>
        <CountryAndSearchContainer />
      </Box>

      <Flex ml={8} mr={4} gap={4} flex="1 auto" h="60%">
        <Flex
          flexDirection="column"
          minWidth="200px"
          style={{
            overflow: 'auto',
            scrollbarWidth: 'none'
          }}
        >
          {countrySelected && <TaxonomyList />}
          <Box mt={2} mb={8} className="planning">
            <Button
              size="sm"
              variant="ghost"
              label={`${t({
                id: `PLANIFICATIONS.SAVED`
              })}`}
              onClick={() => {
                push(SEARCH_PLANNING);
                handleListItemClick(null);
              }}
              isFullWidth
              icon={<Icon icon={faBrowsers} size="sm" name="faBrowsers" color="primary.500" />}
            />
          </Box>
        </Flex>

        <Box
          flexGrow="1"
          style={{
            overflow: 'auto',
            scrollbarWidth: 'none'
          }}
        >
          <Switch>
            <Route exact path={SEARCH} component={SearchPage} />
            <Route exact path={SEARCH_PLANNING} component={PlanningsPage} />
          </Switch>
        </Box>
      </Flex>
    </Flex>
  );
}
