import React, { FC, useEffect, useState, useCallback, memo } from 'react';
import { faHouseLaptop } from '@fortawesome/pro-duotone-svg-icons';
import { DropDownButtonPrimary, Text, Icon, Flex } from '@retargetly/ui-components';
import { useHistory } from 'react-router-dom';
import usePlanning from 'hooks/planning/usePlannings';
import useUIContext from 'hooks/useUIContext';
import DropdownSdS from '../../../widgets/DropdownSdS';
import useOrganization from '../../../hooks/useOrganization';
import useAuth from '../../../hooks/useAuth';

const ClientSelector: FC = memo(() => {
  const [open, setOpen] = useState<boolean>(false);
  const history = useHistory();
  const { user } = useAuth();
  const { onSetCurrentOrganization, onGetOrganizations, currentOrganization, organizations } = useOrganization();
  const { cancelPlanning } = usePlanning();
  const { setIsRightMenuOpen } = useUIContext();
  // Hook that executes Accounts Api call
  useEffect(() => {
    if (organizations?.length === 0 || organizations === undefined) onGetOrganizations();
  }, [organizations, onGetOrganizations]);

  /*
    /Uses the default organization of the user if the currentOrganization is not set
  */
  useEffect(() => {
    if ((currentOrganization === undefined || currentOrganization === null) && user?.organization !== undefined) {
      onSetCurrentOrganization({ ...user.organization, clientId: user.organization.id });
    }
  }, [currentOrganization, onSetCurrentOrganization, user]);

  const handleOrganizationChange = useCallback(
    (e, client) => {
      try {
        if (client) {
          onSetCurrentOrganization({ ...client });
          setOpen(false);
          cancelPlanning();
          setIsRightMenuOpen(false);
        }
        /**
         * @description This catch is used to avoid home component useEffect re select an organization base on view_as query param
         * if the url have a query param view_as
         */
      } finally {
        history.push(history.location.pathname);
      }
    },
    [onSetCurrentOrganization]
  );

  return (
    <Flex flex="1" justifyContent="end">
      <DropDownButtonPrimary
        value={currentOrganization?.name !== undefined ? <b>Workspace: {currentOrganization.name} </b> : ''}
        leftIcon={<Icon icon={faHouseLaptop} name="laptop-house" className="svgPrimary" />}
        open={open}
        setOpen={val => setOpen(val)}
      >
        <div className="d-flex flex-column">
          <div className="mb-2">
            <Text size="bodyMedium" color="black">
              WorkSpaces
            </Text>
          </div>
          <div className="mb-2">
            <DropdownSdS
              id="combo-box-demo"
              value={currentOrganization || null}
              onChange={handleOrganizationChange}
              options={organizations}
              disableClearable
              getOptionSelected={(option: any, value: any) => option.clientId === value.clientId}
              getOptionLabel={(option: any) => {
                return `${option.name} - ID ${option.clientId}`;
              }}
              filterOptions={(options: any[], params: { inputValue: string }) => {
                return options.filter(
                  (f: any) =>
                    f.clientId === parseInt(params.inputValue, 10) ||
                    f.name.toUpperCase().includes(params.inputValue.toUpperCase())
                );
              }}
              style={{ width: 300 }}
            />
          </div>
        </div>
      </DropDownButtonPrimary>
    </Flex>
  );
});

export default ClientSelector;
