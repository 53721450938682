export const LOOP_FALLBACK_THEME = {
  id: 1,
  domain: 'retargetly',
  title: 'Retargetly Loop',
  favicon: 'loop/loop-fav.ico',
  image_theme: 'newLogoLayout.svg',
  image_login: 'loop/logo-loop.svg',
  primary_color: '#004CAD',
  secondary_color: '#004CAD' // there is no need for secondary color in loop theme but it is required in the Rely provider
};
