/* eslint-disable camelcase */
import React from 'react';
import { useEstimationMetrics } from 'hooks/useEstimationMetrics';
import { Card, Flex, Box, Text, Center } from '@retargetly/ui-components';
import { faUserGroup } from '@fortawesome/pro-duotone-svg-icons';
import { useFormatMessage } from 'react-intl-hooks';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ReachCard from './ReachCard';
import MetricsCard from './MetricsCard';
import PlatformReachCard from './PlatformReachCard';

const Statistics = () => {
  const matches = useMediaQuery('(min-width:1541px)');
  const t = useFormatMessage();

  const { data: totalDeviceData, isLoading: isLoadingTotalDeviceData, isError } = useEstimationMetrics({
    metrics: [
      'total_devices',
      'android_devices',
      'ios_devices',
      'people',
      'web_no_mobile_devices',
      'web_mobile_devices'
    ]
  });

  return (
    <Flex direction="column" justify="center" w="100%" px={4}>
      <Grid container spacing={3}>
        <Grid item xs={matches ? 4 : 12}>
          <ReachCard
            reachVolume={totalDeviceData?.people}
            deviceVolume={totalDeviceData?.total_devices}
            icon={faUserGroup}
            isLoading={isLoadingTotalDeviceData}
            isError={isError}
          />
        </Grid>
        <Grid item xs={matches ? 8 : 12}>
          <MetricsCard
            isLoading={isLoadingTotalDeviceData}
            isError={isError}
            mobileCookies={totalDeviceData?.web_mobile_devices}
            desktopCookies={totalDeviceData?.web_no_mobile_devices}
            androidDevices={totalDeviceData?.android_devices}
            iosDevices={totalDeviceData?.ios_devices}
          />
        </Grid>
      </Grid>

      <Center width="auto" my={4}>
        <Card padding={4} width="100%">
          <Box mb={3}>
            <Text size="caption" align="left">
              {t({ id: 'INSIGHTS.REACH' })}
            </Text>
          </Box>
          <PlatformReachCard totalDeviceData={totalDeviceData} />
        </Card>
      </Center>
    </Flex>
  );
};

export default Statistics;
