import React, { FC } from 'react';
import { Divider } from 'antd';
import UserInfo from './UserInfo';
import { UserMenu } from './UserMenu';
import { UserSettingsTypes } from '../UserSettings.types';

export const Container: FC<UserSettingsTypes> = ({ user, setOpen }) => {
  return (
    <div className="d-flex flex-column">
      <UserInfo />
      <Divider className="my-4" />
      <UserMenu user={user} setOpen={setOpen} />
    </div>
  );
};
