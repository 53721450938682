import { Button, Flex, Heading, Stack, Text } from '@retargetly/ui-components';
import { Input, Modal } from 'antd';
import { Blueprint } from 'hooks/planning/useBlueprints';
import React, { useState } from 'react';
import { useFormatMessage } from 'react-intl-hooks';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onDelete: (blueprin: Blueprint) => void;
  planning: Blueprint;
}

export function DeletePlanningModal({ isOpen, onClose, onDelete, planning }: Props) {
  const t = useFormatMessage();
  const CONFIRM_WORD = t({ id: 'PLANIFICATION.DELET_CONFIRM_WORD' });
  const [confirmWord, setConfirmWord] = useState('');

  const handleConfirmWordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmWord(event.target.value);
  };

  const isConfirmWordValid = confirmWord === CONFIRM_WORD;

  return (
    <Modal visible={isOpen} onCancel={onClose} footer={null} centered>
      <Stack flexDirection="column" gap={2}>
        <Heading size="200">{t({ id: 'PLANIFICATION.DELETE_TITLE' })}</Heading>
        <Text size="bodyDefault">{t({ id: 'PLANIFICATION.DELETE_DESCRIPTION' })}</Text>
        <Text size="bodyDefault">&#x2022; {planning?.name}</Text>
        <Text size="bodyDefault">{t({ id: 'PLANIFICATION.DELETE_CONFIRM' }, { word: CONFIRM_WORD as string })}</Text>
        <Input onChange={handleConfirmWordChange} value={confirmWord} />
        <Flex flexDirection="row" gap={2} alignItems="center" justifyContent="flex-end">
          <Button onClick={onClose} label={t({ id: 'GENERIC.LABEL.CANCEL' }) as string} variant="ghost" />
          <Button
            onClick={() => onDelete(planning)}
            label={t({ id: 'PLANIFICATION.DELETE_ACCEPT' }) as string}
            variant="destructive"
            disabled={!isConfirmWordValid}
          />
        </Flex>
      </Stack>
    </Modal>
  );
}
