import colorLib, { Color, RGBA } from '@kurkle/color';

const transparentize = (value: string | number[] | Color | RGBA, opacity: number) => {
  const alpha = opacity === undefined ? 0.5 : 1 - opacity;
  return colorLib(value)
    .alpha(alpha)
    .rgbString();
};

export default transparentize;
