import { useLDClient } from 'launchdarkly-react-client-sdk';

export interface UserFlag {
  // eslint-disable-next-line camelcase
  internal_user_id: string;
  name: string;
  email: string;
}

const useFlagLoader = () => {
  const ldClient = useLDClient();

  const setUserForFlagContext = (user: UserFlag) => {
    if (user && ldClient) {
      ldClient.identify({
        kind: 'user',
        key: user.internal_user_id,
        name: user.name,
        email: user.email
      });
    }
  };

  return {
    setUserForFlagContext
  };
};

export default useFlagLoader;
