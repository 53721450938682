import { Stack, colors, Text, Span, Icon, Flex } from '@retargetly/ui-components';
import { faArrowDown, faArrowUp } from '@fortawesome/pro-duotone-svg-icons';
import { Tooltip } from 'antd';
import React from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { getAffinityRange } from 'utils/get-affinity-range';
import { AFFINITY_LABELS, SIMILITY_LABELS } from 'utils/interfaces';

interface Props {
  affinity: number;
}

export const SearchAffinity = ({ affinity }: Props) => {
  const t = useFormatMessage();
  const key = getAffinityRange(affinity);

  return (
    <Stack flexDirection="column" alignItems="flex-end" width="60%">
      <Stack alignItems="center" flexDirection="row" gap={1}>
        <Tooltip
          title={
            <Stack flexDirection="column" gap={0.5}>
              <Text size="bodySmall">{t({ id: SIMILITY_LABELS[key] })}</Text>
              <Text size="bodySmall">{t({ id: 'SEARCH.GPT.SIMILARITY' }, { affinity })}</Text>
            </Stack>
          }
        >
          <Text size="bodySmall">{t({ id: AFFINITY_LABELS[key] })}</Text>
        </Tooltip>
      </Stack>

      <Flex flexDirection="row" margin="0px !important" alignItems="center" gap={1}>
        {key === 'low' ? (
          <Icon icon={faArrowDown} name="arrow-down" size="sm" color={colors.yellow[600]} />
        ) : (
          <Icon icon={faArrowUp} name="arrow-up" size="sm" color={colors.green[600]} />
        )}

        <Span color={key === 'low' ? colors.yellow[600] : colors.green[600]}>{`${affinity} %`}</Span>
      </Flex>
    </Stack>
  );
};
