import React, { useEffect, useState, Fragment, memo } from 'react';
import { Row, Select, Button, Card, Typography, Divider, Tooltip, Space, message } from 'antd';
import { connect, useDispatch, useSelector } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import PropTypes from 'prop-types';
import { SisternodeOutlined, CloseOutlined } from '@ant-design/icons';
import usePlanning from 'hooks/planning/usePlannings';
import useBlueprints from 'hooks/planning/useBlueprints';
import { getCurrentPlanification } from 'selectors/planification';
import useCustomWindowEvent from 'hooks/analytics/useCustomWindowEvent';
import { toggleShareModal } from 'actions/ui';
import * as selectors from '../../../selectors';
import * as actions from '../../../actions';
import tagRender from '../../../utils/tagRender';

import { contentWrapper, rowDistance, textDistance, clientButtonStyle } from './formStyle';
import PriceInfo from './PriceInfo';
import useOrganization from '../../../hooks/useOrganization';
import { usePlanificationContext } from '../context';

const { Option } = Select;
const { Text } = Typography;

const lateralsFormStyle = { padding: 0 };

const ClientsStep = memo(({ platforms, onGetPlatformsAndSeats, loadingDmps }) => {
  const {
    handlePlatformChange,
    handleSeatChange,
    formState,
    addPlatformAndSeat,
    removePlatform
  } = usePlanificationContext();
  const [platsAlreadySelected, setPlatformsAlreadySelected] = useState([]);
  const [setSeatsSelected] = useState([]);
  const [seatsAlreadySelected, setSeatsAlreadySelected] = useState([]);
  const t = useFormatMessage();
  const { currentOrganization } = useOrganization();
  const { planning } = usePlanning();
  const { shareBlueprint } = useBlueprints();
  const currentPlanification = useSelector(getCurrentPlanification);
  const { pushEventForAnalytics } = useCustomWindowEvent();
  const dispatch = useDispatch();

  const onShareBluePrint = async () => {
    const payloadPlatforms = formState.planification.platforms.map(platform => {
      const audienceType = platform?.platformsData?.[platform.platform]?.audienceType;
      return {
        id: platform.platform,
        name: platform.platformName,
        seats: platform.seatIds.map(selectedSeat => {
          let seat = { id: selectedSeat };
          if (audienceType) {
            seat = { id: selectedSeat, audienceType, type: audienceType };
          }
          return seat;
        })
      };
    });
    try {
      await shareBlueprint(
        {
          platforms: payloadPlatforms,
          price: ''
        },
        currentPlanification?.segmentId,
        currentOrganization?.clientId
      );
      pushEventForAnalytics('planning-shared');
      message.success(t({ id: 'PLANIFICATION.SUCCESFUL.SHARE' }));
      dispatch(toggleShareModal(false));
    } catch (error) {
      message.error(error?.message);
    }
  };

  useEffect(() => {
    onGetPlatformsAndSeats(currentOrganization?.clientId);
  }, [onGetPlatformsAndSeats]);

  useEffect(() => {
    if (!!planning && planning.clients) {
      const { clients } = planning;
      const platsSelected = clients[0].platforms.map(e => e.platform);
      const seatsPrevSelect = {};
      clients[0].platforms.forEach(e => {
        seatsPrevSelect[e.platform] = e.seatIds;
      });
      setPlatformsAlreadySelected(platsSelected);
      setSeatsAlreadySelected(seatsPrevSelect);
    }
    return () => {};
  }, [planning]);
  const onHandlePlatformChange = (e, idxPlatform, option) => {
    // This hook set disabled selected platforms to handleDisableOptions
    setPlatformsAlreadySelected(prevState => {
      const newArr = [...prevState];
      newArr[idxPlatform] = e;
      return newArr;
    });
    if (e === undefined) return;
    handlePlatformChange({ name: 'platform', value: e, platformName: option?.children }, idxPlatform);
  };

  // Disable todas las plataformas ya seleccionadas.

  const handleDisabled = data => {
    if (platsAlreadySelected.find(e => e === data)) {
      return true;
    }
    return false;
  };

  const handleDisableSeats = (seatid, platformId) => {
    if (seatsAlreadySelected[platformId] && seatsAlreadySelected[platformId].find(e => e === seatid)) {
      return true;
    }
    return false;
  };

  // Mapping The Data in the FormState to provide a propper validation.

  const planificationHasPlatfomsWithSeatsSelected = copiedState => {
    return copiedState.platforms.length > 0 && copiedState.platforms.every(e => e.seatIds.length > 0);
  };

  const platformHasSeatsSelected = copiedState => {
    return copiedState.seatIds.length > 0;
  };

  const platformIsSelected = pars => {
    return pars.platforms.some(e => e.platform);
  };

  const handleRemovePlatform = platformIndex => {
    setPlatformsAlreadySelected(prevState => {
      return prevState.filter((plat, ind) => ind !== platformIndex);
    });
    setSeatsSelected([]);
    removePlatform(platformIndex);
  };

  const onHandleSeatChange = (e, idxPlatform, options) => {
    handleSeatChange({ name: 'seatIds', value: e, seatsArray: options }, idxPlatform);
    setSeatsSelected(e);
  };

  return (
    <Card bordered={false} bodyStyle={contentWrapper}>
      <Card bordered={false} bodyStyle={lateralsFormStyle} loading={loadingDmps}>
        <Row id="clients" style={rowDistance}>
          <Space direction="vertical">
            <Text strong style={textDistance}>
              {t({
                id: `GENERIC.LABEL.CLIENT`
              })}
            </Text>
            <Text style={textDistance}>{currentOrganization.name}</Text>
          </Space>
        </Row>
        {formState.planification.platforms.map((platform, idxPlatform) => {
          return (
            <Fragment key={platform.platform}>
              <Row id="platforms" style={rowDistance}>
                <Row justify="space-between" style={{ width: '100%' }}>
                  <Text strong style={textDistance}>
                    {t({
                      id: `GENERIC.LABEL.PLATFORMS`
                    })}
                  </Text>
                  {formState.planification.platforms.length > 1 && !handleDisabled(platform.platform) && (
                    <Button
                      shape="circle"
                      size="small"
                      style={{ transform: `scale(0.7)` }}
                      onClick={() => handleRemovePlatform(idxPlatform)}
                    >
                      <CloseOutlined />
                    </Button>
                  )}
                </Row>
                <Select
                  showArrow
                  showSearch
                  loading={loadingDmps}
                  className="custom-select"
                  disabled={
                    !!planning &&
                    planning.clients.length > 0 &&
                    planning.planification.platforms.length > 0 &&
                    platformHasSeatsSelected(formState.planification.platforms[idxPlatform])
                  }
                  value={
                    formState.planification.platforms[idxPlatform].platform
                      ? formState.planification.platforms[idxPlatform].platform
                      : []
                  }
                  style={{ width: '100%' }}
                  placeholder={t({
                    id: `GENERIC.PLACEHOLDER.PLATFORMS`
                  })}
                  onChange={(e, option) => onHandlePlatformChange(e, idxPlatform, option)}
                  optionFilterProp="children"
                  getPopupContainer={() => document.getElementById('platforms')}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {platforms &&
                    platforms.map(platformOption => {
                      const { id } = platformOption;
                      return (
                        <Option key={id} value={id} disabled={handleDisabled(id)}>
                          {platformOption.name}
                        </Option>
                      );
                    })}
                </Select>
              </Row>
              <Row id="seats" style={rowDistance}>
                <Text strong style={textDistance}>
                  Seats
                </Text>
                <Select
                  mode="multiple"
                  showArrow
                  className="custom-select"
                  showSearch
                  loading={loadingDmps}
                  value={formState.planification.platforms[idxPlatform].seatIds}
                  style={{ width: '100%' }}
                  tagRender={tagRender}
                  placeholder={t({
                    id: `GENERIC.PLACEHOLDER.SEATS`
                  })}
                  onChange={(e, optionsSeats) => onHandleSeatChange(e, idxPlatform, optionsSeats)}
                  optionFilterProp="children"
                  getPopupContainer={() => document.getElementById('seats')}
                  filterOption={(input, option) =>
                    option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {platforms
                    .filter(plats => {
                      return plats.id === formState.planification.platforms[idxPlatform].platform;
                    })
                    .map(account => {
                      return account.seats.map(sato => {
                        return (
                          <Option
                            key={sato.id}
                            value={sato.id}
                            status={sato.status}
                            disabled={handleDisableSeats(
                              sato.id,
                              formState.planification.platforms[idxPlatform].platform
                            )}
                          >
                            {sato.name}
                          </Option>
                        );
                      });
                    })}
                </Select>
              </Row>
              {platform.platform && (
                <Row id="price" style={rowDistance}>
                  <PriceInfo platformId={platform.platform} />
                </Row>
              )}
              <Divider style={{ margin: '12px 0' }} />
            </Fragment>
          );
        })}
        <Row justify="end" align="middle">
          <Tooltip
            title={t({
              id: 'PLANIFICATION.LABEL.ADDPLATFORM'
            })}
            trigger={[planificationHasPlatfomsWithSeatsSelected(formState.planification) ? 'hover' : '']}
          >
            <Button
              icon={<SisternodeOutlined />}
              disabled={!planificationHasPlatfomsWithSeatsSelected(formState.planification)}
              shape="circle"
              onClick={() => addPlatformAndSeat()}
            />
          </Tooltip>
        </Row>
      </Card>
      <Row style={clientButtonStyle} justify="end">
        <Button
          type="primary"
          key="next"
          name="next"
          disabled={
            // adding this cond to not allow to select platform without selected seat
            !platformIsSelected(formState.planification) ||
            (platformIsSelected(formState.planification) &&
              !planificationHasPlatfomsWithSeatsSelected(formState.planification))
          }
          onClick={onShareBluePrint}
        >
          {t({
            id: `GENERIC.LABEL.NEXT`
          })}
        </Button>
      </Row>
    </Card>
  );
});

const mapStateToProps = state => {
  return {
    platforms: selectors.getPlatforms(state),
    loadingDmps: selectors.getLoadingOrganizations(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onGetPlatformsAndSeats: val => dispatch(actions.getPlatformsAndSeats(val))
  };
};

ClientsStep.propTypes = {
  platforms: PropTypes.arrayOf(PropTypes.object).isRequired,
  onGetPlatformsAndSeats: PropTypes.func.isRequired,
  loadingDmps: PropTypes.bool.isRequired
};

ClientsStep.defaultProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(ClientsStep);
