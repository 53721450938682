import React, { FC, useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useDebounceFn } from 'ahooks';
import { Flex } from '@retargetly/ui-components';
import useEventTracker from 'hooks/useEventTracker';
import useGetPlaces from '../../../../hooks/geotool/useGetPlaces';
import GeoSearchResult from '../SearchResult/GeoSearchResult';
import './GeoSearchBar.scss';

type Props = {
  userLocation?: [number, number];
};

const GeoSearchBar: FC<Props> = ({ userLocation }) => {
  const { trackEvent } = useEventTracker();
  const divRef = React.useRef<any>(null);
  const t = useFormatMessage();
  const [initialValue, setInitialValue] = React.useState('');
  const [query, setQuery] = React.useState('');
  const [statePlacesModal, setStatePlacesModal] = React.useState(false);
  const { data } = useGetPlaces({ query, userLocation });

  const { run } = useDebounceFn(
    inputValue => {
      setQuery(inputValue);
    },
    {
      wait: 800
    }
  );

  useEffect(() => {
    run(initialValue);
  }, [initialValue, run]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setInitialValue(value);
  };

  const onSelect = (value: string) => {
    setQuery(value);
    setInitialValue(value);
    setStatePlacesModal(false);
    trackEvent('GEO_SEARCH_LOCATION', {});
  };

  const handleBlur = (): void => {
    setTimeout(() => {
      setStatePlacesModal(false);
    }, 100);
  };

  const element = document.getElementById('x-element');
  const rect = element?.getBoundingClientRect();
  const x = rect?.left;
  const y = rect?.top;

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      height="auto"
      top="15px"
      left="0px"
      width="93%"
      marginLeft="32px"
      zIndex="2"
      backgroundColor="transparent"
    >
      <div className=".input-container" ref={divRef} id="x-element">
        <Input
          value={initialValue}
          className="search-input "
          size="large"
          placeholder={`${t({
            id: `GEOTOOL.ACTIONPANEL.SEARCH.PLACEHOLDER`
          })}`}
          prefix={<SearchOutlined />}
          onChange={onChange}
          onFocus={() => setStatePlacesModal(true)}
          onBlur={handleBlur}
        />
      </div>
      {statePlacesModal &&
        createPortal(
          <Flex
            position="absolute"
            top={`${y + divRef?.current?.offsetHeight}px`}
            left={`${x}px`}
            zIndex={300}
            width={divRef?.current?.offsetWidth !== undefined ? divRef?.current?.offsetWidth : '800px'}
          >
            <GeoSearchResult places={data?.features} onSelect={onSelect} />
          </Flex>,
          document.body
        )}
    </Flex>
  );
};

export default GeoSearchBar;
