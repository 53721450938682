import { useMutation } from '@tanstack/react-query';
import { ServiceFactory } from '../../services/FactoryService';

const planning = ServiceFactory.get('plannings');

interface Rules {
  type: string;
  value: string | number | boolean;
}

interface Payload {
  rules: Rules[];
  type: string;
  name: string;
  crossdevice: boolean;
  reset: () => void;
  clientId: number;
}

const createSegments = (payload: Payload): Promise<number> => {
  return planning.createKeywordsAudience(payload).then((response: any) => {
    // reset is used to reset the form after the mutation is done
    payload.reset();
    return response?.data?.response?.segmentId;
  });
};

export const useCreateKeywordsAudience = (
  onSuccess: (segmentId: number) => void,
  onError: (message: string) => void
) => {
  return useMutation(createSegments, {
    onSuccess: (segmentId: number) => {
      onSuccess(segmentId);
    },
    onError: (error: any) => {
      onError(error?.response?.data?.error);
    }
  });
};
