import React from 'react';
import { Header as HeadTemplate } from '@retargetly/ui-components';
import { Link } from 'react-router-dom';
import { SEARCH } from 'constants/routes';
import useTheme from '../../hooks/themes/useTheme';
import { UserSettings } from './UserMenu/UserSettings';
import ClientSelector from './ClientSelector/ClientSelector';

/**
 * Description: Header Component
 */

const Header = () => {
  const { getThemeProp, getThemePropUrl } = useTheme();
  return (
    <HeadTemplate
      bgColor="secondary.500"
      position="relative"
      id="main_header"
      logo={
        <Link to={SEARCH}>
          <img
            src={getThemePropUrl('image_theme')}
            alt={getThemeProp('title')}
            style={{ maxWidth: '130px', maxHeight: '40px' }}
          />
        </Link>
      }
    >
      <ClientSelector />
      <UserSettings />
    </HeadTemplate>
  );
};

export default Header;
