import React from 'react';
import { MapContainer } from 'components/GeoTool/components';
import { Flex } from '@retargetly/ui-components';
import { Layout } from 'antd';
import { MapProvider } from '../../components/GeoTool/context/Map/MapProvider';
import { LocationProvider } from '../../components/GeoTool/context/Locations/LocationProvider';
import CreationPanel from '../../components/GeoTool/components/CreationPanel/CreationPanel';

const GeoTool = () => {
  if (!navigator.geolocation) {
    alert('Geolocation is not supported by your browser');
    throw new Error('Geolocation is not supported by your browser');
  }

  return (
    <LocationProvider>
      <MapProvider>
        <Layout.Content>
          <Flex flexDirection="row" height="100%" width="100%">
            <MapContainer />
            <CreationPanel />
          </Flex>
        </Layout.Content>
      </MapProvider>
    </LocationProvider>
  );
};

export default GeoTool;
