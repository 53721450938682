import { ServiceFactory } from 'services/FactoryService';
import useEventTracker from 'hooks/useEventTracker';
import useOrganization from 'hooks/useOrganization';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';
import { countExpressionItems } from 'utils/count-expression-items';
import useCountries from 'hooks/useCountriesFilter';
import useDownloadFile from 'hooks/useDownloadFile';
import { BucketExpressionModel } from 'types/expression.types';
import { expressionBucketToBucketExpressionModel } from 'utils/expressionTransformTo';
import { useDispatch } from 'react-redux';
import { resetCurrentPlanification, setCurrentPlanification } from 'actions/plannings';
import useExpressionBucket from './useExpressionBucket';
import { GET_BLUEPRINTS } from './useBlueprints';
import { useDeletePlanning } from './useDeletePlanning';

const services = ServiceFactory.get('plannings');

export type Planning = {
  id?: number;
  folder?: string;
  name?: string;
  clientId: number;
  country?: string;
  estimation?: number;
  expression?: BucketExpressionModel;
  createdAt?: string;
  updatedAt?: string;
  createdBy?: number;
  segmentId?: number;
  countryName?: string;
  desc?: string;
  usage?: {
    dsp: Usage;
    ssp: Usage;
    segments: Usage;
  };
};
interface Usage {
  total: number;
  list: List[];
}

interface List {
  platformId: number;
  platformName: string;
  platformSegmentId?: any;
  licenses: License[];
}

interface License {
  name: string;
  seatId: number;
  status: string;
  seat: string;
  message: string;
  platformSegmentId?: any;
}

export const usePlanning = () => {
  const queryClient = useQueryClient();
  const { currentOrganization } = useOrganization();
  const dispatch = useDispatch();
  const { trackEvent } = useEventTracker();
  const { countrySelected } = useCountries();
  const { hasValidExpression, expressionBucket, resetExpressionBucket } = useExpressionBucket();
  const initialPlanification = { clientId: currentOrganization?.clientId, expressionBucket, estimation: 0 };
  const [planification, setPlanification] = useState<Planning>(initialPlanification);

  const invalidateQuery = () => {
    queryClient.invalidateQueries([GET_BLUEPRINTS]);
  };

  const hasValidPlanification = planification && hasValidExpression && countrySelected;

  const onSavePlanning = async (props: { name: string; folder: string }) => {
    const { name, folder } = props;
    if (hasValidPlanification) {
      const result = await services.saveBlueprint(
        {
          estimation: 0,
          name: `${folder || ''} | ${name}`,
          expression: expressionBucketToBucketExpressionModel(expressionBucket),
          country: countrySelected
        },
        currentOrganization?.clientId
      );
      if (result) {
        const fullName = `${folder || ''} | ${name}`;
        setPlanification({ ...planification, ...result, name: fullName });
        dispatch(setCurrentPlanification({ ...result, name: fullName }));
      }
      invalidateQuery();
      return result;
    }
    return null;
  };

  const cancelPlanning = async () => {
    setPlanification(initialPlanification);
    dispatch(resetCurrentPlanification());
    resetExpressionBucket();
  };

  const { isLoading: isDownloading, error: errorDownloading, downloadFile } = useDownloadFile();

  const downloadPlanning = async () => {
    const name = planification?.name || `planning-${Math.round(Math.random() * 1000)}`;
    downloadFile(expressionBucket, name);
    trackEvent('DOWNLOADED_PLAN', {
      fileName: name,
      fileType: 'csv',
      segmentCount: countExpressionItems(expressionBucket),
      expression: JSON.stringify(expressionBucket)
    });
  };

  const { mutate: deleteCustomPlanning } = useDeletePlanning();

  return {
    resetQuery: invalidateQuery,
    planification,
    setPlanification,
    hasValidPlanification,
    savePlanning: useMutation(onSavePlanning),
    cancelPlanning,
    downloadPlanning,
    isDownloading,
    errorDownloading,
    currentOrganization,
    deleteCustomPlanning
  };
};

export default usePlanning;
