import React, { FC } from 'react';
import { Icon, Card, Flex, Box, Text, IconChip, Heading } from '@retargetly/ui-components';
import { IconDefinition } from '@fortawesome/pro-duotone-svg-icons';
import { Skeleton } from 'antd';
import { InfoTooltip } from 'components/Ui';
import { usLocalizeFormate } from 'utils/usLocalizeFormate';
import { useFormatMessage } from 'react-intl-hooks';
import { Error } from 'components/StateComponents';

type Props = {
  reachVolume: number;
  deviceVolume: number;
  icon: IconDefinition;
  isLoading: boolean;
  isError: boolean;
};

const localizeFormat = usLocalizeFormate.format(',.0f');

const ReachCard: FC<Props> = ({ reachVolume, deviceVolume, icon, isLoading, isError }) => {
  const t = useFormatMessage();
  return (
    <Card height="115px" width="100%">
      <Flex flexDirection="column" height="100%">
        {isError && <Error />}

        {isLoading && !isError && (
          <Flex h="100%" w="100%" align="center">
            <Skeleton
              active
              title={false}
              paragraph={{
                rows: 2,
                width: 100
              }}
            />
          </Flex>
        )}

        {reachVolume !== undefined && !isError && (
          <>
            <Flex flexDirection="row" justify="center" h="100%" flexGrow={1}>
              <Box mr={4} pt={4}>
                <IconChip
                  height="56px"
                  width="56px"
                  ico={<Icon icon={icon} color="info.500" name="user-group" size="lg" />}
                  ariaLabel=""
                />
              </Box>

              <Box flexDirection="column" pt={4}>
                <Flex flexDirection="row" alignItems="center">
                  <Text size="caption" align="center">
                    {`${t({ id: 'INSIGHTS.ESTIMATE.REACH' })}`.toUpperCase()}
                  </Text>
                  <Box pl={1}>
                    <InfoTooltip text={`${t({ id: 'INSIGHTS.ESTIMATE.REACH.TOOLTIP' })}`} size="xsm" />
                  </Box>
                </Flex>
                <Heading size="300">{localizeFormat(reachVolume / 3)}</Heading>
              </Box>
            </Flex>

            <Flex
              borderBottom="1px solid"
              borderBottomColor="neutrals.200"
              position="relative"
              bgColor="neutrals.100"
              alignItems="center"
              justify="center"
            >
              <Flex h="28px" w="100%" align="center" px={2}>
                <Flex flexDirection="row" align="center" flexGrow={1}>
                  <Box>
                    <Text size="bodySmall"> {`${t({ id: 'INSIGHTS.VOLUME' })}`}</Text>
                  </Box>
                  <Box mx={1}>
                    <InfoTooltip text={`${t({ id: 'INSIGHTS.VOLUME.TOOLTIP' })}`} size="xsm" />
                  </Box>
                </Flex>
                <Box>
                  <Text size="bodyBold">{localizeFormat(deviceVolume)}</Text>
                </Box>
              </Flex>
            </Flex>
          </>
        )}
      </Flex>
    </Card>
  );
};

export default ReachCard;
