import { RefObject } from 'react';
import html2canvas from 'html2canvas';

export const divToImg = async (SvgRef: RefObject<HTMLDivElement>, name: string) => {
  if (!SvgRef.current) return;

  html2canvas(SvgRef.current).then(canvas => {
    const img = canvas.toDataURL();

    const evt = new MouseEvent('click', {
      view: window,
      bubbles: false,
      cancelable: true
    });

    const a = document.createElement('a');
    a.setAttribute('download', `${name}.png`);
    a.setAttribute('href', img);
    a.setAttribute('target', '_blank');
    a.dispatchEvent(evt);
  });
};
