import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';

function tagRender({ label, closable, onClose }) {
  return (
    <Tag color="green" closable={closable} onClose={onClose} style={{ marginRight: 3 }}>
      {label}
    </Tag>
  );
}

tagRender.propTypes = {
  label: PropTypes.string.isRequired,
  closable: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
};

export default tagRender;
