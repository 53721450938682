/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import useTaxonomy from 'hooks/planning/useTaxonomy';
import { Box, Flex, Icon, Span, Text } from '@retargetly/ui-components';
import './TaxonomyList.scss';
import { Collapse, Skeleton } from 'antd';
import { useFormatMessage } from 'react-intl-hooks';
import { useHistory } from 'react-router-dom';
import { SEARCH } from 'constants/routes';
import { faCheck } from '@fortawesome/pro-duotone-svg-icons';
import { faChevronUp, faChevronDown } from '@fortawesome/pro-solid-svg-icons';
import { useTaxonomyContext } from '../context/hooks';

export const TaxonomyList = () => {
  const t = useFormatMessage();
  const { taxonomyMarketplace, taxonomyRetargetly, isLoading, idsSelectedSegments } = useTaxonomy();
  const { selectedListItem, handleListItemClick, countrySelected } = useTaxonomyContext();
  const { push } = useHistory();
  const marketplace = taxonomyMarketplace ? taxonomyMarketplace.childrens : [];
  const taxonomy = taxonomyRetargetly ? taxonomyRetargetly.childrens : [];

  const hasSelectedChildren = (item: any): boolean => {
    if (!selectedListItem || !item.childrens) {
      return false;
    }

    const anyChildSelected = item.childrens.some((child: any) => {
      if (idsSelectedSegments?.includes(child.id)) {
        return true;
      }
      if (child.childrens) {
        return hasSelectedChildren(child);
      }
      return false;
    });

    return anyChildSelected;
  };

  if (isLoading) {
    return (
      <div style={{ width: '200px' }}>
        <Skeleton loading={isLoading} active />
      </div>
    );
  }

  const clusterSelected = (item: { id: string }) =>
    selectedListItem?.id === item?.id ||
    selectedListItem?.parentId === item?.id ||
    selectedListItem?.childrens?.[0]?.rootParentId === item?.id;

  return (
    <Box
      sx={{
        overflowY: 'auto',
        overflowX: 'hidden'
      }}
    >
      <Box width="200px">
        <Flex flexDirection="column" mb={4} mt={1}>
          <Collapse
            defaultActiveKey="1"
            ghost
            expandIcon={({ isActive }) =>
              isActive ? (
                <Icon icon={faChevronUp} name="faChevronUp" color="primary.500" size="sm" />
              ) : (
                <Icon icon={faChevronDown} name="faChevronDown" color="primary.500" size="sm" />
              )
            }
          >
            <Collapse.Panel
              header={
                <Span fontSize="0.75rem" color="neutrals.400" textTransform="uppercase">
                  {t({
                    id: `TAXONOMY.LIST.TITLE_1`
                  })}
                </Span>
              }
              key="1"
              className="taxonomy-title-collapse"
            >
              <ul className="taxonomy-list">
                {countrySelected &&
                  taxonomy.map((item: any) => (
                    <li
                      key={item.id}
                      className={`taxonomy-list-item ${clusterSelected(item) ? 'selected' : ''}`}
                      onClick={() => {
                        push(SEARCH);
                        handleListItemClick(item);
                      }}
                    >
                      <button type="button" className="taxonomy-list-button">
                        <Text size="bodyBold">{item.name}</Text>
                        {hasSelectedChildren(item) && (
                          <Icon icon={faCheck} name="faCheck" color="primary.500" size="sm" />
                        )}
                      </button>
                    </li>
                  ))}
              </ul>
            </Collapse.Panel>
          </Collapse>
        </Flex>

        <Flex flexDirection="column">
          <Collapse
            defaultActiveKey="2"
            ghost
            expandIcon={({ isActive }) =>
              isActive ? (
                <Icon icon={faChevronUp} name="faChevronsUp" color="primary.500" size="sm" />
              ) : (
                <Icon icon={faChevronDown} name="faChevronDown" color="primary.500" size="sm" />
              )
            }
          >
            <Collapse.Panel
              header={
                <Span fontSize="0.75rem" color="neutrals.400" textTransform="uppercase">
                  {t({
                    id: `TAXONOMY.LIST.TITLE_2`
                  })}
                </Span>
              }
              key="2"
              className="taxonomy-title-collapse"
            >
              <ul className="taxonomy-list">
                {countrySelected &&
                  marketplace.map((item: any) => (
                    <li
                      key={item.id}
                      className={`taxonomy-list-item ${clusterSelected(item) ? 'selected' : ''}`}
                      onClick={() => {
                        handleListItemClick(item);
                        push(SEARCH);
                      }}
                    >
                      <Text size="bodyBold">{item.name}</Text>
                      {hasSelectedChildren(item) && (
                        <Icon icon={faCheck} name="faCheck" color="primary.500" size="sm" />
                      )}
                    </li>
                  ))}
              </ul>
            </Collapse.Panel>
          </Collapse>
        </Flex>
      </Box>
    </Box>
  );
};
