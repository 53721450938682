import getUrlDomain from 'utils/get-url-domain';

export const getDefaultLang = () => {
  const domain = getUrlDomain(window.location.href);
  switch (true) {
    case domain.indexOf('cadreon') !== -1:
      return 'es';
    default:
      return 'en';
  }
};
