import { useMutation, useQueryClient } from '@tanstack/react-query';
import useEventTracker from 'hooks/useEventTracker';
import { ServiceFactory } from 'services/FactoryService';
import { useFormatMessage } from 'react-intl-hooks';
import { message } from 'antd';
import useOrganization from 'hooks/useOrganization';
import { GET_BLUEPRINTS, Blueprint } from './useBlueprints';

const services = ServiceFactory.get('plannings');

export const useDeletePlanning = () => {
  const { trackEvent } = useEventTracker();
  const t = useFormatMessage();
  const { currentOrganization } = useOrganization();
  const queryClient = useQueryClient();
  return useMutation(services.removeCustomPlanning, {
    onMutate: async data => {
      /* Optimistic delete */
      /* Cancel any outgoing refetches (so they don't overwrite our optimistic delete) */
      await queryClient.cancelQueries([GET_BLUEPRINTS, currentOrganization]);
      /* Snapshot the previous value */
      const previousData: any = queryClient.getQueryData([GET_BLUEPRINTS, currentOrganization]);
      /* filter old data with the selected ids */
      // @ts-ignore
      const filterData = previousData.filter((item: Blueprint) => item.segmentId !== data?.blueprint?.segmentId);
      /* replace old data */
      queryClient.setQueriesData({ queryKey: [GET_BLUEPRINTS, currentOrganization], type: 'active' }, (old: any) => {
        return filterData;
      });
      /* Return a context object with the snapshotted value
      this will be the data if the fetch fails */
      return { previousData };
    },
    onSuccess: (data, variables, context) => {
      // @ts-ignore
      const { segmentId, name, folder } = variables?.blueprint;
      trackEvent('DELETE_PLAN', {
        id: segmentId,
        planningName: name,
        folder
      });
      queryClient.invalidateQueries([GET_BLUEPRINTS]);
    },
    onError: () => {
      message.error(t({ id: 'PLANIFICATION.WARNING.DELETE_ERROR' }));
    },
    // Always refetch after error or success:
    onSettled: () => {
      queryClient.invalidateQueries([GET_BLUEPRINTS]);
    }
  });
};
