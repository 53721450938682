import Repository from '../repository';

const getOptions = () => {
  const token = localStorage.getItem('token');
  const refreshtoken = localStorage.getItem('rtToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt: refreshtoken
    }
  };
};

export default {
  async getFeaturesList() {
    const src = '/feature/list';
    const list = await Repository.get(src, getOptions());
    return list.data.response;
  },
  addFinishedFeature(userId, featureId) {
    const src = '/feature/add';
    const payload = {
      features: [featureId],
      userIds: [userId]
    };
    return Repository.post(src, payload, getOptions());
  },
  removeTourFeature(userId, featureId) {
    const src = '/feature/remove';
    const payload = {
      features: [featureId],
      userIds: [userId]
    };
    return Repository.post(src, payload, getOptions());
  }
};
