import React, { FC } from 'react';
import { BrandIcons } from 'utils/interfaces';

const getSize = (size: BrandIcons['size']) => {
  switch (size) {
    case 'sm': {
      return {
        width: '15px',
        height: '15px'
      };
    }
    case 'md': {
      return {
        width: '20px',
        height: '20px'
      };
    }
    case 'lg': {
      return {
        width: '40px',
        height: '40px'
      };
    }
    default: {
      return {
        width: '15px',
        height: '15px'
      };
    }
  }
};

const AddManagerIcon: FC<BrandIcons> = ({ size = 'sm' }) => {
  const { width, height } = getSize(size);

  return (
    <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1915_7654)">
        <path
          d="M15.0814 8.96479L8.96478 15.0814C8.40678 15.6394 7.50163 15.6394 6.94363 15.0814C6.38502 14.5234 6.38563 13.6183 6.94363 13.0603L13.0609 6.94304C13.6189 6.38504 14.524 6.38504 15.082 6.94304C15.64 7.50165 15.64 8.40618 15.0814 8.96479Z"
          fill="#FBBC04"
        />
        <path
          d="M8.96459 15.0817C9.52286 14.5235 9.52286 13.6184 8.96459 13.0601C8.40633 12.5018 7.50121 12.5018 6.94295 13.0601C6.38469 13.6184 6.38469 14.5235 6.94295 15.0817C7.50121 15.64 8.40633 15.64 8.96459 15.0817Z"
          fill="#34A853"
        />
        <path
          d="M10.0484 3.93117L6.9895 6.98947L9.01065 9.01062L12.0696 5.95231C12.6276 5.39431 12.6276 4.48917 12.0696 3.93117C11.511 3.37255 10.6064 3.37255 10.0484 3.93117Z"
          fill="#FBBC04"
        />
        <path
          d="M5.95234 12.0694L9.01065 9.01114L6.9895 6.98999L3.9312 10.0483C3.3732 10.6063 3.3732 11.5114 3.9312 12.0694C4.4892 12.6274 5.39434 12.6274 5.95234 12.0694Z"
          fill="#3B80EF"
        />
        <path
          d="M9.01782 9.00423C9.57608 8.44597 9.57608 7.54085 9.01782 6.98259C8.45956 6.42432 7.55444 6.42432 6.99617 6.98259C6.43791 7.54085 6.43791 8.44597 6.99617 9.00423C7.55443 9.56249 8.45956 9.56249 9.01782 9.00423Z"
          fill="#34A853"
        />
        <path
          d="M9.05711 2.93989L2.93989 9.05711C2.38189 9.61511 1.47674 9.61511 0.918744 9.05711C0.360744 8.49911 0.360744 7.59397 0.918744 7.03597L7.03597 0.918744C7.59397 0.360744 8.49911 0.360744 9.05711 0.918744C9.61511 1.47674 9.61511 2.38189 9.05711 2.93989Z"
          fill="#3B80EF"
        />
        <path
          d="M8.04623 3.35906C8.83574 3.35906 9.47576 2.71904 9.47576 1.92953C9.47576 1.14002 8.83574 0.5 8.04623 0.5C7.25672 0.5 6.6167 1.14002 6.6167 1.92953C6.6167 2.71904 7.25672 3.35906 8.04623 3.35906Z"
          fill="#34A853"
        />
      </g>
      <defs>
        <clipPath id="clip0_1915_7654">
          <rect width="15" height="15" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default AddManagerIcon;
