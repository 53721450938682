import xandar from '../../../images/media/platforms/xandrIcon.svg';
import bluekai from '../../../images/media/platforms/bluekaiIcon.svg';
import mediamath from '../../../images/media/platforms/mediamathIcon.svg';
import googlemp from '../../../images/media/platforms/googlempIcon.svg';
import ttd from '../../../images/media/platforms/ttdIcon.svg';
import pubmatic from '../../../images/media/platforms/pubmatic.svg';
import verizon from '../../../images/media/platforms/verizonIcon.svg';
import basis from '../../../images/media/platforms/basisIcon.svg';
import gads from '../../../images/media/platforms/gadsIcon.svg';
import taboola from '../../../images/media/platforms/taboolaIcon.svg';
import teads from '../../../images/media/platforms/teadsIcon.svg';
import mgid from '../../../images/media/platforms/mgidIcon.svg';
import tiktok from '../../../images/media/platforms/tiktokIcon.svg';
import dmsEpsilon from '../../../images/media/platforms/dms-epsilonIcon.svg';
import equativ from '../../../images/media/platforms/equativIcon.svg';

export const getPlatformIcon = (platformId: unknown) => {
  const icons = {
    2: xandar,
    9: bluekai,
    10: mediamath,
    11: googlemp,
    13: ttd,
    14: pubmatic,
    22: verizon,
    23: basis,
    26: gads,
    39: taboola,
    51: teads,
    70: mgid,
    71: tiktok,
    72: dmsEpsilon,
    74: equativ,
    77: equativ
  };
  return icons[platformId as keyof typeof icons];
};
