/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
/* eslint-disable radix */
import mixpanel from 'mixpanel-browser';
import jwt_decode from 'jwt-decode';
import * as actionTypes from '../../constants/actionTypes';
import { setCurrentOrganization, getOrganizations } from '../organizations';
import storeConfig from '../../store/store';

const { persistor } = storeConfig();

// helper
const mapAuth0Permissions = permissions => {
  return permissions.reduce((acc, item) => {
    const [resource, action] = item.split(':');
    if (!acc[resource]) {
      acc[resource] = {};
    }
    acc[resource][action] = true;
    return acc;
  }, {});
};

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = payload => {
  return {
    type: actionTypes.AUTH_SUCCESS,
    payload
  };
};

export const authFail = error => {
  // TODO - remove this from the action
  mixpanel.track('USER_LOGIN_FAIL', {});
  return {
    type: actionTypes.AUTH_FAIL,
    payload: error
  };
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('rtToken');
  return {
    type: actionTypes.AUTH_LOGOUT
  };
};

export const fetchLogout = () => {
  return async dispatch => {
    try {
      dispatch({ type: actionTypes.AUTH_LOGOUT });
      localStorage.clear();
      persistor.flush();
    } catch (error) {
      console.log(error);
    }
  };
};

const setUserData = (accessToken, refreshToken, user) => {
  localStorage.setItem('token', accessToken);
  localStorage.setItem('rtToken', refreshToken);
  localStorage.setItem('expirationDate', user.exp);
  localStorage.setItem('userId', user.internal_user_id);
  localStorage.setItem('user', JSON.stringify(user));
};

export const setUserPermissions = permissions => {
  return {
    type: actionTypes.SET_USER_PERMISSIONS,
    payload: { permissions }
  };
};

/**
 * Registers Tour Hitting the Backend;
 * @param { number } userId
 */
export const loginFromToken = (token, rtToken, viewas = null, onError) => {
  return async dispatch => {
    try {
      dispatch(authStart());
      const user = jwt_decode(token);
      const userData = { ...user, id: user.internal_user_id };
      setUserData(token, rtToken, userData);
      dispatch(authSuccess({ accessToken: token, refreshToken: rtToken, user: userData }));
      dispatch(setUserPermissions(mapAuth0Permissions(user.permissions)));
      const orgSelected = viewas || user.main_org;
      const organizations = await dispatch(getOrganizations());
      const selectedOrganization = organizations.filter(
        organization => organization.clientId === parseInt(orgSelected)
      )[0];
      if (selectedOrganization === undefined) {
        dispatch(
          setCurrentOrganization({
            ...user.organization,
            clientId: user.main_org
          })
        );
      } else {
        await dispatch(
          setCurrentOrganization({
            ...selectedOrganization,
            id: selectedOrganization.clientId,
            clientId: selectedOrganization.clientId
          })
        );
      }

      // track the user cross device
      mixpanel.identify(user.internal_user_id);
      // checks if client account
      // eslint-disable-next-line no-unused-expressions
      user.email.includes('retargetly') || user.email.includes('epsilon')
        ? mixpanel.people.set({ clientAccount: false })
        : mixpanel.people.set({ clientAccount: true });
      // this set super properties to be attach to all track events
      mixpanel.register({
        userEmail: user.email,
        userID: userData.id,
        accountType: user.type
      });
      mixpanel.track('USER_LOGIN');
    } catch (error) {
      console.error('auth -> error', error);
      const errorResponse = error?.response?.data?.error;
      dispatch(authFail(errorResponse));
      onError();
    }
  };
};

export const hiddenUIModals = () => {
  return { type: actionTypes.HIDDEN_UI_MODAL };
};

export const everHiddenModalUI = () => {
  return { type: actionTypes.EVER_HIDDEN_UI_MODAL };
};

export const setAuthRedirectPath = path => {
  return {
    type: actionTypes.SET_AUTH_REDIRECT_PATH,
    path
  };
};

export const setToken = ({ accessToken, refreshToken }) => {
  return {
    type: actionTypes.SET_TOKEN,
    payload: { accessToken, refreshToken }
  };
};
