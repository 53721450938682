import produce from 'immer';
import { Organization } from 'utils/interfaces';

import {
  LOADING_ORGANIZATIONS,
  GET_ORGANIZATIONS,
  SET_CURRENT_ORGANIZATION,
  CLEAR_CURRENT_ORGANIZATION
} from '../constants/actionTypes';

const initialState = {
  currentOrganization: undefined,
  organizations: [] as Organization[],
  loadingOrganizations: false
};

export default (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_CURRENT_ORGANIZATION:
      return produce(state, draftState => {
        draftState.currentOrganization = action.payload;
      });
    case CLEAR_CURRENT_ORGANIZATION:
      return produce(state, draftState => {
        draftState.currentOrganization = undefined;
      });
    case GET_ORGANIZATIONS:
      return produce(state, draftState => {
        draftState.organizations = [...action.payload];
      });
    case LOADING_ORGANIZATIONS:
      return produce(state, draftState => {
        draftState.loadingOrganizations = action.payload !== undefined ? !!action.payload : !state.loadingOrganizations;
      });
    default:
      return state;
  }
};
