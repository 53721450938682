/* eslint-disable no-nested-ternary */
import React, { useEffect } from 'react';
import { Box } from '@retargetly/ui-components';
import { useTaxonomyContext } from 'components/Planner/context/hooks';
import { Segment, TaxonomyItem } from '../components/TaxonomyItem';
import { TaxonomyBreadcrumb } from '../components/TaxonomyBreadcrumb';
import useGetRegularSearch from '../../Search/hooks/useGetRegularSearch';
import ErrorPlanning from '../../Search/ErrorPlanning';
import EmptyStatePlanning from '../../Search/EmptyStatePlanning';
import EmptyStateBrief from '../../Search/EmptyStateBrief';
import EmptyPlanning from '../../Search/EmptyPlanning';
import PlanningLoader from '../../Search/PlanningLoader';
import '../../Search/SearchComponent.scss';
import { ContainerResultSearch } from '../components/ContainerResultSearch';

export const SearchPage: React.FC = () => {
  const {
    countrySelected,
    selectedListItem,
    queryToShow,
    searchIaResults,
    isLoading: isLoadingIaSearch,
    isIaError,
    handleSetSelectedListItem,
    useIaSearch
  } = useTaxonomyContext();

  const { data: searchResults, isFetching: isLoadingRegularSearch, isError } = useGetRegularSearch(
    queryToShow,
    countrySelected
  );

  const hasValidResults = searchResults?.length > 0 && queryToShow !== '';
  const hasNoResults = searchResults?.length === 0;

  const hasIaValidResults = searchIaResults?.length > 0;
  const hasIaNoResults = searchIaResults?.length === 0;

  useEffect(() => {
    if (queryToShow || hasIaValidResults || isLoadingIaSearch) {
      handleSetSelectedListItem({} as Segment);
    }
  }, [queryToShow]);

  if (isLoadingIaSearch || isLoadingRegularSearch) return <PlanningLoader />;

  return (
    <>
      {!hasIaValidResults && !hasValidResults && Object.keys(selectedListItem).length === 0 ? (
        useIaSearch ? (
          <EmptyStateBrief />
        ) : (
          <EmptyStatePlanning countrySelected={countrySelected} />
        )
      ) : null}

      {hasIaValidResults && !!selectedListItem && (
        <ContainerResultSearch>
          {searchIaResults?.map((segment: any) => (
            <TaxonomyItem
              key={segment?.id}
              segment={{
                ...segment,
                parentCategoryName: segment.segments?.[0]?.fullName,
                devices: segment.segments?.[0]?.devices || 0
              }}
            />
          ))}
        </ContainerResultSearch>
      )}

      {hasValidResults && !!selectedListItem ? (
        <ContainerResultSearch>
          {searchResults?.map((segment: any) =>
            segment?.segments?.map((segmentItem: any) => (
              <TaxonomyItem
                key={segmentItem?.id}
                segment={{
                  ...segmentItem,
                  parentCategoryName: segmentItem?.fullName,
                  devices: segmentItem?.devices || 0
                }}
              />
            ))
          )}
        </ContainerResultSearch>
      ) : null}

      {isError || isIaError ? (
        <Box height="500px" display="flex" flexGrow={1} flexDirection="column" justify="center" alignItems="center">
          <ErrorPlanning />
        </Box>
      ) : null}

      {hasNoResults || hasIaNoResults ? (
        <Box height="500px" display="flex" flexGrow={1} flexDirection="column" justify="center" alignItems="center">
          <EmptyPlanning />
        </Box>
      ) : null}

      {Object.keys(selectedListItem).length ? (
        <Box position="relative">
          <Box mb={1} position="sticky" top={0} backgroundColor="#fff" zIndex={9} pt={0.5}>
            <TaxonomyBreadcrumb />
          </Box>
          {selectedListItem?.childrens?.map((segment: any) => (
            <TaxonomyItem key={segment?.id} segment={{ ...segment, parentCategoryName: selectedListItem?.name }} />
          ))}
        </Box>
      ) : null}
    </>
  );
};
