export const sortObject = obj => {
  return Object.keys(obj)
    .sort()
    .reduce((result, key) => {
      const res = { ...result };
      res[key] = obj[key];
      return res;
    }, {});
};

export const compareValues = (key, order = 'asc') => {
  return function innerSort(a, b) {
    if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key)) {
      // property doesn't exist on either object
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};
