import React from 'react';
import { Text, Icon, Flex, Box } from '@retargetly/ui-components';
import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';
import { useFormatMessage } from 'react-intl-hooks';

const Error = () => {
  const t = useFormatMessage();
  return (
    <Flex flexDirection="column" minHeight="270px" alignItems="center" justifyContent="center" w="100%">
      <Box textAlign="center" pb="12px">
        <Icon icon={faCircleXmark} name="circle-xmark" size="md" color="red.500" />
      </Box>
      <Box textAlign="center" width="100%">
        <Text size="bodyDefault">{`${t({ id: 'GENERIC.INFO.ERROR' })}`}</Text>
      </Box>
    </Flex>
  );
};
export default Error;
