import React, { FC } from 'react';
import { Icon, Tooltip } from '@retargetly/ui-components';
import { faCircleInfo } from '@fortawesome/pro-duotone-svg-icons';

type Props = {
  text: string | undefined;
  size?: 'small' | 'sm' | 'md' | 'lg' | 'extraSmall' | 'medium' | 'large' | 'xsm';
};

const InfoTooltip: FC<Props> = ({ text, size = 'sm' }) => {
  return (
    <Tooltip placement="right" label={text} hasArrow>
      <span>
        <Icon icon={faCircleInfo} name="circle-info" color="neutrals.500" size={size} />
      </span>
    </Tooltip>
  );
};

export default InfoTooltip;
