import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Icon, Menu, MenuButton, MenuList, MenuGroup, MenuItem, MenuDivider } from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';
import { faChevronDown } from '@fortawesome/pro-duotone-svg-icons';
import useEventTracker from 'hooks/useEventTracker';

const ExpressionConnector = ({ bucketKey, operator, onChangeOperator }) => {
  const { trackEvent } = useEventTracker();
  const t = useFormatMessage();
  const handleAddCondition = newOperator => {
    trackEvent('ADDED_GROUP', {
      GroupName: bucketKey
    });
    onChangeOperator(bucketKey, newOperator);
  };
  return (
    <Flex width="100%" pl={4} height="20px" align="center" position="relative">
      <Menu strategy="fixed">
        <MenuButton
          variant="secondary"
          height="32px"
          display="flex"
          padding="6px 8px 6px 12px"
          border="1px solid #E3E6EE"
          backgroundColor="#FFFFFF"
          color="#424250"
          rightIcon={<Icon icon={faChevronDown} name="laptop-house" className="svgPrimary" size="sm" />}
        >
          <span style={{ marginRight: '0.5rem' }}>
            {t({
              id: `GENERIC.LABEL.${operator}`
            })}
          </span>
        </MenuButton>

        <MenuList>
          <MenuGroup
            title={t({
              id: `PLANIFICATION.INFO.NEWGROUP_COND`
            })}
          >
            <MenuItem onClick={() => handleAddCondition('AND')}>
              {t({
                id: `GENERIC.LABEL.AND`
              })}
            </MenuItem>
            <MenuItem onClick={() => handleAddCondition('OR')}>
              {t({
                id: `GENERIC.LABEL.OR`
              })}
            </MenuItem>
          </MenuGroup>
          <MenuDivider />
          <MenuGroup
            title={t({
              id: `PLANIFICATION.INFO.NEWGROUP_EXC`
            })}
          >
            <MenuItem onClick={() => handleAddCondition('NOT')}>
              {t({
                id: `GENERIC.LABEL.NOT`
              })}
            </MenuItem>
          </MenuGroup>
        </MenuList>
      </Menu>
    </Flex>
  );
};

ExpressionConnector.propTypes = {
  bucketKey: PropTypes.number.isRequired,
  operator: PropTypes.string,
  onChangeOperator: PropTypes.func.isRequired
};

ExpressionConnector.defaultProps = {
  operator: 'OR'
};

export default ExpressionConnector;
