import React, { FC } from 'react';
import { Text, Flex } from '@retargetly/ui-components';
import { useMapContext } from 'components/GeoTool/context/Map/MapProvider';
import { Feature } from '../SearchBar/types';

type Props = {
  places?: Feature[];
  onSelect: any;
};

const GeoSearchResult: FC<Props> = ({ places, onSelect }) => {
  const { goToPoint } = useMapContext();

  const Places = ({ place, index }: any) => {
    const handleLocationSelected = () => {
      onSelect(place.place_name);
      goToPoint(place.center[0], place.center[1]);
    };

    return (
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        height="40px"
        width="100%"
        paddingLeft="12px"
        marginBottom="4px"
        marginTop="4px"
        _hover={{
          backgroundColor: '#cac9c9'
        }}
        onClick={handleLocationSelected}
        role="button"
        tabIndex={index}
      >
        <Text size="bodyDefault">{place.place_name}</Text>
      </Flex>
    );
  };

  return (
    <Flex
      flexDirection="column"
      height="100%"
      width="100%"
      borderRadius="4px"
      backgroundColor="white"
      border="1px solid #E3E6EE"
      cursor="pointer"
      zIndex="101"
    >
      {places?.map((item: any, index) => (
        <Places key={item.id} place={item} index={index} />
      ))}
    </Flex>
  );
};

export default GeoSearchResult;
