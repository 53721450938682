import React, { useEffect } from 'react';
import { ServiceFactory } from 'services/FactoryService';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearCurrentOrganization } from 'actions/organizations/index';
import * as routes from '../../constants/routes';

const authFactory = ServiceFactory.get('auth');

export function AuthSingleSignOn() {
  const history = useHistory();
  const dispatch = useDispatch();
  dispatch(clearCurrentOrganization());

  useEffect(() => {
    authFactory
      .authWithSingleSignOn()
      // eslint-disable-next-line camelcase
      .then((response: { data: { redirect_to: string } }) => {
        window.location.href = response.data.redirect_to;
      })
      .catch(() => {
        history.push(routes.WELCOME);
      });
  }, []);

  return <div />;
}
