// SEGMENTS

export const SEARCH_SEG_BY_ISO = 'SEARCH_SEG_BY_ISO';

export const LOAD_GROUPS_OF_SEGMENTS = 'LOAD_GROUPS_OF_SEGMENTS';

export const LOAD_CUSTOM_PLANNINGS = 'LOAD_CUSTOM_PLANNINGS';

export const EDIT_CUSTOM_PLANNING = 'EDIT_CUSTOM_PLANNING';

export const ADD_SELECTED_SEGMENTS_IDS = 'ADD_SELECTED_SEGMENTS_IDS';

export const REMOVE_FROM_SELECTED_SEGMENTS = 'REMOVE_FROM_SELECTED_SEGMENTS';

export const SET_SELECTED_SEGMENTS = 'SET_SELECTED_SEGMENTS';

export const SET_SELECTED_EXPRESSION_GROUP = 'SET_SELECTED_EXPRESSION_GROUP';

export const ADD_SEGMENTS_IDS_TO_EXPRESSION_GROUP = 'ADD_SEGMENTS_IDS_TO_EXPRESSION_GROUP';

export const REMOVE_SEGMENTS_IDS_FROM_EXPRESSION_GROUP = 'REMOVE_SEGMENTS_IDS_FROM_EXPRESSION_GROUP';

export const SET_NEW_EXPRESSION_GROUP = 'SET_NEW_EXPRESSION_GROUP';

export const RESET_EXPRESSION_GROUP = 'RESET_EXPRESSION_GROUP';

export const REMOVE_CONDITION = 'REMOVE_CONDITION';

export const RESET_EXPRESSION_CONDITION = 'RESET_EXPRESSION_CONDITION';

export const RESET_SELECTED_SEGMENTS = 'RESET_SELECTED_SEGMENTS';

export const ADD_AVAILABLE_EXPRESSION_GROUP = 'ADD_AVAILABLE_EXPRESSION_GROUP';

export const LOAD_GUIDED_PLANIFICATIONS = 'LOAD_GUIDED_PLANIFICATIONS';

export const SEARCH_SEGMENTS = 'SEARCH_SEGMENTS';

export const ADD_COUNTRIES_SELECTED = 'ADD_COUNTRIES_SELECTED';

export const GET_PLATFORMS_AND_SEATS = 'GET_PLATFORMS_AND_SEATS';

export const SET_SEGMENT_RECENCY = 'SET_SEGMENT_RECENCY';

export const SET_RECENCY_FILTER = 'SET_RECENCY_FILTER';

export const ADD_PLANNING_MODAL = 'ADD_PLANNING_MODAL';

export const SET_ALL_COUNTRIES = 'SET_ALL_COUNTRIES';

// EXPRESSIONS

export const SET_COUNTRY_SELECTED = 'SET_COUNTRY_SELECTED';

export const ADD_SEGMENTS_IDS_TO_EXPRESSION_BUCKET = 'ADD_SEGMENTS_IDS_TO_EXPRESSION_BUCKET';

export const ADD_BUCKET_TO_EXPRESSION_BUCKET = 'ADD_BUCKET_TO_EXPRESSION_BUCKET';

export const REMOVE_SEGMENTS_FROM_EXPRESSION_BUCKET = 'REMOVE_SEGMENTS_FROM_EXPRESSION_BUCKET';

export const REMOVE_BUCKET_TO_EXPRESSION_BUCKET = 'REMOVE_BUCKET_TO_EXPRESSION_BUCKET';

export const SET_SELECTED_EXPRESSION_BUCKET = 'SET_SELECTED_EXPRESSION_BUCKET';

export const SET_INTERNAL_OPERATOR_TO_EXPRESSION_BUCKET = 'SET_INTERNAL_OPERATOR_TO_EXPRESSION_BUCKET';

export const SET_EXTERNAL_OPERATOR_TO_EXPRESSION_BUCKET = 'SET_EXTERNAL_OPERATOR_TO_EXPRESSION_BUCKET';

export const RESET_EXPRESSION_BUCKET = 'RESET_EXPRESSION_BUCKET';

export const SET_CURRENT_PLANIFICATION = 'SET_CURRENT_PLANIFICATION';

export const REST_CURRENT_PLANIFICATION = 'REST_CURRENT_PLANIFICATION';

// INSIGHTS

export const TRIGGER_ESTIMATION = 'TRIGGER_ESTIMATION';

export const LOAD_ESTIMATIONS = 'LOAD_ESTIMATIONS';

export const LOAD_TABLE_ESTIMATIONS = 'LOAD_TABLE_ESTIMATIONS';

export const LOAD_BOT_DATA = 'LOAD_BOT_DATA';

export const SET_AVATAR_PROPS = 'SET_AVATAR_PROPS';

// UI

export const TOGGLE_PANEL_RIGHT = 'TOGGLE_PANEL_RIGHT';

export const TOGGLE_MODAL = 'TOGGLE_MODAL';

export const TOGGLE_QUESTION_MODAL = 'TOGGLE_QUESTION_MODAL';

export const TOGGLE_WELCOME = 'TOGGLE_WELCOME';

export const TOGGLE_GUIDE_MODAL = 'TOGGLE_GUIDE_MODAL';

export const TOGGLE_TOUR = 'TOGGLE_TOUR';

export const TOGGLE_LOGOUT = 'TOGGLE_LOGOUT';

export const SET_GENERAL_ERROR = 'SET_GENERAL_ERROR';

export const TOGGLE_SHARE_MODAL = 'TOGGLE_SHARE_MODAL';

export const TOGGLE_PLANIF_MODAL = 'TOGGLE_PLANIF_MODAL';

export const TOGGLE_PASSWORD_MODAL = 'TOGGLE_PASSWORD_MODAL';

export const REFRESH_INSIGHT_DATA = 'REFRESH_INSIGHT_DATA';

export const SET_THEME = 'SET_THEME';

// AUTH

export const SET_TOKEN = 'SET_TOKEN';

export const AUTH_START = 'AUTH_START';

export const AUTH_SUCCESS = 'AUTH_SUCCESS';

export const AUTH_FAIL = 'AUTH_FAIL';

export const AUTH_FETCH_USER = 'AUTH_FETCH_USER';

export const AUTH_LOGOUT = 'AUTH_LOGOUT';

export const SET_AUTH_REDIRECT_PATH = 'SET_AUTH_REDIRECT_PATH';

export const REGISTER_TOUR = 'REGISTER_TOUR';

export const REGISTER_USER = 'REGISTER_USER';

export const SET_USER_PLAN_DATA = 'SET_USER_PLAN_DATA';

export const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';

export const HIDDEN_UI_MODAL = 'HIDDEN_UI_MODAL';

export const EVER_HIDDEN_UI_MODAL = 'EVER_HIDDEN_UI_MODAL';

// ORGANIZATION

export const GET_ORGANIZATIONS = 'GET_ORGANIZATIONS';

export const LOADING_ORGANIZATIONS = 'LOADING_ORGANIZATIONS';

export const SET_CURRENT_ORGANIZATION = 'SET_CURRENT_ORGANIZATION';

export const CLEAR_CURRENT_ORGANIZATION = 'CLEAR_CURRENT_ORGANIZATION';
