import repository from '../repository';

const getOptions = () => {
  const refreshtoken = localStorage.getItem('rtToken');
  const token = localStorage.getItem('token');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt: refreshtoken
    }
  };
};

const searchApiKey = process.env.REACT_APP_MAPBOX_SEARCH_API;
export default {
  async getPlaces(query, proximity) {
    const response = await repository.get(`https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json`, {
      params: {
        limit: 5,
        language: 'es',
        access_token: searchApiKey,
        query,
        proximity
      }
    });
    return response.data;
  },
  async getGeoCountries() {
    const countries = await repository.get('countries?feature=geo', getOptions());
    // const countries = await repository.get('countries', getOptions());
    return countries.data.response;
  }
};
