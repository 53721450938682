import { tresholdDevicesValueToShowChart as tresholdDevicesValue } from '../constants/config';

export const treeToMap = (data, isSub, level = 1) => {
  let childrens = [];
  let idsAndSegmentsIds = [];
  let levelNode = level;
  if (isSub) {
    levelNode += 1;
  }
  for (let i = 0, len = data.length; i < len; i += 1) {
    if (data[i].childrens && data[i].childrens.length !== 0) {
      childrens.push(data[i]);
      const [newChilds, newIds] = treeToMap(data[i].childrens, true, levelNode);
      childrens = childrens.concat(newChilds);
      idsAndSegmentsIds.push(data[i].id);
      idsAndSegmentsIds = idsAndSegmentsIds.concat(newIds);
    } else {
      childrens.push(data[i]);
      idsAndSegmentsIds.push(data[i].segmentId);
      idsAndSegmentsIds.push(data[i].id);
    }
  }
  return [childrens, idsAndSegmentsIds];
};

export const copyToClipboard = textToCopy => {
  const temp = document.createElement('INPUT');
  temp.value = textToCopy;
  const body = document.getElementsByTagName('body')[0];
  body.appendChild(temp);

  temp.select();
  temp.setSelectionRange(0, 99999);
  document.execCommand('copy');
  body.removeChild(temp);
};

export const downloadFile = (data, filename) => {
  try {
    const tempLink = document.createElement('a');
    let reportName;

    if (filename && filename !== undefined) {
      reportName = filename;
    } else {
      reportName = 'plannedAudience';
    }

    const csvData = new Blob([data], { type: 'text/csv' });

    tempLink.href = URL.createObjectURL(csvData);
    tempLink.setAttribute('download', `${reportName}.csv`);
    tempLink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
  } catch (error) {
    console.error(error);
  }
};

export const generateDomKey = () => {
  return Math.random()
    .toString(36)
    .substr(2, 9);
};

export const checkTreshold = dataToGraph => {
  if (dataToGraph.datasets && dataToGraph.datasets[0]?.data?.length > 0) {
    if (dataToGraph.totalDevices) {
      // for posibilities of nested arrays
      const valuesTotals = dataToGraph.totalDevices.flat();
      if (Math.max(...valuesTotals) < tresholdDevicesValue) {
        return false;
      }
    }
    if (
      dataToGraph.datasets.some(dataset => {
        return dataset.data.some(value => value === null);
      })
    ) {
      return false;
    }

    return true;
  }
  return false;
};

export const userFromMail = email => {
  return email.match(/^([^@]*)@/)[1];
};

export const randomItem = myArray => myArray[Math.floor(Math.random() * myArray.length)];

export const getUsedGroups = group => {
  const selectedAvailableGroups = [];
  Object.keys(group).forEach(key => {
    if (group[key].length > 0) selectedAvailableGroups.push(key);
  });
  return selectedAvailableGroups;
};
