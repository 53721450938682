import React from 'react';
import { Slider, Text, Flex, Box } from '@retargetly/ui-components';
import { Divider } from 'antd';
import { useLocationContext } from 'components/GeoTool/context/Locations/LocationProvider';
import { useFormatMessage } from 'react-intl-hooks';

const PinTool = () => {
  const { radius, setRadius } = useLocationContext();
  const t = useFormatMessage();
  return (
    <Box>
      <Box>
        <Box mb={2}>
          <Text size="bodyBold" align="left">
            {t({
              id: `GEOTOOL.ACTIONPANEL.PINTOOL.TITLE`
            })}
          </Text>
        </Box>
        <Box>
          <Text size="bodySmall" align="left">
            {t({
              id: `GEOTOOL.ACTIONPANEL.PINTOOL.DESCRIPTION`
            })}
          </Text>
        </Box>
        <Divider style={{ marginTop: '15px', marginBottom: '15px' }} />
      </Box>
      <Flex flexDirection="column" height="auto">
        <Box mb={2}>
          <Text size="bodyBold" align="left">
            {t({
              id: `GENERIC.LABEL.RADIUS`
            })}
          </Text>
        </Box>
        <Box>
          <Text size="bodySmall" align="left">
            {t({
              id: `GEOTOOL.ACTIONPANEL.PINTOOL.RADIUS.DESCRIPTION`
            })}
          </Text>
        </Box>
        <Flex height="50px">
          <Slider
            defaultValue={radius}
            min={100}
            max={10000}
            step={50}
            label="m"
            sliderValue={radius}
            setSliderValue={setRadius}
          />
        </Flex>
      </Flex>
    </Box>
  );
};

export default PinTool;
