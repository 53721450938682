import React from 'react';
import AsyncFlagProvider from 'components/FeatureFlagging/hocs/AsyncFlagProvider';
import mixpanel from 'mixpanel-browser';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider } from '@tanstack/react-query';
import { CookiesProvider } from 'react-cookie';
import AntThemeProvider from 'components/AntThemeProvider';
import OrganizationBaseProvider from 'components/Organization/OrganizationBaseProvider';
import SplashSpinner from 'components/SplashSpinner';
import { initialize as gtmStart } from './utils/gtmStarter';
import { userMonitoringStarter } from './hooks/SyntheticMonitoring/userMonitoringStarter';
import { queryClient } from './QueryClient';
import { I18nProvider } from './i18n/I18nProvider';
import configStore from './store/store';
import './styles/main.scss';
import App from './App';

const { store, persistor } = configStore();

/**
 * Starts gtmModule only for production environment
 */
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  gtmStart();
}

if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_API_MOCKING === 'enabled') {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

userMonitoringStarter();

// Initiates MixPanel tracker
mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
  debug: process.env.REACT_APP_ENVIRONMENT !== 'production',
  track_pageview: 'url-with-path',
  persistence: 'localStorage'
});

render(
  <AsyncFlagProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          <BrowserRouter>
            <I18nProvider>
              <PersistGate loading={<SplashSpinner />} persistor={persistor}>
                <App />
                <AntThemeProvider />
                <OrganizationBaseProvider />
              </PersistGate>
            </I18nProvider>
          </BrowserRouter>
        </CookiesProvider>
      </QueryClientProvider>
    </Provider>
  </AsyncFlagProvider>,

  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
