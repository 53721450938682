import React from 'react';
import { Flex, Text, FailSvg } from '@retargetly/ui-components';
import { useFormatMessage } from 'react-intl-hooks';

export const ErrorPlanning = () => {
  const t = useFormatMessage();
  return (
    <Flex flexDirection="column" width="100%" alignItems="center" justifyContent="center">
      <Flex
        w="calc(100% - 490px)"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxW="300px"
        maxH="300px"
        minW="80px"
        minH="80px"
      >
        <FailSvg />
      </Flex>
      <Text size="bodyDefault" color="black">
        {t({
          id: `SEARCH.ERROR.TITLE`
        })}
      </Text>
    </Flex>
  );
};
