import React, { useEffect } from 'react';
import { useFormatMessage } from 'react-intl-hooks';
import { CDN_URL } from 'constants/config';
import { useHistory, Link } from 'react-router-dom';
import { Box, Button, Stack, Text } from '@retargetly/ui-components';
import useAuth from '../../hooks/useAuth';
import * as routes from '../../constants/routes';

export const toAbsoluteUrl = (pathname: string) => process.env.PUBLIC_URL + pathname;

export const authCard = {
  backgroundColor: 'white',
  margin: '0 auto',
  padding: '60px',
  borderRadius: '4px',
  textAlign: 'center',
  width: '500px',
  minHeight: '300px',
  justifyContent: 'space-between',
  boxShadow: '0px 4px 6px -2px rgba(0, 0, 0, 0.05), 0px 10px 15px -3px rgba(0, 0, 0, 0.10)'
};

const Welcome = () => {
  const t = useFormatMessage();
  const history = useHistory();
  const { isLoggedIn } = useAuth();

  useEffect(() => {
    if (isLoggedIn) {
      history.push('/');
    }
  }, [isLoggedIn, history]);

  return (
    <Stack h="100vh" alignItems="center" justifyContent="center" sx={{ backgroundColor: '#f3f6f9' }}>
      <Stack as="form" gap={6} data-testid="login-form" style={authCard}>
        <Link to="/">
          <Stack alignItems="center">
            <img alt="Logo" width={220} src={`${CDN_URL}/images/loop/Loop.svg`} />
          </Stack>
        </Link>

        <Box sx={{ textAlign: 'center' }}>
          <Text size="bodyDefault">{t({ id: 'AUTH.TEXT.WELCOME' })}</Text>
        </Box>

        <Button
          onClick={() => history.push(routes.SINGLE_SIGN_ON_AUTH)}
          isLoading={false}
          label={`${t({ id: 'AUTH.ENTER.BUTTON' })}`}
        />
      </Stack>
    </Stack>
  );
};

export default Welcome;
