import { createContext } from 'react';
import { Feature } from './types';

type LocationContextProps = {
  isLoading: boolean;
  userLocation?: [number, number];
  isLoadingPlaces: boolean;
  places: Feature[];
  radius: number;
  locations: Feature[];
  addPlaces: (places: Feature) => void;
  setRadius: (radius: number) => void;
  deletePlaces: (id: string) => void;
  resetPlaces: () => void;
};

export const LocationContext = createContext({} as LocationContextProps);
