import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as actions from '../actions';
import * as selectors from '../selectors';

const useUIContext = () => {
  const dispatch = useDispatch();

  const [isLeftMenuOpen, setIsLeftMenuOpen] = useState(false);

  return {
    // Plannification Modal
    isPlanificationModalOpen: useSelector(selectors.getPlanifModal),
    setIsPlanificationModalOpen: (show: boolean) => dispatch(actions.togglePlanifModal(show)),

    // Right Menu
    isRightMenuOpen: useSelector(selectors.getRightPanel),
    setIsRightMenuOpen: (show?: boolean) => dispatch(actions.togglePanelRight(show)),

    // Left Menu
    isLeftMenuOpen,
    setIsLeftMenuOpen
  };
};

export default useUIContext;
