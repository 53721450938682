/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { SaveShareSteps } from './Steps/SaveShareSteps';

const SharedPlanificationModal = () => {
  return <SaveShareSteps />;
};

SharedPlanificationModal.propTypes = {};

SharedPlanificationModal.defaultProps = {};

export default SharedPlanificationModal;
