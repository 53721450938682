import * as actionTypes from '../constants/actionTypes';
import { updateObject } from '../utils/objectUpdater';

export const initialState = {
  isAuthorized: false || !!localStorage.getItem('token'),
  token: localStorage.getItem('token') || undefined,
  refreshToken: localStorage.getItem('rtToken') || undefined,
  loading: false,
  user: JSON.parse(localStorage.getItem('user')) || {},
  userId: localStorage.getItem('userId') || {},
  error: null,
  registeredUser: null,
  permissions: JSON.parse(localStorage.getItem('permissions')) || {},
  showNewUIModal: true,
  everHiddenModalReDesing: false,
  planMetadata: {
    expireDate: '',
    daysRemaining: 0,
    activeUsers: 1,
    availableUsers: 2,
    totalUsers: 3,
    trial: true
  }
};

const authStart = state => {
  return updateObject(state, { error: null, loading: true });
};

const authSuccess = (state, payload) => {
  const { accessToken, refreshToken, user } = payload;

  return updateObject(state, {
    token: accessToken,
    refreshToken,
    user,
    isAuthorized: true,
    error: null,
    loading: false,
    showNewUIModal: true
  });
};

const setToken = (state, payload) => {
  const { accessToken, refreshToken } = payload;

  return updateObject(state, {
    token: accessToken,
    refreshToken
  });
};

const authFail = (state, error) => {
  return updateObject(state, {
    error,
    loading: false
  });
};

const authFetchUser = (state, payload) => {
  const { user } = payload;
  return updateObject(state, { user });
};

const authLogout = state => {
  return updateObject(state, {
    token: null,
    refreshToken: null,
    user: {},
    isAuthorized: false,
    error: null,
    loading: false,
    userId: {}
  });
};

const registerUser = (state, payload) => {
  return updateObject(state, {
    registeredUser: payload
  });
};

const setUserPlanData = (state, payload) => {
  return updateObject(state, { ...payload });
};

const setUserPermissions = (state, payload) => {
  return updateObject(state, { ...payload });
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.AUTH_START:
      return authStart(state);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, payload);
    case actionTypes.AUTH_FAIL:
      return authFail(state, payload);
    case actionTypes.AUTH_FETCH_USER:
      return authFetchUser(state, payload);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state);
    case actionTypes.REGISTER_USER:
      return registerUser(state, payload);
    case actionTypes.SET_USER_PLAN_DATA:
      return setUserPlanData(state, payload);
    case actionTypes.SET_USER_PERMISSIONS:
      return setUserPermissions(state, payload);
    case actionTypes.HIDDEN_UI_MODAL: {
      return updateObject(state, { showNewUIModal: false });
    }
    case actionTypes.EVER_HIDDEN_UI_MODAL: {
      return updateObject(state, { everHiddenModalReDesing: true });
    }
    case actionTypes.SET_TOKEN: {
      return setToken(state, payload);
    }

    default:
      return state;
  }
};
