// Segments selectors
export const getExpressionBucket = state => state.segments.expressionBucket;
export const getSelectedBucket = state => state.segments.selectedBucket;

export const isEmptyExpressionBucket = state => {
  let emptyExpression = true;
  state.segments.expressionBucket.forEach(idx => {
    if (state.segments.expressionBucket[idx].segments.length > 0) {
      emptyExpression = false;
    }
  });
  return emptyExpression;
};

export const getCountrySelected = state => state.segments.selectedCountry;

export const getCurrentPlanification = state => state.customPlanifications.currentPlanification;
