import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import useAuth from '../../hooks/useAuth';
import * as Routes from '../../constants/routes';

const SSOLogout = () => {
  const history = useHistory();
  const { onLogOut, userAuth } = useAuth();
  const [, , removeCookie] = useCookies(['jwtToken', 'rtToken']);

  useEffect(() => {
    removeCookie('jwtToken', {
      path: '/',
      domain: process.env.REACT_APP_API_DOMAIN,
      sameSite: false
    });
    removeCookie('rtToken', {
      path: '/',
      domain: process.env.REACT_APP_API_DOMAIN,
      sameSite: false
    });
    onLogOut();
  }, []);

  useEffect(() => {
    if (userAuth) {
      history.push(Routes.SINGLE_SIGN_ON_AUTH);
    }
  }, [userAuth]);

  return <div />;
};

export default SSOLogout;
