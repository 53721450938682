import useExpressionBucket from 'hooks/planning/useExpressionBucket';
import useTaxonomy from 'hooks/planning/useTaxonomy';
import { Segment } from '../components/TaxonomyItem';

const useTaxonomyItem = (segment: Segment) => {
  const { allSegments, idsSelectedSegments } = useTaxonomy();
  const { addSegmentsToExpression, removeSegmentsToExpression } = useExpressionBucket();

  const getSegmentsFromIds = (ids: any) => {
    return ids.map((id: any) => allSegments.get(id));
  };

  const searchRecursive = (currentSegment: Segment, ids: string[]) => {
    if (!ids.includes(currentSegment.id)) {
      ids.push(currentSegment.id);
    }

    if (currentSegment?.childrens?.length) {
      currentSegment.childrens.forEach((child: Segment) => {
        if (!child.childrens || child.childrens.length === 0) {
          ids.push(child.id);
        }
        if (child.childrens && child.childrens.length > 0) {
          searchRecursive(child, ids);
        }
      });
    }

    if (currentSegment?.segments?.length) {
      currentSegment.segments.forEach((child: Segment) => {
        ids.push(child.id);
      });
    }

    return ids;
  };

  const isSelected = (segments: string[]) => {
    return segments.every((s: string) => idsSelectedSegments?.includes(s));
  };

  // verify if all children are selected in segment: Segment
  const isChildrenSelected = (s: Segment) => {
    if (s?.childrens) {
      return s.childrens.every((child: Segment) => idsSelectedSegments?.includes(child.id));
    }
    return false;
  };

  const getNotSelected = (segmentIds: string[]) => {
    return segmentIds.filter((s: string) => !idsSelectedSegments?.includes(s));
  };

  const onCheck = () => {
    let segmentIds: string[] = [];

    if (segment?.childrens || segment?.segments) {
      segmentIds = searchRecursive(segment, []);
    } else {
      segmentIds.push(segment.id);
    }

    if (segment?.childrens && !isChildrenSelected(segment)) {
      segmentIds = getNotSelected(segmentIds);
    }

    getSegmentsFromIds(segmentIds).forEach((segm: Segment) => {
      if (segm.id && Array.isArray(idsSelectedSegments) && idsSelectedSegments.includes(segm.id)) {
        removeSegmentsToExpression([segm]);
        // if parent is selected, remove parent from expression
        if (isSelected([segm.parentId])) {
          removeSegmentsToExpression([allSegments.get(segm.parentId)]);
        }
      } else {
        addSegmentsToExpression([segm]);
        // if all children are selected, add parent to expression
        if (isChildrenSelected(getSegmentsFromIds([segm.parentId]))) {
          addSegmentsToExpression([allSegments.get(segm.parentId)]);
        }
      }
    });
  };

  return { onCheck, idsSelectedSegments };
};

export default useTaxonomyItem;
