import { compareValues } from '../utils/sorter';

// Auth selectors
export const getUserOrganization = state => state.auth.user?.organization?.id;

// UI selectors
export const getRightPanel = state => state.ui.rightPanelShow;
export const getGuidedModal = state => state.ui.guidedModalShow;
export const getModal = state => state.ui.modalShow;
export const getShowTutorial = state => state.ui.showTutorial;
export const getUser = state => state.auth.user;
export const isAuthorized = state => state.auth.isAuthorized;
export const getUserId = state => state.auth.userId;
export const getUserPlanData = state => {
  return {
    plan: state.auth.plan,
    role: state.auth.role,
    ...state.auth.planMetadata
  };
};
export const getLogOutScreen = state => state.ui.logOutScreen;
export const getGlobalErrors = state => state.ui.errors;
export const getShareModal = state => state.ui.showShareModal;
export const getPlanifModal = state => state.ui.planifModal;
export const getPasswordModal = state => state.ui.passwordModal;
export const refreshInsightData = state => state.ui.refreshInsightData;

// Segments selectors
export const getSelectedIdSegments = state => state.segments.selectedIdSegments;
export const getSelectedPlanif = state => state.customPlanifications.selectedPlanning;
export const getPlanningModalObject = state => state.customPlanifications.modalPlanning;
export const getPlatforms = state => state.customPlanifications.platformsPlanification;

// Search selectors
export const getSearchQuery = state => state.searchSegments.query;
export const getSearchResults = state => state.searchSegments.results;

// Insights Selectors
export const getTotalDevices = state => state.insights.total_devices;
export const getWebMobileDevices = state => state.insights.web_mobile_devices;
export const getWebNoMobileDevices = state => state.insights.web_no_mobile_devices;
export const getIosDevices = state => state.insights.ios_devices;
export const getAndroidDevices = state => state.insights.android_devices;
export const getPeople = state => state.insights.people;
export const getDevicesByPlatform = state => {
  const theOrder = ['Google', 'Facebook', 'MediaMath', 'AppNexus'];
  const platformsOrdered = {};
  for (let i = 0; i < theOrder.length; i++) {
    platformsOrdered[theOrder[i]] = i;
  }
  const copy = [...state.insights.devices_by_platform];
  return copy.sort((a, b) => {
    return platformsOrdered[a.name] - platformsOrdered[b.name] || a.name.localeCompare(b.name);
  });
};
export const getDevicesByCountryAndPlatform = state => state.insights.devices_by_country_and_platform;
export const getTopCountries = state => state.insights.top_countries;
// TODO: This is a Fix that needs to be addresed by the Back End Team
export const getTopClusters = state => {
  const topCluster = state.insights.top_clusters.filter(
    el => el.segments.length > 0 && el.segments.some(subel => subel.clusterId !== 16)
  );
  // console.log(topCluster);
  if (topCluster[1] && topCluster[1].clusterName === 'Rango de Edad') {
    topCluster[1].segments.sort(compareValues('name'));
  }
  // console.log(topCluster);
  return topCluster;
};
export const getDemographicOverlap = state => state.insights.demographic_overlap;
export const getTotalRow = state => {
  const theOrder = ['Google', 'Facebook', 'MediaMath', 'AppNexus'];
  const platformsOrdered = {};
  for (let i = 0; i < theOrder.length; i++) {
    platformsOrdered[theOrder[i]] = i;
  }
  const copy = state.insights.totalRow ? [...state.insights.totalRow] : [];
  return copy.sort((a, b) => {
    return platformsOrdered[a.name] - platformsOrdered[b.name] || a.name.localeCompare(b.name);
  });
};

export const getModalsUI = ({ auth }) => ({
  showNewUIModal: auth?.showNewUIModal,
  everHiddenModalReDesing: auth?.everHiddenModalReDesing
});
