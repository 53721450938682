import produce from 'immer';

import {
  TOGGLE_PANEL_RIGHT,
  TOGGLE_MODAL,
  TOGGLE_GUIDE_MODAL,
  TOGGLE_TOUR,
  TOGGLE_WELCOME,
  TOGGLE_LOGOUT,
  SET_GENERAL_ERROR,
  TOGGLE_SHARE_MODAL,
  TOGGLE_PLANIF_MODAL,
  TOGGLE_PASSWORD_MODAL,
  REFRESH_INSIGHT_DATA,
  TOGGLE_QUESTION_MODAL
} from '../constants/actionTypes';

export const initialState = {
  rightPanelShow: false,
  modalShow: false,
  guidedModalShow: false,
  showTutorial: true,
  logOutScreen: false,
  questionModal: false,
  welcomeModal: false,
  showShareModal: false,
  planifModal: false,
  passwordModal: false,
  refreshInsightData: false,
  errors: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_PANEL_RIGHT:
      return produce(state, draftState => {
        draftState.rightPanelShow = action.payload !== undefined ? !!action.payload : !state.rightPanelShow;
      });

    case TOGGLE_MODAL:
      return produce(state, draftState => {
        draftState.modalShow = action.payload !== undefined ? !!action.payload : !state.modalShow;
      });

    case TOGGLE_GUIDE_MODAL:
      return produce(state, draftState => {
        draftState.guidedModalShow = action.payload !== undefined ? !!action.payload : !state.guidedModalShow;
      });
    case TOGGLE_TOUR:
      return produce(state, draftState => {
        draftState.showTutorial = action.payload !== undefined ? !!action.payload : !state.showTutorial;
      });
    case TOGGLE_WELCOME:
      return produce(state, draftState => {
        draftState.welcomeModal = action.payload !== undefined ? !!action.payload : !state.welcomeModal;
      });
    case TOGGLE_LOGOUT:
      return produce(state, draftState => {
        draftState.logOutScreen = action.payload !== undefined ? !!action.payload : !state.logOutScreen;
      });
    case SET_GENERAL_ERROR:
      return produce(state, draftState => {
        draftState.errors.push(action.payload);
      });
    case TOGGLE_SHARE_MODAL:
      return produce(state, draftState => {
        draftState.showShareModal = action.payload !== undefined ? !!action.payload : !state.showShareModal;
      });
    case TOGGLE_PLANIF_MODAL:
      return produce(state, draftState => {
        draftState.planifModal = action.payload !== undefined ? !!action.payload : !state.planifModal;
      });
    case TOGGLE_PASSWORD_MODAL:
      return produce(state, draftState => {
        draftState.passwordModal = action.payload !== undefined ? !!action.payload : !state.passwordModal;
      });
    case TOGGLE_QUESTION_MODAL:
      return produce(state, draftState => {
        draftState.questionModal = action.payload !== undefined ? !!action.payload : !state.questionModal;
      });
    case REFRESH_INSIGHT_DATA:
      return produce(state, draftState => {
        draftState.refreshInsightData = action.payload;
      });
    default:
      return state;
  }
};
