/* eslint-disable no-useless-catch */
import repository from '../repository';

const getOptions = () => {
  const token = localStorage.getItem('token');
  const rt = localStorage.getItem('rtToken');
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      rt
    }
  };
};

const getKeywordVolumeEstimation = async (clientId, keywords, country, filter) => {
  const payload = {
    country: `${country}`,
    interval: `${filter}`,
    keywords
  };

  try {
    const response = await repository.post(`/segments/v3/keyword/estimate?view_as=${clientId}`, payload, getOptions());
    return response.data;
  } catch (error) {
    throw error;
  }
};

export default {
  async getGeoCountries() {
    const countries = await repository.get('countries?feature=geo', getOptions());
    return countries.data.response;
  },
  getKeywordVolumeEstimation
};
