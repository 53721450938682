import { useQuery } from '@tanstack/react-query';
import { ServiceFactory } from 'services/FactoryService';

const getThemes = (): Promise<any> => {
  const service = ServiceFactory.get('theme');
  return service.getAllThemes();
};

export const useGetAllThemes = () => {
  return useQuery(['clientThemes'], getThemes, {
    staleTime: 0
  });
};

export default useGetAllThemes;
