import React from 'react';
import { Modal as AntModal } from 'antd';

type Props = {
  open: boolean;
};

const Modal: React.FC<Props> = ({ open, children }) => {
  return (
    <AntModal visible={open} style={{ width: '450px', height: 'auto' }} footer={null} closable={false}>
      {children}
    </AntModal>
  );
};

export default Modal;
