/* eslint-disable prefer-destructuring */
import * as actions from '../../constants/actionTypes';
import { ServiceFactory } from '../../services/FactoryService';

const service = ServiceFactory.get('plannings');

export const loadCustomPlannings = customPlanningsList => {
  return {
    type: actions.LOAD_CUSTOM_PLANNINGS,
    payload: customPlanningsList
  };
};

export const loadEditedPlanning = customPlanning => {
  return {
    type: actions.EDIT_CUSTOM_PLANNING,
    payload: customPlanning
  };
};

export const addPlanningObject = id => {
  return {
    type: actions.ADD_PLANNING_MODAL,
    payload: id
  };
};

export const loadPlatforms = platforms => {
  return {
    type: actions.GET_PLATFORMS_AND_SEATS,
    payload: platforms
  };
};

export const getPlatformsAndSeats = clientId => {
  return async dispatch => {
    try {
      const platforms = await service.getPlatformsAndSeats(clientId);
      dispatch(loadPlatforms(platforms));
      return platforms;
    } catch (error) {
      console.error('[Planning Action Error Get Platforms and Seats]', error);
      return Promise.reject(new Error(error));
    }
  };
};

export const setCurrentPlanification = planification => {
  return {
    type: actions.SET_CURRENT_PLANIFICATION,
    payload: planification
  };
};

export const resetCurrentPlanification = () => {
  return {
    type: actions.REST_CURRENT_PLANIFICATION
  };
};
