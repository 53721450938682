import { useMutation } from '@tanstack/react-query';
import useEventTracker from 'hooks/useEventTracker';
import { Feature } from 'react-mapbox-gl';
import { ServiceFactory } from '../../services/FactoryService';

const planning = ServiceFactory.get('plannings');
interface payload {
  name: string;
  description: string;
  locations: Feature[];
  country: string;
  days: string;
  reset: () => void;
  clientId: number;
}

const toolMoreUsed = (locations: any) => {
  let pinCounter = 0;
  let bulkCounter = 0;
  let latLongCounter = 0;

  locations.map((loc: any) => {
    if (loc?.properties?.latLongTool === true) {
      latLongCounter += 1;
    }
    if (loc?.properties?.bulkTool === true) {
      bulkCounter += 1;
    }
    if (loc?.properties?.bulkTool === undefined && loc?.properties?.latLongTool === undefined) {
      pinCounter += 1;
    }
    return null;
  });

  let moreUsed = '';

  if (pinCounter >= bulkCounter && pinCounter >= latLongCounter) {
    moreUsed = 'pinTool';
  } else if (bulkCounter >= pinCounter && bulkCounter >= latLongCounter) {
    moreUsed = 'bulkTool';
  } else {
    moreUsed = 'latLongTool';
  }

  return moreUsed;
};

const createSegments = ({ name, description, locations, reset, clientId, country, days }: payload) => {
  const locationsArray = locations.map((loc: any) => {
    return {
      ...loc,
      geometry: {
        ...loc.geometry
      },
      properties: {
        ...loc.properties,
        radius: loc?.properties?.radiusInMeters ? loc?.properties?.radiusInMeters : loc?.properties?.radiusInKm * 1000
      }
    };
  });
  const response = planning.createGeoAudience(name, description, locationsArray, clientId, country, days).then(() => {
    // reset is used to reset the form after the mutation is done
    reset();
  });
  return response;
};

export const useCreateGeoAudience = (onSuccess: () => void, onError: (message: string) => void) => {
  const { trackEvent } = useEventTracker();
  return useMutation(createSegments, {
    onSuccess: (data, variables, context) => {
      const moreUsedTool = toolMoreUsed(variables.locations);
      trackEvent('GEO_AUDIENCE_CREATION', {
        country: variables?.country,
        days: variables?.days,
        locationsCount: variables?.locations?.length,
        toolUsed: moreUsedTool
      });
      onSuccess();
    },
    onError: (error: any) => {
      onError(error?.response?.data?.error);
    }
  });
};
