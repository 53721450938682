import mixpanel from 'mixpanel-browser';
import useOrganization from 'hooks/useOrganization';

const useEventTracker = () => {
  const { currentOrganization } = useOrganization();
  const customerSelectedInfo = {
    AccountID: currentOrganization?.id,
    AccountName: currentOrganization?.name
  };

  /**
   * @description method for tracking event and attaching account props
   * @param {string} name custom name for event to track
   * @param {object} [props] object containing additional properties
   */
  const trackEvent = (name: string, props?: object) => {
    mixpanel.track(name, {
      ...customerSelectedInfo,
      ...props
    });
  };

  /**
   * @description method for tracking pre-login events
   * @param {string} name custom name for event to track
   * @param {object} [props] object containing additional properties
   */
  const trackPrelogged = (name: string, props?: object) => {
    mixpanel.track(name, { ...props });
  };

  /**
   * @description method to start the timer targetting the MixPanel "Duration" prop. To stop it, {@link trackEvent} needs to be called specifying the same tracker name
   * @param {string} name custom name for event to track
   */
  const startTimeTracking = (name: string) => {
    mixpanel.time_event(name);
  };

  return { trackEvent, startTimeTracking, trackPrelogged };
};

export default useEventTracker;
