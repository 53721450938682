import React, { memo } from 'react';
import { Row, Typography } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import { useFormatMessage } from 'react-intl-hooks';
import usePrice from '../../../hooks/usePrice';
import { getPlatforms } from '../../../selectors';

interface Props {
  platformId: number;
}

const { Text } = Typography;

const PriceInfo: React.FC<Props> = ({ platformId }) => {
  const { isLoading, price } = usePrice(platformId);
  const platforms = useSelector(getPlatforms);
  const platformName = platforms.find((platform: any) => platform.id === platformId)?.name || 'generica';
  const t = useFormatMessage();

  return (
    <Row
      className="text--size-2"
      style={{ padding: '7px 10px', border: '1px solid', width: '100%', color: '#e0e0e0' }}
      justify="space-between"
      align="middle"
    >
      <Text style={{ color: '#707070' }}>
        {t({ id: 'MODAL.SHARE.AUDIENCE.VALUE' })} {platformName}
      </Text>
      {price !== null && <Text strong>{`USD ${price?.toFixed(2)}`}</Text>}
      {isLoading && <SyncOutlined spin />}
    </Row>
  );
};

export default memo(PriceInfo);
