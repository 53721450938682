import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useCookies } from 'react-cookie';
import PropTypes from 'prop-types';
import repository from '../../services/repository';
import * as actions from '../../actions';

const checkRequests = Wrapped => {
  const CheckRequests = props => {
    const { onTogglePanelRight, onLogout, onSetGlobalError, onSetToken } = props;
    const [cookie, setCookie, removeCookie] = useCookies(['jwtToken', 'rtToken']);

    const errorAuthRequestInterceptor = repository.interceptors.response.use(
      res => {
        if (res?.headers?.at && cookie.jwtToken !== undefined) {
          const cookieOptions = {
            path: '/',
            domain: process.env.REACT_APP_API_DOMAIN
          };
          setCookie('jwtToken', res.headers.at, cookieOptions);
          onSetToken({ accessToken: res?.headers?.at, refreshToken: cookie.rtToken });
        }
        return res;
      },
      error => {
        console.info('ReqId: ', error.response.headers['X-Request-Id']);
        switch (error.request.status) {
          case 401:
            onTogglePanelRight(false);
            removeCookie('jwtToken');
            removeCookie('rtToken');
            onLogout();
            break;
          case 403:
            onSetGlobalError({
              type: 'global',
              message: 'Permission denied, please contact your administrator.'
            });
            console.log('Access Denied');
            break;
          case 400:
          case 404:
            if (error?.request?.response) {
              const errorMsj = error.response.data;
              throw errorMsj;
            } else {
              throw new Error(error.request.status);
            }
          default:
            throw new Error('Error general');
        }

        return error;
      }
    );
    useEffect(() => {
      return () => {
        repository.interceptors.response.eject(errorAuthRequestInterceptor);
      };
    });

    return <Wrapped {...props} />;
  };
  const mapDispatchToProps = dispatch => {
    return {
      onTogglePanelRight: () => dispatch(actions.togglePanelRight()),
      onSetGlobalError: error => dispatch(actions.setGlobalError(error)),
      onLogout: () => dispatch(actions.logout()),
      onSetToken: (token, refreshToken) => dispatch(actions.setToken(token, refreshToken))
    };
  };

  CheckRequests.propTypes = {
    onTogglePanelRight: PropTypes.func.isRequired,
    onSetGlobalError: PropTypes.func.isRequired,
    onLogout: PropTypes.func.isRequired,
    onSetToken: PropTypes.func.isRequired
  };

  return connect(null, mapDispatchToProps)(CheckRequests);
};

export default checkRequests;
