import React from 'react';
import { Text, Flex, Button, Icon, Slider, Box } from '@retargetly/ui-components';
import { faPlus } from '@fortawesome/pro-duotone-svg-icons';
import { useLocationContext } from 'components/GeoTool/context/Locations/LocationProvider';
import { useMapContext } from 'components/GeoTool/context/Map/MapProvider';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useFormatMessage } from 'react-intl-hooks';
import Inputs from './Inputs/Inputs';

const LatLogTool = () => {
  const { radius, setRadius } = useLocationContext();
  const { addByLatAndLong } = useMapContext();
  const t = useFormatMessage();

  const handleAddLocation = (lat: string, lon: string) => {
    addByLatAndLong(parseFloat(lat), parseFloat(lon));
  };

  const latLongSchema = Yup.object().shape({
    lat: Yup.number(
      t({
        id: `GEOTOOL.ACTIONPANEL.VALIDATION.LATITUDE`
      })
    )
      .typeError(
        t({
          id: `GEOTOOL.ACTIONPANEL.VALIDATION.NUMBER`
        })
      )
      .min(
        -90,
        t({
          id: `GEOTOOL.ACTIONPANEL.VALIDATION.LATITUDE.MIN`
        })
      )
      .max(
        90,
        t({
          id: `GEOTOOL.ACTIONPANEL.VALIDATION.LATITUDE.MAX`
        })
      )
      .required(
        t({
          id: `GENERIC.LABEL.REQUIRED`
        })
      ),
    lon: Yup.number(
      t({
        id: `GEOTOOL.ACTIONPANEL.VALIDATION.LONGITUDE`
      })
    )
      .typeError(
        t({
          id: `GEOTOOL.ACTIONPANEL.VALIDATION.NUMBER`
        })
      )
      .min(
        -180,
        t({
          id: `GEOTOOL.ACTIONPANEL.VALIDATION.LONGITUDE.MIN`
        })
      )
      .max(
        180,
        t({
          id: `GEOTOOL.ACTIONPANEL.VALIDATION.LONGITUDE.MAX`
        })
      )
      .required(
        t({
          id: `GENERIC.LABEL.REQUIRED`
        })
      )
  });

  return (
    <div>
      <Formik
        initialValues={{
          lat: '',
          lon: ''
        }}
        validationSchema={latLongSchema}
        onSubmit={(values, actions) => {
          handleAddLocation(values.lat, values.lon);
          actions.resetForm({
            values: {
              lat: '',
              lon: ''
            }
          });
        }}
      >
        {(props: any) => {
          return (
            <form onSubmit={props.handleSubmit}>
              <>
                <Flex>
                  <Box mb={2}>
                    <Text size="bodyBold" align="left">
                      {t({
                        id: `GEOTOOL.ACTIONPANEL.LATLONG.TITLE`
                      })}
                    </Text>
                  </Box>
                </Flex>
                <Flex>
                  <Text size="bodySmall" align="left">
                    {t({
                      id: `GEOTOOL.ACTIONPANEL.LATLONG.DESCRIPTION`
                    })}
                  </Text>
                </Flex>
                <Inputs />
                <Flex flexDirection="row-reverse">
                  <Button
                    variant="ghost"
                    type="submit"
                    icon={<Icon icon={faPlus} name="grid" className="svgPrimary" size="sm" />}
                    label="Add Location"
                    size="sm"
                    onClick={() => {}}
                  />
                </Flex>
                <Box my={2}>
                  <Text size="bodyBold" align="left">
                    {t({
                      id: `GENERIC.LABEL.RADIUS`
                    })}
                  </Text>
                </Box>
                <Box>
                  <Text size="bodySmall" align="left">
                    {t({
                      id: `GEOTOOL.ACTIONPANEL.PINTOOL.RADIUS.DESCRIPTION`
                    })}
                  </Text>
                </Box>
                <Flex height="50px">
                  <Slider
                    defaultValue={radius}
                    min={100}
                    max={10000}
                    step={50}
                    label="m"
                    sliderValue={radius}
                    setSliderValue={setRadius}
                  />
                </Flex>
              </>
            </form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LatLogTool;
