import { datadogRum } from '@datadog/browser-rum';

export const userMonitoringStarter = async () => {
  if (process.env.REACT_APP_ENVIRONMENT === 'production') {
    if (!process.env.REACT_APP_DATADOG_APPID) {
      console.error('Missing Datadog App ID');
    }
    if (!process.env.REACT_APP_DATADOG_TOKEN) {
      console.error('Missing Datadog Token');
    }
    if (process.env.REACT_APP_DATADOG_TOKEN && process.env.REACT_APP_DATADOG_APPID) {
      datadogRum.init({
        applicationId: 'c6bf98d2-5751-4a87-85ef-1edc4edbcb4f',
        clientToken: 'pub5a3a28be15421553e9c271292a219dfb',
        site: 'datadoghq.com',
        service: 'loop',
        env: process.env.REACT_APP_ENVIRONMENT,
        version: process.env.REACT_APP_VERSION,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 25,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
      });

      await datadogRum.startSessionReplayRecording();
    }
  }
};
