import React from 'react';
import useTheme from 'hooks/themes/useTheme';
import { Helmet } from 'react-helmet';

interface Props {}

const OrganizationBaseProvider: React.FC<Props> = () => {
  const { getThemeProp, getThemePropUrl } = useTheme();

  return (
    <Helmet>
      <title>{getThemeProp('title')}</title>
      <link rel="icon" type="image/x-icon" href={getThemePropUrl('favicon')} />
    </Helmet>
  );
};

export default OrganizationBaseProvider;
