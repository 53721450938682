/* eslint-disable no-nested-ternary */
import React, { FC, useEffect, useState } from 'react';
import transparentize from 'utils/transparentize';
import { colors } from 'utils/insightsGraphConfiguration';
import {
  TableV2,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Text,
  Flex,
  Box,
  Icon,
  Button
} from '@retargetly/ui-components';
import { InfoTooltip } from 'components/Ui';
import { ISegmentAffinity } from 'utils/interfaces';
import { useFormatMessage } from 'react-intl-hooks';
import { faArrowUp, faArrowDown, faSort, faSquarePlus } from '@fortawesome/pro-duotone-svg-icons';
import { useTable, useSortBy } from 'react-table';
import InterestRadarGraph from '../Charts/InterestRadarGraph';

interface Props {
  data: ISegmentAffinity[];
  handleOpenChildAudience: (id: number, name: string) => void;
  handleBack: () => void;
  clustersSelected: { name: string; id: number }[];
}

interface DataProps {
  id: number;
  affinity: number;
  name: string;
  retargetly: number;
  dif: string;
  hasChildren: boolean;
}

interface GraphProps {
  labels: string[];
  responsive: boolean;
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[];
}

const Comparison: FC<Props> = ({ data, handleOpenChildAudience, handleBack, clustersSelected }) => {
  const t = useFormatMessage();
  const [tableData, setTableData] = useState<DataProps[] | undefined>(undefined);
  const [graphData, setGraphData] = useState<GraphProps[] | undefined>(undefined);

  const rowConfiguration = [
    {
      type: 'string',
      align: 'left',
      range: false,
      percentage: false
    },
    {
      type: 'string',
      align: 'right',
      range: false,
      percentage: true
    },
    {
      type: 'string',
      align: 'right',
      range: false,
      percentage: true
    },
    {
      type: 'string',
      align: 'right',
      range: true,
      percentage: false
    }
  ];

  const headerConfiguration = [
    {
      toolTip: undefined
    },
    {
      toolTip: `${t({ id: 'INSIGHTS.TABLE.COMPARISON.PLANNING.TOOLTIP' })}`
    },
    {
      toolTip: `${t({ id: 'INSIGHTS.TABLE.COMPARISON.RELY.TOOLTIP' })}`
    },
    {
      toolTip: `${t({ id: 'INSIGHTS.TABLE.COMPARISON.AFFINITY.TOOLTIP' })}`
    }
  ];

  const columns = React.useMemo(
    () => [
      {
        Header: `${t({ id: 'INSIGHTS.TABLE.COMPARISON.CATEGORY' })}`,
        accessor: 'name'
      },
      {
        Header: `${t({ id: 'INSIGHTS.TABLE.COMPARISON.PLANNING' })}`,
        accessor: 'affinity'
      },
      {
        Header: `Retargetly`,
        accessor: 'retargetly'
      },
      {
        Header: `${t({ id: 'INSIGHTS.TABLE.COMPARISON.AFFINITY' })}`,
        accessor: 'dif'
      }
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      // @ts-ignore
      columns,
      data: tableData ?? []
    },
    useSortBy
  );

  useEffect(() => {
    if (data && data.length > 0) {
      const topDataPlaning = data.slice(0, 10).map(item => item.inner_bar);
      const topDataRely = data.slice(0, 10).map(item => item.outer_bar);
      const topLabels = data.slice(0, 10).map(item => item.name);
      const transformData = data.slice(0, 10).map(item => {
        return {
          id: item.id,
          name: item.name,
          affinity: item.inner_bar,
          retargetly: item.outer_bar,
          dif: item.inner_bar > 0 && item.outer_bar > 0 ? ((item.inner_bar / item.outer_bar) * 100).toFixed(2) : '',
          hasChildren: item.hasChildrens
        };
      });
      setTableData(transformData.sort((a: any, b: any) => b.dif - a.dif));
      setGraphData([
        {
          labels: topLabels,
          responsive: true,
          datasets: [
            {
              label: `${t({ id: 'INSIGHTS.TABLE.COMPARISON.PLANNING' })}`,
              data: topDataPlaning,
              backgroundColor: transparentize(colors[0], 0.5),
              borderColor: transparentize(colors[0], 0),
              borderWidth: 1
            },
            {
              label: 'Retargetly',
              data: topDataRely,
              backgroundColor: transparentize(colors[2], 0.5),
              borderColor: transparentize(colors[2], 0),
              borderWidth: 1
            }
          ]
        }
      ]);
    } else {
      setGraphData([]);
      setTableData([]);
    }
  }, [setGraphData, data]);

  return (
    <>
      {clustersSelected.length > 1 && (
        <Button variant="ghost" label={`${t({ id: 'GENERIC.LABEL.BACK' })}`} onClick={() => handleBack()} />
      )}
      <TableContainer border="none">
        <TableV2 variant="simple" {...getTableProps()}>
          <Thead>
            {headerGroups.map(headerGroup => {
              return (
                <Tr borderTop="none" {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, i) => {
                    return (
                      // @ts-ignore
                      <Th verticalAlign="center" {...column.getHeaderProps(column.getSortByToggleProps())}>
                        <Flex flexDirection="row" alignItems="center">
                          <Box mr={1}>
                            <Text size="bodySmallBold">{column.render('Header')}</Text>
                          </Box>
                          {headerConfiguration[i].toolTip && (
                            <Box>
                              <InfoTooltip text={headerConfiguration[i].toolTip} />
                            </Box>
                          )}
                          <Box>
                            <span>
                              {// @ts-ignore
                              column.isSorted ? (
                                // @ts-ignore
                                column.isSortedDesc ? (
                                  <Icon icon={faArrowDown} name="arrow-down" color="blue.500" size="sm" />
                                ) : (
                                  <Icon icon={faArrowUp} name="arrow-up" color="blue.500" size="sm" />
                                )
                              ) : (
                                <Icon icon={faSort} name="arrow-sort" color="blue.500" size="sm" />
                              )}
                            </span>
                          </Box>
                        </Flex>
                      </Th>
                    );
                  })}
                </Tr>
              );
            })}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {rows.map(row => {
              prepareRow(row);
              return (
                <Tr borderTop="none" h="40px" key={row?.id}>
                  {row.cells.map((cell, j) => {
                    const { hasChildren, id, name } = cell.row.original;
                    const getColor = (value: number) => {
                      return value > 100 ? 'green.600' : 'red.600';
                    };
                    return (
                      <Td textAlign={rowConfiguration[j].align} {...cell.getCellProps()}>
                        <Flex alignItems="center">
                          <Box mr={1} w="12px">
                            {hasChildren && j === 0 && (
                              <Box cursor="pointer" onClick={() => handleOpenChildAudience(id, name)}>
                                <Icon icon={faSquarePlus} name="square-plus" size="xsm" color="blue.500" />
                              </Box>
                            )}
                          </Box>
                          <Box>
                            <Text
                              size="bodyDefault"
                              color={rowConfiguration[j].range ? getColor(cell.value) : 'neutrals.500'}
                            >
                              {cell.render('Cell')} {rowConfiguration[j].percentage ? '%' : ''}
                            </Text>
                          </Box>
                        </Flex>
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </TableV2>
      </TableContainer>

      {graphData && (
        <Flex flexDirection="column" alignItems="center" h="auto" width="100%">
          <InterestRadarGraph data={graphData} />
        </Flex>
      )}
    </>
  );
};

export default Comparison;
