import produce from 'immer';
import { LOAD_ESTIMATIONS, LOAD_TABLE_ESTIMATIONS, LOAD_BOT_DATA, SET_AVATAR_PROPS } from '../constants/actionTypes';
import { updateObject } from '../utils/objectUpdater';

const prevState = {
  total_devices: 0,
  web_no_mobile_devices: 0,
  web__mobile_devices: 0,
  ios_devices: 0,
  android_devices: 0,
  people: 0,
  devices_by_platform: [],
  devices_by_country_and_platform: [],
  totalRow: [],
  top_clusters: [],
  demographic_overlap: [],
  top_countries: [],
  education_level: [],
  work_situation: [],
  economic_level: [],
  bank_state: [],
  marital_status: [],
  credit_score: [],
  profiles: [],
  profession: [],
  purchase_intent: [],
  avatar_bot: {},
  survey: []
};

const setNewState = (state, payload) => {
  return updateObject(state, {
    totalRow: payload.devices_by_country_and_platform.total_devices_by_platform,
    devices_by_country_and_platform: payload.devices_by_country_and_platform.total_devices_by_country_and_platform
  });
};

export default (state = prevState, action) => {
  switch (action.type) {
    case LOAD_ESTIMATIONS:
      return { ...state, ...action.payload };
    case LOAD_BOT_DATA:
      return produce(state, draftState => {
        draftState.avatar_bot = action.payload;
      });
    case SET_AVATAR_PROPS:
      return produce(state, draftState => {
        draftState.avatar_bot.props = action.payload;
      });
    case LOAD_TABLE_ESTIMATIONS:
      return setNewState(state, action.payload);
    default:
      return state;
  }
};
