import React, { useEffect } from 'react';
import { Route, Switch, Redirect, useHistory } from 'react-router-dom';
import { Layout } from 'antd';
import '../styles/components/content.scss';

import useExpressionBucket from 'hooks/planning/useExpressionBucket';
import useUIContext from 'hooks/useUIContext';
import RightMenu from './RightMenuComponent';
import Insights from '../containers/Insights/InsightsSection';
import * as Routes from '../constants/routes';
import { TaxonomyProvider } from './Planner/context';
import { PlannerPage } from './Planner/pages/PlannerPage';

/**
 * Description: This component contains the two siders and the central content
 */

const { Sider, Content } = Layout;
const ContentPlannerComponent = () => {
  const history = useHistory();
  const { isRightMenuOpen } = useUIContext();
  const { hasValidExpression } = useExpressionBucket();

  useEffect(() => {
    if (!hasValidExpression && history.location.pathname.includes(Routes.INSIGHTS)) {
      history.push(Routes.SEARCH);
    }
  }, [history, hasValidExpression]);

  return (
    <>
      <Content className="content" style={{ borderTop: '1px solid rgba(0, 0, 0, .05)' }}>
        <Switch>
          <Route path={Routes.INSIGHTS} component={Insights} />
          <TaxonomyProvider>
            <Route path={Routes.PLANNER_SUBSECTION} component={PlannerPage} />
          </TaxonomyProvider>
          <Route path="*">
            <Redirect to={Routes.ERROR404} />
          </Route>
        </Switch>
      </Content>
      <Sider
        className="right-panel"
        collapsed={!isRightMenuOpen}
        collapsible="true"
        collapsedWidth="0"
        width="25vw"
        zeroWidthTriggerStyle={{
          display: 'none'
        }}
      >
        <RightMenu />
      </Sider>
    </>
  );
};

ContentPlannerComponent.propTypes = {};

export default ContentPlannerComponent;
