import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useHistory } from 'react-router-dom';
import { useFormatMessage } from 'react-intl-hooks';
import { Button as ButtonDS, Icon } from '@retargetly/ui-components';
import { faAngleLeft } from '@fortawesome/pro-duotone-svg-icons';
import Interest from 'components/Insights/Interest/Interest';
import PurchaseInterest from 'components/Insights/PurchaseInterest/PurchaseInterest';
import Statistics from 'components/Insights/Statistics/Statistics';
import AgeGenderAndIdentifiers from 'components/Insights/AgeGenderAndIdentifiers/AgeGenderAndIdentifiers';

const InsightsSection = React.memo(() => {
  const history = useHistory();
  const t = useFormatMessage();

  return (
    <Scrollbars autoHide>
      <ButtonDS
        onClick={history.goBack}
        icon={<Icon icon={faAngleLeft} name="angle-left" size="sm" />}
        variant="ghost"
        label={t({ id: 'INSIGHTS.BUTTON.BACK_TO_RESULTS' })}
      />

      <Statistics />
      <AgeGenderAndIdentifiers />
      <Interest />
      <PurchaseInterest />
    </Scrollbars>
  );
});

export default InsightsSection;
