import React, { ClipboardEvent } from 'react';
import { Text, Flex, Box } from '@retargetly/ui-components';
import FormikInput from 'widgets/forms/input/FormikInput';
import { useFormatMessage } from 'react-intl-hooks';
import { isValidLatitude } from 'utils/isValidLatitude';
import { isValidLongitude } from 'utils/isValidLongitude';
import { useFormikContext } from 'formik';

const Inputs = () => {
  const t = useFormatMessage();
  const { setFieldValue } = useFormikContext();

  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();
    try {
      const { clipboardData } = event;
      const [lat, lon] = clipboardData.getData('text/plain').split(',');
      if (isValidLatitude(parseFloat(lat)) || isValidLongitude(parseFloat(lon))) {
        setFieldValue('lat', parseFloat(lat));
        setFieldValue('lon', parseFloat(lon));
      }
    } catch (error) {
      // TODO: add some snack bar to inform the user that the paste was not successful
      console.log('error');
    }
  };

  return (
    <>
      <Flex flexDirection="column">
        <Box my={2}>
          <Text size="bodyBold" align="left">
            {t({
              id: `GENERIC.LABEL.LATITUDE`
            })}
          </Text>
        </Box>
        <Box height="70px">
          <FormikInput name="lat" onPaste={handlePaste} />
        </Box>
      </Flex>
      <Flex flexDirection="column">
        <Box mb={2}>
          <Text size="bodyBold" align="left">
            {t({
              id: `GENERIC.LABEL.LONGITUDE`
            })}
          </Text>
        </Box>
        <Box height="70px">
          <FormikInput name="lon" />
        </Box>
      </Flex>
    </>
  );
};

export default Inputs;
