import React, { FC } from 'react';
import { BrandIcons } from 'utils/interfaces';

const getSize = (size: BrandIcons['size']) => {
  switch (size) {
    case 'sm': {
      return {
        width: '15px',
        height: '15px'
      };
    }
    case 'md': {
      return {
        width: '20px',
        height: '20px'
      };
    }
    case 'lg': {
      return {
        width: '40px',
        height: '40px'
      };
    }
    default: {
      return {
        width: '15px',
        height: '15px'
      };
    }
  }
};

const XanderIcon: FC<BrandIcons> = ({ size = 'sm' }) => {
  const { width, height } = getSize(size);

  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9032 5.25H8.84692L12.4329 11.2652L15.9032 5.25Z" fill="#FB312F" />
      <path d="M8.84679 19.3625L15.9031 19.3625L12.3171 13.3474L8.84679 19.3625Z" fill="#FE8B7D" />
      <path d="M8.02814 5.7227L4.5 11.8336L11.5023 11.7357L8.02814 5.7227Z" fill="#DF0501" />
      <path d="M16.7219 18.8899L20.25 12.7789L13.2477 12.8769L16.7219 18.8899Z" fill="#FE7163" />
      <path d="M4.49993 12.779L8.02808 18.8899L11.4444 12.7767L4.49993 12.779Z" fill="#FC5046" />
      <path d="M20.2501 11.8336L16.7219 5.72266L13.3056 11.8358L20.2501 11.8336Z" fill="#FF4D46" />
    </svg>
  );
};

export default XanderIcon;
