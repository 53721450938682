import SegmentsRepository from './segments';
import AuthRepository from './auth';
import InsightsRepository from './insights';
import FeaturesRepository from './features';
import PlanningsRepository from './plannings';
import QuestionsRepository from './questions';
import ThemeRepository from './theme';
import OrganizationRepository from './organization';
import GeoRepository from './geo';
import KeywordsRepository from './keywords';
import ProductsRepository from './products';

const repositories = {
  segments: SegmentsRepository,
  auth: AuthRepository,
  insights: InsightsRepository,
  features: FeaturesRepository,
  plannings: PlanningsRepository,
  questions: QuestionsRepository,
  theme: ThemeRepository,
  organization: OrganizationRepository,
  geo: GeoRepository,
  keywords: KeywordsRepository,
  products: ProductsRepository
};

export const ServiceFactory = {
  get: name => repositories[name]
};
